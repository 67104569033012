import clsx from "clsx";

const PageTitle = ({ Icon, text }) => {
  return (
    <div
      className={clsx(
        "flex items-center gap-4 pl-[52px] relative",
        'before:content-[""] before:absolute before:h-[1px] before:w-[356px] before:bg-gray300 before:bottom-0 before:left-0',
      )}
    >
      <Icon className="text-primary w-8 h-8" fill="transparent" />
      <h1>{text}</h1>
    </div>
  );
};

export default PageTitle;
