import clsx from "clsx";
import { useState } from "react";

export const CalendarGridDay = ({
  highlightType,
  isToday,
  isRangeStartDay,
  isRangeEndDay,
  isInRange,
  dateValue,
  disabled,
  onSelectDay,
  onHover,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleClick = () => {
    if (!disabled) {
      onSelectDay();
      setIsSelected(true);
    }
  };

  return (
    <div
      className={clsx("flex aspect-square", {
        "bg-gradient-to-b text-neutral": isInRange || isRangeStartDay,
        "from-gradientInfoStart to-gradientInfoEnd": highlightType === "info",
        "from-gradientDangerStart to-gradientDangerEnd":
          highlightType === "danger",
        "rounded-s-full": isRangeStartDay,
        "rounded-e-full": isRangeEndDay,
      })}
    >
      <button
        className={clsx(
          "text-center rounded-full w-full",
          isInRange || isRangeStartDay || isRangeEndDay
            ? ""
            : disabled
              ? "text-gray500 opacity-50 pointer-events-none"
              : highlightType === "info"
                ? "hover:bg-info100"
                : "hover:bg-danger100",
          !isInRange &&
            isToday &&
            (highlightType === "info"
              ? "border border-info500"
              : "border border-danger500"),
        )}
        disabled={disabled || isSelected}
        onClick={() => {
          handleClick();
          setIsSelected(false)
        }}
        onMouseOver={onHover}
        onMouseLeave={() => setIsSelected(false)}
      >
        {dateValue}
      </button>
    </div>
  );
};
