import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import { CalendarGridDay } from "./CalendarGridDay";

export const MonthGrid = ({
  highlightType,
  rangeStart,
  rangeEnd,
  today,
  workingDays,
  currentMonth,
  onSelectDay,
  mepa,
}) => {
  const [hoveredDay, setHoverDay] = useState(null);

  const daysArray = useMemo(() => {
    const currentDate = dayjs(currentMonth);

    // Get the first day of the month and the last day of the month
    const startOfMonth = currentDate.startOf("month");
    const lastOfMonth = currentDate.endOf("month");
    const firstDayOfMonth = startOfMonth.get("day");

    const adjustedStartDayOfWeek =
      firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
    const daysInPreviousMonth = adjustedStartDayOfWeek;

    const daysInCurrentMonth = currentDate.daysInMonth();

    // Calculate the first and last day to display in the calendar
    const remainingDays = 6 * 7 - (daysInPreviousMonth + daysInCurrentMonth);

    const firstDayToDisplay = startOfMonth.subtract(daysInPreviousMonth, "day");
    const lastDayToDisplay = lastOfMonth.add(remainingDays, "day");

    const calendarDates = [];
    let currentDateToDisplay = firstDayToDisplay;
    while (currentDateToDisplay.isSameOrBefore(lastDayToDisplay)) {
      calendarDates.push(dayjs(currentDateToDisplay));
      currentDateToDisplay = currentDateToDisplay.add(1, "day");
    }

    return calendarDates;
  }, [currentMonth]);

  const onHover = (day) => {
    if (!rangeEnd) {
      setHoverDay(day);
    } else if (hoveredDay) {
      setHoverDay(null);
    }
  };
  const getCurrentDayValues = useCallback(
    (date) => {
      //   const isToday = date.isSame(today, "date");
      const isRangeStartDay = date.isSame(rangeStart, "date");
      const rangeEndDay = rangeEnd || hoveredDay;
      const isRangeEndDay = rangeEndDay
        ? date.isSame(rangeEndDay, "date")
        : isRangeStartDay;

      // If not rangeEnd Day select niether hovered - then make the startDay rounded
      const isInRange =
        rangeEndDay && date.isBetween(rangeStart, rangeEndDay, "date", "[]");

      const isPreviousMonth = date.isBefore(currentMonth, "month");
      const isToday = date.isSame(today, "date");
      // const disabled = today.isAfter(date, "date") || isToday;
      const isBeforeStartDay = rangeEnd
        ? false
        : date.isBefore(rangeStart, "date");

      const isWorkingDay = workingDays.includes(date.get("day"));
      const isFirstThreeDates = date.diff(today, "days") < 3;
      const disabled =
        mepa === "mepa"
          ? isFirstThreeDates || isToday
          : today.isAfter(date, "date") || isToday;

      //  const isFirstThreeDates = date.diff(currentMonth, "days") < 0;
      return {
        dayKey: date.toISOString(),
        dayValue: date.get("date"),
        isRangeStartDay,
        isRangeEndDay,
        isToday,
        isInRange,
        disabled:
          disabled ||
          isBeforeStartDay ||
          (mepa === "mepa" ? isFirstThreeDates : "") ||
          isPreviousMonth ||
          !isWorkingDay,
      };
    },
    [today, currentMonth, rangeEnd, hoveredDay, workingDays],
  );
  return (
    <div>
      <div className="grid grid-cols-7 gap-y-2 text-sm font-normal">
        {daysArray.map((date, index) => {
          const {
            dayValue,
            isRangeStartDay,
            isRangeEndDay,
            isToday,
            isInRange,
            disabled,
          } = getCurrentDayValues(date);

          return (
            <CalendarGridDay
              key={index}
              highlightType={highlightType}
              dateValue={dayValue}
              isToday={isToday}
              isInRange={isInRange}
              isRangeStartDay={isRangeStartDay}
              isRangeEndDay={isRangeEndDay}
              disabled={disabled}
              onSelectDay={() => onSelectDay(date)}
              onHover={() => onHover(date)}
            />
          );
        })}
      </div>
    </div>
  );
};
