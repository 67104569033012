import { Outlet } from "react-router-dom";
import logo from "../../assets/images/logo.png";

export const AuthContainer = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="w-[500px] px-[52px]">
        <img
          src={logo}
          className="w-[313px] mb-[16px] mx-auto"
          alt="Zelvor Logo"
        />
        <Outlet />
      </div>
    </div>
  );
};
