import { useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Modal } from "../layout/Modal";
import { RedirectionConfirmationModal } from "../modals/RedirectionConfirmationModal";
import { useLinkedMeetingApps } from "../../hooks/integrations";
import { selectAvailableCalendarPlatforms } from "../../store/configs";

import { ReactComponent as ChevronIcon } from "../../assets/icons/Chevron.svg";
import { ReactComponent as IconTick } from "../../assets/icons/Tick.svg";

const logosByPlatform = {
  None: null,
  Zoom: "/apps/zoom_logo.svg",
  "Google Meet": "/apps/meet_logo.png",
  Skype: "/apps/skype_logo.svg",
  "Microsoft Teams": "/apps/msteam_logo.png",
  Azure: "/apps/outlook_logo.svg",
  Google: "/apps/gcalendar_logo.png",
  Webex: "/apps/webex_logo.svg",
};

export const DashboardIntegrationCard = () => {
  const userLinkedMeetingApps = useLinkedMeetingApps();
  const availableCalendarsApps = useSelector(selectAvailableCalendarPlatforms);
  const [showRedirectionAlert, setShowRedirectionAlert] = useState(false);

  const onToggleRedirectionAlert = () => {
    setShowRedirectionAlert(!showRedirectionAlert);
  };

  return (
    <div
      className={clsx(
        "bg-neutral px-6 py-7 border border-gray500 rounded-[10px] shadow-theme",
        "w-[calc(25%-16px)] select-none",
      )}
    >
      <div className="flex items-center justify-between border-b border-gray500 pb-6">
        <p className="text-xl 3xl:text-2xl">Integrations</p>
        <Link
          className="text-gray500"
          to={{ pathname: "../settings", search: "section=integrations" }}
        >
          <ChevronIcon fill="transparent" />
        </Link>
      </div>
      <div className="h-[calc(100%-30px)] overflow-y-scroll pb-[28px]">
        <div className="border-b border-gray500 py-4">
          <p className="">Calendar Integrations:</p>
          {availableCalendarsApps.map((calendarApp, index) => (
            <div
              key={index}
              className="flex items-center justify-between pt-4"
            >
              <img
                className="max-h-6 h-full w-auto"
                src={
                  logosByPlatform[
                    calendarApp.externalCalendarInfrastructureTitle
                  ]
                }
                alt={calendarApp.externalCalendarInfrastructureTitle}
              />
              <button onClick={onToggleRedirectionAlert}>
                {calendarApp.userIsLinked ? (
                  <IconTick
                    className="text-success w-5 h-5 ml-1"
                    fill="transparent"
                  />
                ) : (
                  <span className="underline">Connect</span>
                )}
              </button>
            </div>
          ))}
        </div>
        <div className="pt-4">
          <p className="">Video Conference Integrations:</p>
          {userLinkedMeetingApps.map((meetingPlatform, index) => (
            <div
              key={index}
              className="flex items-center justify-between pt-4"
            >
              <img
                className="max-h-6 h-full w-auto pointer-events-none"
                src={logosByPlatform[meetingPlatform.title]}
                alt={meetingPlatform.title}
              />
              <button onClick={onToggleRedirectionAlert}>
                {meetingPlatform.isConnected ? (
                  <IconTick
                    className="text-success w-5 h-5 ml-1"
                    fill="transparent"
                  />
                ) : (
                  <span className="underline">Connect</span>
                )}
              </button>
            </div>
          ))}
        </div>
      </div>
      {showRedirectionAlert && (
        <Modal
          isOpen={showRedirectionAlert}
          onClose={onToggleRedirectionAlert}
          preventAnimate={true}
          hideHeader={true}
          modalClassName="border border-gray500 shadow-theme p-0"
        >
          <RedirectionConfirmationModal
            title="Integrations"
            description={"You'll be redirected outside of Zelvor"}
            onClose={onToggleRedirectionAlert}
          />
        </Modal>
      )}
    </div>
  );
};
