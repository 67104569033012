import clsx from "clsx";
import { ReactComponent as CrossIcon } from "../../assets/icons/Cross.svg";

export const ActionErrorModal = ({
  modalTitle,
  message,
  onClose,
  onClearError,
}) => {
  return (
    <div className="w-[560px] font-semibold px-8">
      <div className="flex items-center justify-between">
        <p className="text-2xl">{modalTitle}</p>
        <CrossIcon
          fill="transparent"
          type="button"
          className="cursor-pointer"
          onClick={onClose}
        />
      </div>
      <p className="text-gray500 mt-3">{message}</p>
      <div
        className={clsx(
          "flex items-center justify-center mx-auto my-7",
          "bg-danger w-[70px] h-[70px] text-[white] rounded-full",
        )}
      >
        <CrossIcon fill="transparent" />
      </div>

      <button
        className={clsx(
          "border border-gray500",
          "w-full py-3 bg-info100 rounded-full",
          "shadow-theme",
        )}
        onClick={onClearError}
      >
        Retry
      </button>
    </div>
  );
};
