import clsx from "clsx";

export const Input = ({
  id,
  name,
  label,
  value,
  placeholder,
  type,
  onChange,
  onBlur,
  hasError,
  error,
  className,
  containerClassName,
  disabled,
  autoComplete
}) => {
  const handleBlur = () => {};

  return (
    // <div className={clsx('mt-4', containerClassName)}>
    <div className={clsx("", containerClassName)}>
      {label && <label className="text-sm font-semibold">{label}</label>}
      <input
        className={clsx(
          "border",
          "w-full p-[12px] rounded-[6px] bg-neutral",
          "text-xs",
          hasError ? "border-danger" : "border-primary",
          className,
        )}
        id={id}
        name={name}
        onChange={onChange}
        onBlur={onBlur || handleBlur}
        value={value}
        placeholder={placeholder}
        type={type || "text"}
        disabled={disabled}
        autoComplete={autoComplete}
      />
      {hasError && (
        <span className="text-danger text-xs mt-[2px]">{error}</span>
      )}
    </div>
  );
};
