import clsx from "clsx";

import { ReactComponent as CrossIcon } from "../../assets/icons/Cross.svg";

export const MeetingDetailError = ({ actionError, onClose }) => {
  return (
    <div className="w-[482px]">
      <p className="text-2xl">Could not load the meeting detail.</p>
      <p className="text-gray500 mt-3">{actionError}</p>
      <div
        className={clsx(
          "flex items-center justify-center mx-auto my-7",
          "bg-danger w-[70px] h-[70px] text-[white] rounded-full",
        )}
      >
        <CrossIcon fill="transparent" />
      </div>

      <button
        className={clsx(
          "border border-gray500",
          "w-full py-3 rounded-full",
          "shadow-theme",
        )}
        onClick={onClose}
      >
        Close
      </button>
    </div>
  );
};
