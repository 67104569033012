import clsx from "clsx";
import Avatar from "../Avatar";
import { MeetingRequestsActions } from "./MeetingRequestsActions";
import MeetingParticipant from "../meetings/MeetingParticipant";
import { MEETING_INVITATION_RESPONSE } from "../../utils/constants";
import dayjs from "dayjs";

import { ReactComponent as TickIcon } from "../../assets/icons/Tick.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/Cross.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/time.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/Calendar.svg";
import { ReactComponent as GroupIcon } from "../../assets/icons/Group.svg";
import { ReactComponent as AgendaIcon } from "../../assets/icons/Agenda.svg";
import { ReactComponent as PinIcon } from "../../assets/icons/pin.svg";
import { ReactComponent as AdjustableIcon } from "../../assets/icons/adjustable.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/Location.svg";

export const MeetingRequestCard = ({
  meetingRequest,
  onAccept,
  onDecline,
  setShowAgendaDetailModal,
}) => {

  return (
    <div
      className={clsx(
        "bg-neutral border border-gray500 rounded-[10px] shadow-theme",
        "py-6 mb-4",
      )}
    >
      <div className="flex items-center justify-between gap-8 pl-12">
        <div className="flex items-center gap-2">
          <Avatar
            userId={meetingRequest.hostPersonID}
            firstName={meetingRequest.host}
            lastName=""
            className="w-8 h-8"
            profileById={true}
          />
          <p className="border border-gray500 rounded-[10px] px-4 py-1">
            {meetingRequest.host}
          </p>
        </div>
        <p className="text-xl flex-grow text-center">{meetingRequest.topic}</p>
        {meetingRequest.response === MEETING_INVITATION_RESPONSE.ACCEPTED &&
          !meetingRequest.isHost && (
            <div
              className={clsx(
                "flex items-center gap-1 px-3 rounded-1",
                "text-base leading-loose",
                "bg-success100 text-success",
              )}
            >
              <TickIcon fill="transparent" />
              <span>Accepted</span>
            </div>
          )}
        {meetingRequest.response === MEETING_INVITATION_RESPONSE.REJECTED && (
          <div
            className={clsx(
              "flex items-center gap-1 px-3 rounded-1",
              "text-base leading-loose",
              "bg-danger100 text-danger",
            )}
          >
            <CrossIcon fill="transparent" />
            <span>Declined</span>
          </div>
        )}
      </div>
      <div className="flex justify-between gap-3 my-6 px-12">
        <div className="w-[calc(100%-272px-72px)]">
          <div className="flex gap-4 items-start my-6">
            <p className="flex items-center gap-2 text-gray500">
              <AgendaIcon fill="transparent" />
              <span className="font-normal">Agenda</span>
            </p>
            <div className="">
              <p className="font-normal">{meetingRequest.agenda}</p>
              {meetingRequest.canExapandAgendaText && (
                <button
                  className="border border-gray500 px-3 py-1 rounded-full mt-3"
                  onClick={() =>
                    setShowAgendaDetailModal(meetingRequest.agendaFull)
                  }
                >
                  Expand Agenda
                </button>
              )}
            </div>
          </div>

          <div className="flex gap-4 items-start">
            <p className="flex items-center gap-2 text-gray500">
              <GroupIcon fill="transparent" />
              <span className="font-normal">Attendees</span>
            </p>
            <div className="flex items-center flex-wrap gap-1">
              {meetingRequest.invitees.map((invitedUser, index) => (
                <MeetingParticipant
                  key={index}
                  firstName={
                    invitedUser?.firstName
                      ? invitedUser?.firstName
                      : invitedUser?.emailAddress.split("@")[0]
                  }
                  lastName={invitedUser?.lastName ? invitedUser?.lastName : ""}
                  hasApproved={
                    invitedUser.response ===
                    MEETING_INVITATION_RESPONSE.ACCEPTED
                  }
                  hasRejected={
                    invitedUser.response ===
                    MEETING_INVITATION_RESPONSE.REJECTED
                  }
                />
              ))}
            </div>
          </div>
        </div>
        <div
          className={clsx(
            "flex flex-col gap-x-6 gap-y-3 flex-wrap",
            "whitespace-nowrap font-normal",
            "w-[272px] p-4",
            "border border-gray500 rounded-[10px]",
          )}
        >
          <p className="flex items-center gap-2">
            <ClockIcon fill="transparent" />
            <span>{meetingRequest.meetingDuration}</span>
          </p>
          <p className="flex items-center gap-2">
            <CalendarIcon fill="transparent" />
            <span>
              {dayjs(meetingRequest.proposalDateTimesToBeHeldStart).format(
                "DD.MM.YYYY",
              )}{" "}
              -{" "}
              {dayjs(meetingRequest.proposalDateTimesToBeHeldEnd).format(
                "DD.MM.YYYY",
              )}
            </span>
          </p>
          <p className="flex items-center gap-2">
            <LocationIcon fill="transparent" />
            <span>{meetingRequest.platformTitle}</span>
          </p>
          {!meetingRequest?.mepaId && (
            <p className="flex items-center gap-2">
              {meetingRequest.nonAdjustable ? (
                <PinIcon fill="transparent" />
              ) : (
                <AdjustableIcon fill="transparent" />
              )}
              <span>
                {meetingRequest.nonAdjustable
                  ? "Non-Adjustable Meeting"
                  : "Adjustable Meeting"}
              </span>
            </p>
          )}
        </div>
      </div>
      {!meetingRequest.isHost && (
        <MeetingRequestsActions
          response={meetingRequest.response}
          onAccept={onAccept}
          onDecline={onDecline}
          hideClose={true}
        />
      )}
    </div>
  );
};
