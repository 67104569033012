import { useEffect, useMemo } from "react";
import clsx from "clsx";
import { useFormik } from "formik";
import { Checkbox, Select, Textarea, Tooltip } from "../../theme";
import { Spinner } from "../Spinner";
import { DatePicker } from "../date-picker";
import {
  absenceFormSchema,
  getInitialAbsenceFromValues,
} from "../../utils/formsValidators";
import { getReadableDateRange } from "../../utils/dateTime";
import { useWorkingSchedule } from "../../hooks/workingHours";

import { ReactComponent as EditIcon } from "../../assets/icons/Edit.svg";
import { ReactComponent as TickIcon } from "../../assets/icons/Tick.svg";

const buttonActionDisplay = {
  add: "Add Absence",
  edit: "Save Changes",
  view: "Close",
};

export const ManageAbsenceModal = ({
  absenceData,
  actionType,
  actionLoading,
  actionError,
  actionSuccess,
  onConfirm,
  onCancel,
  onSwitchActionType,
}) => {
  const {
    values,
    errors,
    touched,
    resetForm,
    handleChange,
    handleBlur,
    setFieldValue,
    submitForm,
  } = useFormik({
    initialValues: getInitialAbsenceFromValues(absenceData),
    validationSchema: absenceFormSchema,
    onSubmit: onConfirm,
  });

  const { workingTimes } = useWorkingSchedule(
    values.fromDate,
    values.toDate,
    values.fromTime,
    values.toTime,
  );

  const haldeMainButtonAction = () => {
    if (actionType === "view") {
      onCancel();
    } else {
      submitForm();
    }
  };

  const onRangeChange = (range) => {
    const [start, end] = range;
    setFieldValue("fromDate", start);
    setFieldValue("toDate", end);
    if (start !== end) {
      setFieldValue("isAllDay", true);
    }
  };

  const onChangeAllDayOption = (value) => {
    if (values.fromDate !== values.toDate) {
      return;
    }
    setFieldValue("isAllDay", value);
  };

  const timeFrame = useMemo(() => {
    return getReadableDateRange(values.fromDate, values.toDate);
  }, [values.fromDate, values.toDate]);

  useEffect(() => {
    const initialValues = getInitialAbsenceFromValues(absenceData);
    resetForm({ values: initialValues });
  }, [absenceData, actionType]);

  return (
    <div
      className={clsx(
        "w-[660px] font-semibold",
        actionSuccess && "h-[288px] overflow-hidden",
      )}
    >
      <div
        className={clsx(
          "flex items-center justify-between",
          "border-b border-gray500 pt-[22px] px-[26px] pb-1",
        )}
      >
        <h3 className="text-2xl">
          {actionType === "view"
            ? "Absence Details"
            : actionType === "edit"
              ? "Edit Absence"
              : "Add Absence"}
        </h3>
        {actionType === "view" && (
          <button
            className="text-sm text-gray500 underline"
            onClick={() => onSwitchActionType("delete")}
          >
            Delete Absence
          </button>
        )}
      </div>
      <div>
        <div
          className={clsx(
            "flex items-center justify-between",
            "pl-[26px] mr-[26px] py-3 border-b border-gray500 text-base",
            "text-xs lg:text-sm",
          )}
        >
          {actionType === "view" ? (
            <>
              <div className="flex items-center gap-[26px]">
                <h4>Timeframe:</h4>
                <h4>{timeFrame}</h4>
                <h4 className="flex items-center gap-1">
                  <span> All Day</span>
                  <Checkbox checked={absenceData.isAllDay} />
                </h4>
              </div>
              {!absenceData.isAllDay && <p>{absenceData.timeDisplay}</p>}
              <button onClick={() => onSwitchActionType("edit")}>
                <EditIcon fill="transparent" />
              </button>
            </>
          ) : (
            <>
              <div className="flex items-center gap-[26px]">
                <h4>Timeframe:</h4>
                <DatePicker
                  timeFrame={timeFrame}
                  calendarTitle="Select your absence"
                  highlightType="danger"
                  startDate={values.fromDate}
                  endDate={values.toDate}
                  pickerButtonClassName="border-b"
                  onRangeChange={onRangeChange}
                />
                <Tooltip
                  content={
                    values.fromDate === values.toDate
                      ? ""
                      : "Times are not supported for multi-days."
                  }
                >
                  <h4 className="flex items-center gap-1">
                    <span> All Day</span>
                    <Checkbox
                      checked={values.isAllDay}
                      onChange={onChangeAllDayOption}
                    />
                  </h4>
                </Tooltip>
              </div>
              {actionType && (
                <div className="flex gap-3 ml-3">
                  <Select
                    name="fromTime"
                    placeholder="From"
                    value={values.fromTime}
                    options={workingTimes}
                    disabled={values.isAllDay}
                    onChange={handleChange}
                    containerClassName="w-[110px]"
                    controlClassName="!border-primary text-xs !min-h-[30px] !h-[30px]"
                    hasError={!!(errors.fromTime && touched.fromTime)}
                    error={errors.fromTime}
                    errorClassName="w-[110px] text-xs font-normal"
                  />
                  <Select
                    name="toTime"
                    placeholder="To"
                    value={values.toTime}
                    options={workingTimes}
                    disabled={values.isAllDay}
                    onChange={handleChange}
                    containerClassName="w-[110px]"
                    controlClassName="!border-primary text-xs !min-h-[30px] !h-[30px]"
                    hasError={!!(errors.toTime && touched.toTime)}
                    error={errors.toTime}
                    errorClassName="w-[110px] text-xs font-normal"
                  />
                </div>
              )}
            </>
          )}
        </div>
        <div className="px-[26px] mb-6 mt-3">
          {actionError && (
            <p className="mb-2 text-xs text-center text-danger">
              {actionError}
            </p>
          )}
          <h3 className="text-2xl">Motivation</h3>
          <Textarea
            name="motivation"
            placeholder="Will be having an on site visit with stakeholders."
            value={values.motivation}
            onChange={handleChange}
            onBlur={handleBlur}
            containerClassName="!mt-0 mb-[18px]"
            className={clsx(
              "mt-[9px] !text-sm",
              !(errors.motivation && touched.motivation) && "!border-gray500",
            )}
            rows={4}
            hasError={errors.motivation && touched.motivation}
            error={errors.motivation}
            disabled={actionType === "view"}
          />
          <div className="flex items-center justify-end gap-9 mb-6 mt-9">
            <button
              className={clsx(
                "flex items-center justify-center",
                "border border-gray500",
                "w-[280px] px-[25px] py-3 mx-auto bg-neutral rounded-full",
                "shadow-theme",
              )}
              type="button"
              onClick={haldeMainButtonAction}
            >
              {buttonActionDisplay[actionType]}
            </button>
            {actionType !== "view" && (
              <button
                className="underline text-base leading-8 mr-[84px]"
                onClick={onCancel}
              >
                Go back
              </button>
            )}
          </div>
        </div>
      </div>
      {actionLoading && (
        <div
          className={clsx(
            "absolute top-0",
            "flex flex-col items-center justify-center",
            "w-full h-full bg-[#b5b5b5b5] rounded-[10px]",
          )}
        >
          <Spinner className="!w-6 !h-6 mb-2" />
          <p>Loading...</p>
        </div>
      )}
      {actionSuccess && (
        <div
          className={clsx(
            "absolute top-0",
            "flex flex-col items-center justify-center",
            "w-full h-full bg-neutral rounded-[10px] py-6",
          )}
        >
          <div
            className={clsx(
              "flex items-center justify-center",
              "bg-[green] w-[70px] h-[70px] text-[white] rounded-full",
            )}
          >
            <TickIcon fill="transparent" />
          </div>
          <h3 className="text-lg my-8">{actionSuccess}</h3>
          <button
            className={clsx(
              "flex items-center justify-center",
              "border border-gray500",
              "w-[280px] px-[25px] py-3 mx-auto bg-neutral rounded-full",
              "shadow-theme",
            )}
            type="button"
            onClick={onCancel}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};
