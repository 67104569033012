import { SettingsCard } from "./SettingsCard";
import { ReactComponent as SecurityIcon } from "../../assets/icons/Security.svg";
import { Input } from "../../theme";

export const SecurityCard = ({ listRef, formInstance }) => {
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    formInstance;

  return (
    <SettingsCard
      title="Security"
      description="Update your password"
      Icon={SecurityIcon}
      sectionKey="security"
      listRef={listRef}
      haveChanges={formInstance.dirty}
    >
      <form onSubmit={handleSubmit}>
        <div className="flex flex-wrap gap-4 ml-[320px]">
          <Input
            id="current-password"
            name="oldPassword"
            label="Current Password"
            placeholder="Password"
            type="password"
            value={values.oldPassword}
            className="mt-2"
            containerClassName="!mt-0 w-[calc(50%-8px)]"
            onChange={handleChange}
            onBlur={handleBlur}
            hasError={!!(errors.oldPassword && touched.oldPassword)}
            error={errors.oldPassword}
            autoComplete="current-password"
          />
          <div className=""></div>
          <Input
            id="new-password"
            name="newPassword"
            label="New Password"
            placeholder="New Password"
            type="password"
            value={values.newPassword}
            className="mt-2"
            containerClassName="!mt-0 w-[calc(50%-8px)]"
            onChange={handleChange}
            onBlur={handleBlur}
            hasError={!!(errors.newPassword && touched.newPassword)}
            error={errors.newPassword}
            autoComplete="new-password"
          />
          <Input
            id="new-password"
            name="confirmNewPassword"
            label="Confirm New Password"
            placeholder="Confirm Passowrd"
            type="password"
            value={values.confirmNewPassword}
            className="mt-2"
            containerClassName="!mt-0 w-[calc(50%-8px)]"
            onChange={handleChange}
            onBlur={handleBlur}
            hasError={
              !!(errors.confirmNewPassword && touched.confirmNewPassword)
            }
            error={errors.confirmNewPassword}
            autoComplete="new-password"
          />
        </div>
      </form>
    </SettingsCard>
  );
};
