import { useMemo } from "react";
import clsx from "clsx";
import Separator from "../Separator";

import { ReactComponent as TickIcon } from "../../assets/icons/Tick.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/Calendar.svg";
import { ReactComponent as GroupIcon } from "../../assets/icons/Group.svg";
import { ReactComponent as PinIcon } from "../../assets/icons/pin.svg";
import { ReactComponent as AdjustableIcon } from "../../assets/icons/adjustable.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/Location.svg";

export const MeetingRequestSuccess = ({
  timeFrame,
  nonAdjustable,
  selectedInvitees,
  invitableUsers,
  platformId,
  availablePlatforms,
  isUpdateAction,
  onClose,
}) => {
  const { selectedPlatformName, parsedInvitees } = useMemo(() => {
    const selectedPlatformName = availablePlatforms?.find(
      (platform) => platform?.value === platformId,
    );

    const parsedInvitees = selectedInvitees?.map((inviteeId) => {
      const foundUser = invitableUsers?.find((item) => item.id === inviteeId);
      return `${foundUser?.firstName} ${foundUser?.lastName}`;
    });

    return {
      selectedPlatformName: selectedPlatformName?.label,
      parsedInvitees,
    };
  }, []);

  return (
    <div>
      <p className="text-2xl">
        Meeting proposal {isUpdateAction ? "updated" : "added"} successfully!
      </p>
      <p className="text-gray500 my-3">Congratulations!</p>
      <div
        className={clsx(
          "flex items-center justify-center mx-auto mb-10",
          "bg-success w-[70px] h-[70px] text-[white] rounded-full",
        )}
      >
        <TickIcon fill="transparent" />
      </div>

      <div className="flex items-center mt-4">
        <p className="text-base font-light flex items-center gap-2 whitespace-nowrap">
          <CalendarIcon fill="transparent" />
          <span>Scheduled for:</span>
          <span>{timeFrame}</span>
        </p>
        <Separator className="ml-4 border-success opacity-50" />
      </div>

      <div className="flex items-center mt-4">
        <p className="text-base font-light flex items-center gap-2 whitespace-nowrap">
          <LocationIcon fill="transparent" />
          <span>Location:</span>
          <span>{selectedPlatformName}</span>
        </p>
        <Separator className="ml-4 border-success opacity-50" />
      </div>

      <div className="">
        <div className="flex items-center mt-4">
          <p className="text-base font-light flex items-center gap-2">
            <GroupIcon fill="transparent" />
            <span>Participants:</span>
          </p>
          <Separator className="ml-4 border-success opacity-50" />
        </div>
        <div className="flex flex-wrap gap-x-1 gap-y-3 my-3">
          {parsedInvitees.map((inviteeName, index) => (
            <p
              key={index}
              className={clsx(
                "border border-gray500 rounded-full",
                "px-2 py-1 min-w-[130px] leading-none text-center",
              )}
            >
              {inviteeName}
            </p>
          ))}
        </div>
      </div>

      <Separator className="my-5" />

      <p
        className={clsx(
          "py-6 flex items-center justify-center gap-2 w-full",
          "border border-primary rounded-[10px]",
        )}
      >
        <span>
          {nonAdjustable ? (
            <PinIcon fill="transparent" />
          ) : (
            <AdjustableIcon fill="transparent" />
          )}
        </span>
        <span>
          {nonAdjustable ? "Non-Adjustable Meeting" : "Adjustable Meeting"}
        </span>
      </p>
      <button
        className={clsx(
          "border border-gray500",
          "w-full py-3 mt-7 bg-info100 rounded-full",
          "shadow-theme",
        )}
        onClick={onClose}
      >
        Close
      </button>
    </div>
  );
};
