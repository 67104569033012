import { useState } from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import { DatePickerCalendar } from "./DatePickerCalendar";

import { ReactComponent as ArrowDownIcon } from "../../assets/icons/Chevron.svg";

export const DatePicker = ({
  calendarTitle,
  highlightType,
  timeFrame,
  showSplittedRange,
  formatedRangeStart,
  formatedRangeEnd,
  startDate,
  endDate,
  pickerButtonClassName,
  onRangeChange,
  mepa,
}) => {
  const [today] = useState(dayjs());
  const [showPicker, setShowPicker] = useState(false);
  const [showEndDateInitial, setShowEndDateIntial] = useState(false);

  const toggleDatePicker = () => {
    setShowPicker(!showPicker);
    setShowEndDateIntial(false);
  };

  const onOpenEndDate = () => {
    setShowEndDateIntial(true);
    setShowPicker(true);
  };

  const handleRangeUpdate = (start, end) => {
    const formatedStart = start.format("YYYY-MM-DD");
    const formatedEnd = end.format("YYYY-MM-DD");
    onRangeChange([formatedStart, formatedEnd]);
    setShowPicker(false);
    setShowEndDateIntial(false);
  };

  return (
    <div className="relative">
      {showSplittedRange ? (
        <div
          className={clsx(
            "flex items-center gap-x-2 select-none",
            pickerButtonClassName,
          )}
        >
          <button
            className="px-2 py-1 border rounded-[6px]"
            onClick={toggleDatePicker}
          >
            {formatedRangeStart}
          </button>
          <p>&mdash;</p>
          <button
            className="px-2 py-1 border rounded-[6px]"
            onClick={onOpenEndDate}
          >
            {formatedRangeEnd}
          </button>
          <button className="" onClick={toggleDatePicker}>
            <ArrowDownIcon className="rotate-90 w-4 h-4" fill="transparent" />
          </button>
        </div>
      ) : (
        <button
          className={clsx("border-b", pickerButtonClassName)}
          onClick={toggleDatePicker}
        >
          {timeFrame}
        </button>
      )}
      {showPicker && (
        <DatePickerCalendar
          showPicker={showPicker}
          calendarTitle={calendarTitle}
          highlightType={highlightType}
          startDate={startDate}
          endDate={endDate}
          today={today}
          showEndDateInitial={showEndDateInitial}
          toggleDatePicker={toggleDatePicker}
          onRangeChange={handleRangeUpdate}
          mepa={mepa}
        />
      )}
    </div>
  );
};
