import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axiosClient from "../utils/axiosClient";
import {
  GET_NOTIFICATIONS_API,
  MARK_NOTIFICATIONS_RECEIVED_API,
  MARK_NOTIFICATION_SEEN_API,
} from "../utils/apiRoutes";
import { parseNotificationsData, queryfyUrl } from "../utils/dataParser";

const initialState = {
  notificationsList: [],
  notificationsLoading: false,
  hasMoreData: true,
};

export const fetchExistingNotifications = createAsyncThunk(
  "notifications/fetchExistingNotifications",
  async (loadeMore, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { currentUser } = getState().user;
    const { notificationsList } = getState().notifications;
    if (notificationsList.length > 0 && !loadeMore) {
      return [];
    }
    const filter = { pageSize: 10, pageNumber: notificationsList.length };
    const url = queryfyUrl(`${serverUrl}/${GET_NOTIFICATIONS_API}`, filter);
    const { data: result } = await axiosClient.get(url);
    const [parsedData, newlyReceivedId] = parseNotificationsData(
      result.data,
      currentUser?.timeZone,
    );
    if (parsedData.length === 0) {
      dispatch(setHasMoreData(false));
    }

    // When newlyReceivedId list, then trigger to mark all those as received
    dispatch(markNotificationsAsReceived(newlyReceivedId));

    return parsedData;
  },
);
export const fetchLatestNotification = createAsyncThunk(
  "notifications/fetchLatestNotification",
  async (_, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { currentUser } = getState().user;
    const { notificationsList } = getState().notifications;

    const filter = { pageSize: 1, pageNumber: 0 };
    const url = queryfyUrl(`${serverUrl}/${GET_NOTIFICATIONS_API}`, filter);
    const { data: result } = await axiosClient.get(url);
    const [parsedData, newlyReceivedId] = parseNotificationsData(
      result.data,
      currentUser?.timeZone,
    );

    const existingNotifications = [...parsedData, ...notificationsList];
    dispatch(setUpdatedNotifications(existingNotifications));

    // When newlyReceivedId list, then trigger to mark all those as received
    dispatch(markNotificationsAsReceived(newlyReceivedId));
  },
);

const markNotificationsAsReceived = createAsyncThunk(
  "notifications/markNotificationsAsReceived",
  async (notifIds, { getState }) => {
    const { serverUrl } = getState().configs;

    notifIds.forEach(async (id) => {
      try {
        const url = queryfyUrl(
          `${serverUrl}/${MARK_NOTIFICATIONS_RECEIVED_API}`,
          {
            lastMessageId: id,
          },
        );
        await axiosClient.post(url);
      } catch {
        // No action Needed
      }
    });
  },
);

export const markNotificationsAsSeen = createAsyncThunk(
  "notifications/markNotificationsAsSeen",
  async (notifId, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { notificationsList } = getState().notifications;

    const url = queryfyUrl(`${serverUrl}/${MARK_NOTIFICATION_SEEN_API}`, {
      messageId: notifId,
    });

    let allNotifications = [...notificationsList];
    const notifToUpdateIndex = allNotifications.findIndex(
      (item) => item.id === notifId,
    );

    allNotifications[notifToUpdateIndex] = {
      ...allNotifications[notifToUpdateIndex],
      isUnread: false,
    };
    dispatch(setUpdatedNotifications(allNotifications));

    // No need to wait/listen for result
    await axiosClient.post(url);
    return null;
  },
);

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    resetNotificationsState: () => {
      return initialState;
    },
    setUpdatedNotifications: (state, { payload }) => {
      state.notificationsList = payload;
    },
    setHasMoreData: (state, { payload }) => {
      state.hasMoreData = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExistingNotifications.pending, (state) => {
      state.notificationsLoading = true;
    });
    builder.addCase(
      fetchExistingNotifications.fulfilled,
      (state, { payload }) => {
        state.notificationsLoading = false;
        state.notificationsList = [...state.notificationsList, ...payload];
      },
    );
    builder.addCase(fetchExistingNotifications.rejected, (state) => {
      state.notificationsLoading = false;
    });
  },
});

// ACTIONS
export const {
  resetNotificationsState,
  setUpdatedNotifications,
  setHasMoreData,
} = slice.actions;

// SELECTORS
const selectNotificationsData = (state) => {
  return state.notifications;
};

export const selectNotificationsList = createSelector(
  selectNotificationsData,
  ({ notificationsList, notificationsLoading, hasMoreData }) => ({
    notificationsList,
    notificationsLoading,
    hasMoreData,
  }),
);

export default slice.reducer;
