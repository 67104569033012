import clsx from "clsx";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchExistingNotifications,
  markNotificationsAsSeen,
  selectNotificationsList,
} from "../../store/notifications";
import { Spinner } from "../Spinner";

export const DashboardNotifsCard = () => {
  const dispatch = useDispatch();
  const { notificationsList, notificationsLoading, hasMoreData } = useSelector(
    selectNotificationsList,
  );
  const onLoadMore = () => {
    dispatch(fetchExistingNotifications(true));
  };

  const onNotificationSeend = (notifId) => {
    dispatch(markNotificationsAsSeen(notifId));
  };

  useEffect(() => {
    dispatch(fetchExistingNotifications());
  }, []);
  const stripHtmlTags = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };
  return (
    <div
      className={clsx(
        "bg-neutral border border-gray500 rounded-[10px] shadow-theme",
        "w-[calc(50%-16px)]",
      )}
    >
      <div className="border-b border-gray500 px-6 pt-7 pb-6">
        <p className="text-xl 3xl:text-2xl">Notifications</p>
      </div>
      <div className="h-[calc(100%-85px)] overflow-y-scroll flex flex-col gap-2">
        {notificationsList.map((notifItem, index) => (
          <div
            key={index}
            className={clsx(
              "px-6",
              notifItem.isUnread
                ? "bg-info100 cursor-pointer"
                : "pointer-events-none",
            )}
            onClick={() => onNotificationSeend(notifItem.id)}
          >
            <div
              className={clsx(
                "border-b border-gray500 py-5",
                "[&:last-child]:border-0",
              )}
            >
              <h5 className="text-gray500 mb-3">{notifItem.dateTimeDisplay}</h5>
              <p className="font-normal">{stripHtmlTags(notifItem.body)}</p>
            </div>
          </div>
        ))}

        {notificationsLoading ? (
          <Spinner className="w-8 h-8 text-center shrink-0 !m-auto" />
        ) : notificationsList.length === 0 ? (
          <p className="text-gray500 text-center my-auto">
            No notification at the moment.
          </p>
        ) : hasMoreData ? (
          <button className="w-fit my-2 mx-auto border-b" onClick={onLoadMore}>
            Load More
          </button>
        ) : (
          <p className="text-gray500 text-center my-2">
            No more notifications.
          </p>
        )}
      </div>
    </div>
  );
};
