import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { sortBy } from "lodash";
import {
  selectScheduledEventDeletionStatus,
  selectScheduledEventUpdateStatus,
  selectScheduledEvents,
} from "../store/scheduledEvents";
import {
  selectAddMeetingRequestStatus,
  selectMeetingRequestDeletionStatus,
  selectMeetingResponseStatus,
} from "../store/meetingRequests";

import { selectLoggedInUser } from "../store/user";

export const useScheduleEventsByDuration = (activeDate, activeView) => {
  const { scheduledEventsList } = useSelector(selectScheduledEvents);
  const [scheduleEvents, setScheduleEvents] = useState({});
  useEffect(() => {
    const filteredEvents = {};
    if (Object.values(scheduledEventsList).length > 0) {
      const start = activeDate.startOf(activeView);
      const end = activeDate.endOf(activeView);

      let dateTime = start;
      while (dateTime.isSameOrBefore(end)) {
        const formattedDateTime = dateTime.format("YYYY-MM-DD");
        if (scheduledEventsList[formattedDateTime]) {
          filteredEvents[formattedDateTime] =
            scheduledEventsList[formattedDateTime];
        }
        dateTime = dateTime.add(1, "day");
      }
    }
    setScheduleEvents(filteredEvents);
  }, [scheduledEventsList, activeDate, activeView]);
  return scheduleEvents;
};

export const useUpcomingScheduleEvents = (filter) => {
  const { scheduledEventsList } = useSelector(selectScheduledEvents);
  const [scheduleEvents, setScheduleEvents] = useState([]);
  useEffect(() => {
    const currentDateTime = dayjs();
    const filteredEvents = {};

    for (const dateTimeKey in scheduledEventsList) {
      const dateTimeByKey = dayjs(dateTimeKey);
      if (dateTimeByKey.isSameOrAfter(currentDateTime, "day")) {
        let meetingsOnDay = scheduledEventsList[dateTimeKey];

        // Filter out external meetings
        meetingsOnDay = meetingsOnDay.filter(
          (meetingRow) => !meetingRow.isExternalMeeting,
        );

        if (filter !== "all") {
          const hostedOnly = filter === "hosted";
          meetingsOnDay = meetingsOnDay.filter(
            (meetingItem) => meetingItem.isHost === hostedOnly,
          );
        }
        if (meetingsOnDay.length > 0) {
          filteredEvents[dateTimeKey] = meetingsOnDay;
        }
      }
    }

    const todayDateKey = currentDateTime.format("YYYY-MM-DD");
    const tomorrowDateKey = currentDateTime.add(1, "day").format("YYYY-MM-DD");

    // If no event scheduled on today and tomorrow, then add empty arrays to format the UI
    if (!filteredEvents[todayDateKey]) {
      filteredEvents[todayDateKey] = [];
    }
    if (!filteredEvents[tomorrowDateKey]) {
      filteredEvents[tomorrowDateKey] = [];
    }

    const mappedEvents = sortBy(
      Object.entries(filteredEvents).map(([key, value]) => {
        return {
          dateKey: key,
          events: value,
        };
      }),
      "dateKey",
    );

    setScheduleEvents(mappedEvents);
  }, [scheduledEventsList, filter]);
  return scheduleEvents;
};

export const useScheduledEventsNew = (filterValue) => {
  const upComingMeetingInvitee = useSelector(
    (state) => state?.meetingRequests?.upComingMeetingInvitee,
  );
  const { scheduledEventsList } = useSelector(selectScheduledEvents);
  const currentUser = useSelector(selectLoggedInUser);
  let allScheduledEvents = [];
  Object.keys(scheduledEventsList).forEach((event) => {
    allScheduledEvents = [...allScheduledEvents, ...scheduledEventsList[event]];
  });

  // const allmeetingInvitations = meetingInvitations.filter(item => {
  // 	const isMeetingExist = allScheduledEvents.find(scheduledItem => scheduledItem.entityID === item.meetingProposalID)
  // 	return !isMeetingExist;
  // });

  const [scheduledEvents, setScheduledEvents] = useState([]);

  useEffect(() => {
    const myProposedInviations =
      upComingMeetingInvitee &&
      upComingMeetingInvitee
        ?.filter((item) => {
          if (item?.mepaId) {
            // For MEPA meetings
            return (
              (item?.invitationResponse === 1 ||
                item?.hostPersonID === currentUser?.userId) &&
              item?.mepA_FixTime
            );
          } else {
            // For internal meetings
            return (
              (item?.invitationResponse === 1 ||
                item?.hostPersonID === currentUser?.userId) &&
              item.lastScheduleTime
            );
          }
        })
        ?.map((proposal) => ({
          ...proposal,
          entityID: proposal.meetingProposalID,
          allParticipants: proposal.invitees.length,
          isProposal: true,
          meetingPlatform: proposal.platformTitle,
          eventDurationParsed: proposal.meetingDuration,
        }))
        ?.sort(
          (a, b) => new Date(a.lastScheduleTime) - new Date(b.lastScheduleTime),
        );

    setScheduledEvents(myProposedInviations);
  }, [upComingMeetingInvitee]);
  let scheduledData;

  if (filterValue === "hosted") {
    scheduledData = scheduledEvents.filter(
      (item) => item.hostPersonID === currentUser.userId,
    );
  } else if (filterValue === "invited") {
    scheduledData = scheduledEvents.filter(
      (item) => item.hostPersonID !== currentUser.userId,
    );
  } else {
    scheduledData = scheduledEvents;
  }

  return scheduledData;
};

export const useUnscheduledEvents = (filterValue) => {
  const upComingMeetingInvitee = useSelector(
    (state) => state?.meetingRequests?.upComingMeetingInvitee,
  );
  const { scheduledEventsList } = useSelector(selectScheduledEvents);
  const currentUser = useSelector(selectLoggedInUser);
  let allScheduledEvents = [];
  Object.keys(scheduledEventsList).forEach((event) => {
    allScheduledEvents = [...allScheduledEvents, ...scheduledEventsList[event]];
  });

  // const allmeetingInvitations = meetingInvitations.filter(item => {
  // 	const isMeetingExist = allScheduledEvents.find(scheduledItem => scheduledItem.entityID === item.meetingProposalID)
  // 	return !isMeetingExist;
  // });

  const [unscheduledEvents, setUnscheduledEvents] = useState([]);
  useEffect(() => {
    const myProposedInviations =
      upComingMeetingInvitee &&
      upComingMeetingInvitee
        ?.filter((item) => {
          if (item.mepaId) {
            // For MEPA meetings
            return (
              (item?.invitationResponse === 1 ||
                item?.hostPersonID === currentUser?.userId) &&
              !item?.mepA_FixTime
            );
          } else {
            // For internal meetings
            return (
              (item?.invitationResponse === 1 ||
                item?.hostPersonID === currentUser?.userId) &&
              !item?.lastScheduleTime
            );
          }
        })
        ?.map((proposal) => ({
          ...proposal,
          entityID: proposal.meetingProposalID,
          allParticipants: proposal.invitees.length,
          isProposal: true,
          meetingPlatform: proposal.platformTitle,
          eventDurationParsed: proposal.meetingDuration,
        }))
        .sort((a, b) => new Date(a.start) - new Date(b.start));

    setUnscheduledEvents(myProposedInviations);
  }, [upComingMeetingInvitee]);
  let unScheduledData;

  if (filterValue === "hosted") {
    unScheduledData = unscheduledEvents.filter(
      (item) => item.hostPersonID === currentUser.userId,
    );
  } else if (filterValue === "invited") {
    unScheduledData = unscheduledEvents.filter(
      (item) => item.hostPersonID !== currentUser.userId,
    );
  } else {
    unScheduledData = unscheduledEvents;
  }

  return unScheduledData;
};

export const useEventDeleteOrDeclineActionStatus = () => {
  const {
    meetingResponseLoading,
    meetingResponseSuccess,
    meetingResponseError,
  } = useSelector(selectMeetingResponseStatus);
  const {
    meetingRequestDeletionLoading,
    meetingRequestDeletionSuccess,
    meetingRequestDeletionError,
  } = useSelector(selectMeetingRequestDeletionStatus);
  const {
    scheduledEventDeletionLoading,
    scheduledEventDeletionSuccess,
    scheduledEventDeletionError,
  } = useSelector(selectScheduledEventDeletionStatus);

  return {
    deleteOrDeclineActionLoading:
      meetingResponseLoading ||
      meetingRequestDeletionLoading ||
      scheduledEventDeletionLoading,
    deleteOrDeclineActionSuccess:
      meetingResponseSuccess ||
      meetingRequestDeletionSuccess ||
      scheduledEventDeletionSuccess,
    deleteOrDeclineActionError:
      meetingResponseError ||
      meetingRequestDeletionError ||
      scheduledEventDeletionError,
  };
};

export const useEventUpdateActionStatus = () => {
  const {
    addMeetingRequestLoading,
    addMeetingRequestSuccess,
    addMeetingRequestError,
  } = useSelector(selectAddMeetingRequestStatus);
  const {
    updateScheduledMeetingLoading,
    updateScheduledMeetingSuccess,
    updateScheduledMeetingError,
  } = useSelector(selectScheduledEventUpdateStatus);

  return {
    evnetUpdateActionLoading:
      addMeetingRequestLoading || updateScheduledMeetingLoading,
    evnetUpdateActionSuccess:
      addMeetingRequestSuccess || updateScheduledMeetingSuccess,
    evnetUpdateActionError:
      addMeetingRequestError || updateScheduledMeetingError,
  };
};
