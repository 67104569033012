import clsx from "clsx";
import { ReactComponent as AlertFillIcon } from "../../assets/icons/alert-fill.svg";

export const SettingsCard = ({
  sectionKey,
  title,
  description,
  Icon,
  haveChanges,
  containerClass,
  children,
  hideBottomBorder,
  listRef,
}) => {
  return (
    <div className="mt-10 w-full">
      <div
        className={clsx(
          "bg-neutral border border-gray500 rounded-[10px] text-primary",
          containerClass,
        )}
        ref={(elRef) => (listRef.current[sectionKey] = elRef)}
      >
        {haveChanges && (
          <p className="font-semibold text-warning pl-10 pt-4">
            <AlertFillIcon className="w-6 h-6 inline mr-2" />
            Unsaved Changes
          </p>
        )}
        <div className="flex pt-8 gap-[30px]">
          <div className="pl-10 font-semibold">
            <h3 className="text-2xl mb-3">{title}</h3>
            <p className="text-sm leading-none w-[273px] text-gray500">
              {description}
            </p>
          </div>
          <div
            className={clsx(
              "flex flex-grow justify-end items-center",
              "border-b border-gray500 pr-10",
            )}
          >
            <Icon fill="transparent" />
          </div>
        </div>
        <div className="p-11">{children}</div>
      </div>
      {!hideBottomBorder && (
        <div className="mt-10 ml-auto border-b border-gray500 max-w-[824px]"></div>
      )}
    </div>
  );
};
