import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import PageTitle from "../../../components/PageTitle";
import {
  MeetingPreferencesCard,
  SecurityCard,
  SettingsActionButtons,
  SettingsCalendarSync,
  SettingsIntegrations,
  SettingsMyProfileCard,
  SettingsNotifications,
  SettingsSectionsMenu,
} from "../../../components/settings";

import {
  selectLoggedInUser,
  selectMyMeetingPreferences,
} from "../../../store/user";
import {
  getInitialProfileDataFields,
  profileDataFormValidations,
  passwordUpdateFields,
  passwordUpdateFormValidations,
  getMeetingPreferencesInitial,
  meetingPreferencesValidations,
  getInitialNotificationsSettings,
} from "./settingsForms";
import { selectNotificationsTypes } from "../../../store/configs";
import {
  selectIfAllFormsDone,
  selectPasswordLastUpdated,
  updateMyMeetingPreferences,
  updatePassword,
  updateProfileData,
  updateTimezone,
  uploadProfilePic,
} from "../../../store/settingsForms";

import { ReactComponent as SettingsIcon } from "../../../assets/icons/Settings.svg";
import { ReactComponent as ProfileIcon } from "../../../assets/icons/Profile.svg";
import { ReactComponent as SecurityIcon } from "../../../assets/icons/Security.svg";
import { ReactComponent as PreferencesIcon } from "../../../assets/icons/Preferences.svg";
import { ReactComponent as IntegrationsIcon } from "../../../assets/icons/integrations.svg";
import { ReactComponent as SyncCalendarIcon } from "../../../assets/icons/sync_calendar.svg";
import { ReactComponent as NotificationsIcon } from "../../../assets/icons/Notifications.svg";

const secitonButtons = [
  {
    key: "profile",
    title: "My Details",
    Icon: <ProfileIcon className="w-6 h-6" fill="transparent" />,
  },
  {
    key: "security",
    title: "Security",
    Icon: <SecurityIcon className="w-6 h-6" fill="transparent" />,
  },
  {
    key: "meeting-preferences",
    title: "Meeting preferences",
    Icon: <PreferencesIcon className="w-6 h-6" fill="transparent" />,
  },
  {
    key: "integrations",
    title: "Integrations",
    Icon: <IntegrationsIcon className="w-6 h-6" fill="transparent" />,
  },
  {
    key: "calendar-sync",
    title: "Calendar sync",
    Icon: <SyncCalendarIcon className="w-6 h-6" fill="transparent" />,
  },
  {
    key: "notifications",
    title: "Notifications",
    Icon: <NotificationsIcon className="w-6 h-6" fill="transparent" />,
  },
];

export const Settings = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const settingCardsRef = useRef({});
  const [activeTarget, setActiveTarget] = useState(secitonButtons[0].key);
  const [haveScrolled, setHaveScrolled] = useState(false);

  const allFormsProcessed = useSelector(selectIfAllFormsDone);
  const passwordLastUpdated = useSelector(selectPasswordLastUpdated);
  const currentUser = useSelector(selectLoggedInUser);
  const settingsList = useSelector(selectNotificationsTypes);
  const myPreferences = useSelector(selectMyMeetingPreferences);

  const [removeCurrentProfilePic, setRemoveCurrentProfilePic] = useState(false);
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [newProfilePicPreview, setNewProfilePicPreview] = useState("");

  const onRemoveCurrentProfilePic = () => {
    setRemoveCurrentProfilePic(currentUser?.profilePhoto ? true : false);
    setProfilePicFile(null);
    setNewProfilePicPreview("");
  };

  const handleFileChange = (event) => {
    setRemoveCurrentProfilePic(true);
    const file = event.target.files[0];
    setProfilePicFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      setNewProfilePicPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const profileDataForm = useFormik({
    initialValues: getInitialProfileDataFields(currentUser),
    validationSchema: profileDataFormValidations,
    onSubmit: (values) => {
      console.log("PROFILE VALUES:::", values);
    },
  });

  const passwordUpdateForm = useFormik({
    initialValues: passwordUpdateFields,
    validationSchema: passwordUpdateFormValidations,
    onSubmit: (values) => {
      console.log("Update Password VALUES:::", values);
    },
  });

  const notificationsSettingsForm = useFormik({
    initialValues: { enabledMessageTypeIdsForNotifications: [] },
    onSubmit: (values) => {
      console.log("Notifications Update VALUES:::", values);
    },
  });

  const meetingPreferencesForm = useFormik({
    initialValues: getMeetingPreferencesInitial(),
    validationSchema: meetingPreferencesValidations,
    onSubmit: (values) => {
      console.log("Meeting Preferences VALUES:::", values);
    },
  });

  const { allowFormsReset, allowFormsSubmit } = useMemo(() => {
    const formsList = [
      profileDataForm,
      passwordUpdateForm,
      notificationsSettingsForm,
      meetingPreferencesForm,
    ];

    let allowFormsReset =
      removeCurrentProfilePic ||
      newProfilePicPreview ||
      formsList.some((form) => form.dirty);

    let allowFormsSubmit = false;

    if (allowFormsReset) {
      allowFormsSubmit = formsList.some((form) => form.dirty);
    }

    return {
      allowFormsReset,
      allowFormsSubmit,
    };
  }, [
    profileDataForm,
    passwordUpdateForm,
    notificationsSettingsForm,
    meetingPreferencesForm,
    removeCurrentProfilePic,
    newProfilePicPreview,
  ]);

  const unsavedSections = useMemo(() => {
    return {
      profile: profileDataForm.dirty
        ? profileDataForm.isValid
          ? "valid"
          : "invalid"
        : (removeCurrentProfilePic || newProfilePicPreview) && "valid",
      security: passwordUpdateForm.dirty
        ? passwordUpdateForm.isValid
          ? "valid"
          : "invalid"
        : undefined,
      "meeting-preferences": meetingPreferencesForm.dirty
        ? meetingPreferencesForm.isValid
          ? "valid"
          : "invalid"
        : undefined,
      notifications: notificationsSettingsForm.dirty && "valid",
    };
  }, [
    profileDataForm.dirty,
    profileDataForm.isValid,
    passwordUpdateForm.dirty,
    passwordUpdateForm.isValid,
    notificationsSettingsForm.dirty,
    notificationsSettingsForm.isValid,
    meetingPreferencesForm.dirty,
    meetingPreferencesForm.isValid,
    removeCurrentProfilePic,
    newProfilePicPreview,
  ]);

  const onSaveFormsChanges = async () => {
    if (profilePicFile || removeCurrentProfilePic) {
      dispatch(uploadProfilePic(profilePicFile));
    }
    // Update My Profile and Notifications
    if (profileDataForm.dirty || notificationsSettingsForm.dirty) {
      if (!profileDataForm.isValid) {
        toast.error("Please correctly fill up the profile data form.");
        return;
      }
      dispatch(
        updateProfileData({
          profileData: profileDataForm.values,
          notificationsData: notificationsSettingsForm.values,
        }),
      );
    }

    if (
      profileDataForm.dirty &&
      profileDataForm.values.timeZone !== currentUser?.timeZone
    ) {
      dispatch(updateTimezone(profileDataForm.values.timeZone));
    }

    // Update My Password
    if (passwordUpdateForm.dirty) {
      dispatch(updatePassword(passwordUpdateForm.values));
    }

    // Update Meeting Preferences
    if (meetingPreferencesForm.dirty) {
      dispatch(updateMyMeetingPreferences(meetingPreferencesForm.values));
    }
  };

  const onResetForms = () => {
    // Reset All Forms
    // Remove Profile Pic Data if any

    if (profileDataForm.dirty) {
      profileDataForm.resetForm({
        values: getInitialProfileDataFields(currentUser),
      });
    }
    if (passwordUpdateForm.dirty) {
      passwordUpdateForm.resetForm({ values: passwordUpdateFields });
    }
    if (notificationsSettingsForm.dirty) {
      const enabledList = getInitialNotificationsSettings(
        currentUser?.enabledMessageTypeIdsForNotifications,
        settingsList,
      );
      notificationsSettingsForm.resetForm({
        values: {
          enabledMessageTypeIdsForNotifications: enabledList,
        },
      });
    }

    if (meetingPreferencesForm.dirty) {
      const parsedInitialValues = getMeetingPreferencesInitial(myPreferences);
      meetingPreferencesForm.resetForm({
        values: parsedInitialValues,
      });
    }

    if (removeCurrentProfilePic) {
      setRemoveCurrentProfilePic(false);
    }
    if (newProfilePicPreview || profilePicFile) {
      setNewProfilePicPreview(null);
      setProfilePicFile(null);
    }
  };

  useEffect(() => {
    setNewProfilePicPreview("");
    setProfilePicFile(null);
    setRemoveCurrentProfilePic("");
  }, [currentUser?.profilePhoto]);

  useEffect(() => {
    if (passwordLastUpdated) {
      passwordUpdateForm.resetForm();
    }
  }, [passwordLastUpdated]);

  // SCROLLSPY
  const onScrollToSection = (sectionKey) => {
    if (settingCardsRef.current && settingCardsRef.current[sectionKey]) {
      settingCardsRef.current[sectionKey].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    const offsetsList = {};
    for (const key in settingCardsRef.current) {
      if (settingCardsRef.current[key]) {
        const element = settingCardsRef.current[key];
        const elemetStartPos =
          element.offsetTop - scrollContainerRef.current.offsetTop - 38;
        offsetsList[key] = elemetStartPos;
      }
    }
    const handleScroll = () => {
      const scrollPosition = scrollContainerRef.current.scrollTop;
      for (const key in offsetsList) {
        if (offsetsList[key] && offsetsList[key] <= scrollPosition) {
          setActiveTarget(key);
          setHaveScrolled(true);
        }
      }
    };
    scrollContainerRef.current?.addEventListener("scroll", handleScroll);
    return () => {
      scrollContainerRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [
    settingCardsRef,
    settingCardsRef.current["meeting-preferences"]?.offsetHeight,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const activeSection = queryParams.get("section");
    if (activeSection && !haveScrolled) {
      onScrollToSection(activeSection);
    }
  }, [
    haveScrolled,
    settingCardsRef.current["meeting-preferences"]?.offsetHeight,
  ]);

  return (
    <div>
      <PageTitle Icon={SettingsIcon} text="Settings" />
      <div className="pt-[28px] px-8 flex">
        <div className="hidden xl:flex mt-[105px] 3xl:ml-[68px]">
          <SettingsSectionsMenu
            secitonButtons={secitonButtons}
            activeCardId={activeTarget}
            handleItemClick={onScrollToSection}
            unsavedSections={unsavedSections}
          />
        </div>
        <div className="flex-grow">
          <SettingsActionButtons
            disableSumbit={!allowFormsSubmit}
            disableFromsReset={!allowFormsReset}
            handleSubmit={onSaveFormsChanges}
            handleReset={onResetForms}
            formsSumitProcessing={!allFormsProcessed}
          />
          <div
            className={clsx(
              "flex flex-col items-end",
              "5xl:ml-[135px] h-[calc(100vh-302px)] overflow-scroll",
            )}
            ref={scrollContainerRef}
          >
            <SettingsMyProfileCard
              listRef={settingCardsRef}
              formInstance={profileDataForm}
              currentUser={currentUser}
              profilePreview={
                removeCurrentProfilePic || !currentUser?.profilePhoto
                  ? newProfilePicPreview
                  : currentUser?.profilePhoto
              }
              sectionHaveChanges={unsavedSections["profile"]}
              onRemoveCurrentProfilePic={onRemoveCurrentProfilePic}
              handleFileChange={handleFileChange}
            />
            <SecurityCard
              listRef={settingCardsRef}
              formInstance={passwordUpdateForm}
            />
            <MeetingPreferencesCard
              listRef={settingCardsRef}
              formInstance={meetingPreferencesForm}
            />
            <SettingsIntegrations listRef={settingCardsRef} />
            <SettingsCalendarSync listRef={settingCardsRef} />
            <SettingsNotifications
              listRef={settingCardsRef}
              enabledNotifications={
                currentUser?.enabledMessageTypeIdsForNotifications
              }
              formInstance={notificationsSettingsForm}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
