import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AES, enc as cryptoEnc } from "crypto-js";
import { Checkbox } from "../theme/Checkbox";
import { Modal } from "../components/layout/Modal";
import { Input } from "../theme/Input";
import { ServerConfigModal } from "../components/modals/ServerConfigModal";
import { lookupUserServer, setServerUrl } from "../store/configs";
import { loginFormSchema } from "../utils/formsValidators";
import {
  selectLoginRequestData,
  resetLoginError,
  login,
  selectLoggedInUser,
} from "../store/user";
import {
  getStoredLoginData,
  saveLoginDataLocally,
} from "../utils/localStorage";

export const Login = () => {
  const [rememberUser, setRememberUser] = useState(true);
  const [showServerConfigModal, setShowServerConfigModal] = useState(false);
  const [serverLookupLoading, setServerLookupLoading] = useState(false);
  const [userServersList, setUserServersList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loggedInUser = useSelector(selectLoggedInUser);
  const { loginLoading, loginError } = useSelector(selectLoginRequestData);

  const { handleChange, errors, values, touched, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema: loginFormSchema,
      onSubmit: async (values) => {
        // Lookup server for the user
        setServerLookupLoading(true);
        const lookupResult = await dispatch(lookupUserServer(values.username));
        setServerLookupLoading(false);
        // If more than 1 server, open the server selection model and prevent the login flow
        if (lookupResult.payload.length > 1) {
          setUserServersList(lookupResult.payload);
          setShowServerConfigModal(true);
          return;
        }
        // Otherwise the server is already setup in the action, so just go ahead
        handleLogin();
      },
    });

  const handleLogin = async () => {
    dispatch(login(values));
    if (rememberUser) {
      const dataToEncrypt = `${values.username}::${values.password}`;
      const encryptedData = AES.encrypt(dataToEncrypt, "Z64578O!Rl$£");
      saveLoginDataLocally(encryptedData);
    }
  };

  const onToggleRememberOption = () => {
    setRememberUser(!rememberUser);
  };

  const onToggleServerConfigModal = () => {
    setShowServerConfigModal(!showServerConfigModal);
  };

  const onSaveServerUrl = async (selectedServer) => {
    let safeValue = selectedServer;
    if (safeValue.endsWith("/")) {
      safeValue = safeValue.slice(0, -1);
    }
    await dispatch(setServerUrl(safeValue));
    setShowServerConfigModal(false);
    handleLogin();
  };

  useEffect(() => {
    if (loginError) {
      setTimeout(() => {
        dispatch(resetLoginError());
      }, 5000);
    }
  }, [loginError]);

  useEffect(() => {
    if (loggedInUser) {
      navigate("/admin/dashboard");
    }
  }, [loggedInUser]);

  useEffect(() => {
    const encryptedData = getStoredLoginData();
    if (encryptedData) {
      // Decrypt it, then split by the sperator and update form values
      const decrypted = AES.decrypt(encryptedData, "Z64578O!Rl$£").toString(
        cryptoEnc.Utf8,
      );
      const [username, ...passwords] = decrypted.split("::");
      setFieldValue("username", username);
      setFieldValue("password", passwords.join(""));
    }
  }, []);

  return (
    <div>
      <h3 className="text-center mt-[58px] text-2xl font-semibold text-primary">
        Sign In
      </h3>
      {loginError && (
        <p className="p-2 rounded-[4px] bg-danger400 text-danger font-semibold text-xs mt-3">
          {loginError}
        </p>
      )}
      <form onSubmit={handleSubmit}>
        <Input
          id="username"
          name="username"
          onChange={handleChange}
          value={values.username}
          placeholder="admin@admin.com"
          hasError={!!(errors.username && touched.username)}
          error={errors.username}
          className="mt-6 p-[12px] !text-base"
          autoComplete="username"
          
        />
        <Input
          id="current-password"
          name="password"
          onChange={handleChange}
          value={values.password}
          placeholder="password"
          type="password"
          hasError={!!(errors.password && touched.password)}
          error={errors.password}
          className="mt-6 p-[12px] !text-base"
          autoComplete="current-password"
        />
        <Checkbox
          label="Remember me"
          checked={rememberUser}
          containerStyle="mt-4"
          fillColor={rememberUser && "bg-accent"}
          onChange={onToggleRememberOption}
        />
        <Link
          to="../recover-password"
          className="flex justify-center mt-[10px] mx-auto text-gray500 font-normal underline w-fit"
        >
          Forgot password?
        </Link>
        <button
          className={clsx(
            "flex w-[186px] justify-center",
            "py-[10px] px-[20px] rounded-[20px] mt-6 mx-auto",
            "text-neutral font-semibold text-xs",
            loginLoading || serverLookupLoading ? "bg-gray500" : "bg-primary",
          )}
          type="submit"
          disabled={loginLoading || serverLookupLoading}
        >
          {loginLoading || serverLookupLoading
            ? "Authenticating..."
            : "Sign in"}
        </button>
      </form>
      <Modal
        isOpen={showServerConfigModal}
        preventAnimate={true}
        preventBackdropClose={true}
        onClose={onToggleServerConfigModal}
      >
        <ServerConfigModal
          userServers={userServersList}
          isOpen={showServerConfigModal}
          onSave={onSaveServerUrl}
        />
      </Modal>
    </div>
  );
};
