import { Fragment, useState } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Modal } from "../../components/layout/Modal";
import { MeetingAgendModal } from "./MeetingAgendModal";
import { MeetingDetailLoader } from "../meetings/MeetingDetailLoader";
import { MeetingDetailError } from "../meetings/MeetingDetailError";
import Avatar from "../Avatar";
import {
  selectEventDetail,
} from "../../store/scheduledEvents";

import { ReactComponent as ClockIcon } from "../../assets/icons/time.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/Calendar.svg";
import { ReactComponent as CalendarCheckIcon } from "../../assets/icons/calendar-check.svg";
import { ReactComponent as GroupIcon } from "../../assets/icons/Group.svg";
import { ReactComponent as AgendaIcon } from "../../assets/icons/Agenda.svg";
import { ReactComponent as PinIcon } from "../../assets/icons/pin.svg";
import { ReactComponent as AdjustableIcon } from "../../assets/icons/adjustable.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/Location.svg";
import { MEETING_INVITATION_RESPONSE } from "../../utils/constants";
import { ReactComponent as TickIcon } from "../../assets/icons/tick-circle.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/close-circle.svg";
import { ReactComponent as QuestionIcon } from "../../assets/icons/help.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/Arrow.svg";
import { selectLoggedInUser } from "../../store/user";

export const MeetingDetailModal = ({
  onClose,
  meetingDetails,
  handleGetInviteStatus,
  isMepa,
}) => {
  const [showAgendaDetailModal, setShowAgendaDetailModal] = useState(false);
  const currentUser = useSelector(selectLoggedInUser);

  const {
    scheduledEventDetailLoading,
    scheduledEventDetailError,
    scheduledEventDetail,
  } = useSelector(selectEventDetail);

  return (
    <div className="py-8">
      {scheduledEventDetailLoading && <MeetingDetailLoader />}
      {scheduledEventDetail && !scheduledEventDetailLoading && (
        <div className={clsx("font-semibold max-w-[1405px] w-[90vw]")}>
          <div className="flex items-center justify-between gap-8">
            <div className="flex items-center gap-2">
              <Avatar
                userId={scheduledEventDetail.hostPersonID}
                firstName={scheduledEventDetail.host}
                lastName=""
                profileById={true}
                className="w-8 h-8 rounded-full"
                url="https://randomuser.me/api/portraits/men/3.jpg"
              />
              <p className="border border-gray500 rounded-[10px] px-4 py-1">
                {scheduledEventDetail.host}
              </p>
            </div>
            <p className="text-xl">{scheduledEventDetail.topic}</p>
            <p className="text-gray500">
              Last update <span>{scheduledEventDetail.lastModifiedOn}</span>
            </p>
          </div>
          <div className="flex gap-3 my-6">
            <div className={clsx("w-[calc(100%-290px-12px)]", "flex flex-col")}>
              <p className="text-gray500">Meeting infos</p>
              {meetingDetails?.mepA_TimeSlotsFinalizationTime === null
                ? meetingDetails?.mepA_ScheduledProposalsCount > 0 &&
                  scheduledEventDetail?.hostPersonID ===
                    currentUser?.userId && (
                    <div
                      className="border cursor-pointer bg-[#fdfdfd] border-[#e15c5c] rounded-[10px] p-4 h-full mb-2"
                      onClick={() => handleGetInviteStatus({ fixing: false })}
                    >
                      <div className="flex justify-between items-center w-[100%]">
                        <p>
                          External meeting ready for send out - Please select
                          time slots for invitees
                        </p>
                        <ArrowIcon />
                      </div>
                    </div>
                  )
                : meetingDetails?.mepA_ShouldBeManuallyFixedBefore !== null &&
                  meetingDetails?.mepA_FixedProposalId === null &&
                  scheduledEventDetail?.hostPersonID ===
                    currentUser?.userId && (
                    <div
                      className="border cursor-pointer bg-[#fdfdfd] border-[#e15c5c] rounded-[10px] p-4 h-full mb-2"
                      onClick={() => handleGetInviteStatus({ fixing: true })}
                    >
                      <div className="flex justify-between items-center w-[100%]">
                        <p>
                          Meeting approaching & pending responses - Please
                          indicate how you want to proceed
                        </p>
                        <ArrowIcon />
                      </div>
                    </div>
                  )}
              <div className="border border-gray500 rounded-[10px] p-4 h-full">
                <div
                  className={clsx(
                    "flex items-center gap-x-6 gap-y-3 flex-wrap",
                    "whitespace-nowrap font-normal",
                  )}
                >
                  <p className="flex items-center gap-2">
                    <ClockIcon fill="transparent" />
                    <span>{scheduledEventDetail?.meetingDuration}</span>
                  </p>
                  <p className="flex items-center gap-2">
                    <CalendarIcon fill="transparent" />
                    <span>{scheduledEventDetail?.meetingWindow}</span>
                  </p>
                  {scheduledEventDetail?.scheduledDateTime && (
                    <p className="flex items-center gap-2">
                      <CalendarCheckIcon fill="transparent" />
                      <span>{scheduledEventDetail.scheduledDateTime}</span>
                    </p>
                  )}
                  <p className="flex items-center gap-2">
                    <LocationIcon fill="transparent" />
                    <span>{scheduledEventDetail?.meetingPlatform}</span>
                  </p>
                  {!meetingDetails?.mepaId && !isMepa && (
                    <p className="flex items-center gap-2">
                      {scheduledEventDetail?.nonAdjustable ? (
                        <PinIcon fill="transparent" />
                      ) : (
                        <AdjustableIcon fill="transparent" />
                      )}
                      <span>
                        {scheduledEventDetail?.nonAdjustable
                          ? "Non-Adjustable Meeting"
                          : "Adjustable Meeting"}
                      </span>
                    </p>
                  )}
                </div>

                <div className="flex gap-4 items-start my-6">
                  <p className="flex items-center gap-2 w-[94px]">
                    <AgendaIcon fill="transparent" />
                    <span className="font-normal">Agenda</span>
                  </p>
                  <div className="">
                    <p className="font-normal">{scheduledEventDetail.agenda}</p>
                    {scheduledEventDetail.canExapandAgendaText && (
                      <button
                        className="border border-gray500 px-3 py-1 rounded-full mt-3"
                        onClick={() => setShowAgendaDetailModal(true)}
                      >
                        Expand Agenda
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={clsx("w-[290px]", "flex flex-col")}>
              <p className="text-gray500">History</p>
              <div className="border border-gray500 rounded-[10px] p-4 h-full">
                <p className="flex items-center gap-2 w-auto mb-2">
                  <GroupIcon fill="transparent" />
                  <span className="font-normal">Attendees</span>
                </p>
                <div className="flex items-center flex-di flex-wrap gap-1">
                  {scheduledEventDetail.participants.map((participantItem, index) => {
                    const hasApproved =
                      participantItem.response ===
                        MEETING_INVITATION_RESPONSE.ACCEPTED ||
                      participantItem?.canAttend === true;
                    const hasRejected =
                      participantItem.response ===
                        MEETING_INVITATION_RESPONSE.REJECTED ||
                      participantItem?.canAttend === false;
                    return (
                      <Fragment key={index}>
                        {!(
                          scheduledEventDetail?.hostPersonID ===
                          participantItem?.id
                        ) && (
                          <div
                            className={clsx(
                              "flex items-center justify-between w-full",
                            )}
                          >
                            <p
                              className={clsx(
                                "relative border border-gray500 rounded-[10px]",
                                `px-3 py-1 leading-none whitespace-nowrap ${
                                  hasApproved
                                    ? "bg-[#CCF0D8]"
                                    : hasRejected
                                      ? " bg-[#FFE9EE]"
                                      : "bg-[#FFE4AB]"
                                }`,
                              )}
                            >
                              {participantItem?.firstName
                                ? participantItem?.firstName
                                : participantItem?.emailAddress.split(
                                    "@",
                                  )[0]}{" "}
                              {participantItem?.lastName
                                ? participantItem?.lastName
                                : ""}
                            </p>
                            {hasApproved ? (
                              <TickIcon
                                fill="transparent"
                                className="h-7 w-7"
                              />
                            ) : hasRejected ? (
                              <CrossIcon
                                fill="transparent"
                                className="h-7 w-7"
                              />
                            ) : (
                              <QuestionIcon
                                fill="transparent"
                                className="h-7 w-7"
                              />
                            )}
                          </div>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              className={clsx(
                "border border-gray500",
                "w-[320px] px-[25px] py-3 bg-info100 rounded-full",
                "shadow-theme",
              )}
              onClick={onClose}
            >
              <span className="flex-grow">Close</span>
            </button>
          </div>
          {showAgendaDetailModal && (
            <Modal
              isOpen={showAgendaDetailModal}
              onClose={() => setShowAgendaDetailModal(false)}
              preventAnimate={true}
              hideHeader={true}
              modalClassName="border border-gray500 shadow-theme p-0"
            >
              <MeetingAgendModal
                onClose={() => setShowAgendaDetailModal(false)}
                agendaText={scheduledEventDetail.agendaFull}
              />
            </Modal>
          )}
        </div>
      )}
      {!!scheduledEventDetailError && (
        <MeetingDetailError
          onClose={onClose}
          actionError={scheduledEventDetailError}
        />
      )}
    </div>
  );
};
