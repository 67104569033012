import { Spinner } from "../Spinner";

export const MeetingDetailLoader = () => {
  return (
    <div className="w-[482px] h-[280px] flex flex-col items-center justify-center gap-4">
      <Spinner className="!w-8 !h-8 mb-2" />
      <p>Loading...</p>
    </div>
  );
};
