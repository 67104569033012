import clsx from "clsx";
import { ReactComponent as CrossIcon } from "../../assets/icons/Cross.svg";

export const DayChip = ({ day, type, onRemove }) => {
  return (
    <div
      className={clsx(
        "p-[1.5px] rounded-[6px] bg-gradient-to-b",
        type === "info"
          ? "from-gradientInfoStart to-gradientInfoEnd"
          : "from-gradientDangerStart to-gradientDangerEnd",
      )}
    >
      <div
        className={clsx(
          "flex flex-shrink items-center justify-center gap-1 rounded-[6px]",
          "font-semibold text-sm leading-none p-2",
          type === "info"
            ? "text-info400 bg-info100"
            : "text-danger bg-danger100",
        )}
      >
        <span>{day}</span>
        <CrossIcon
          className="w-4 h-4 cursor-pointer"
          fill="transparent"
          onClick={onRemove}
        />
      </div>
    </div>
  );
};
