import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Tooltip } from "react-tooltip";
import { ParticipantsChip } from "../ParticipantsChip";
import { getHoursDifferenceBetweenTimes } from "../../utils/dateTime";

import { ReactComponent as ChevronIcon } from "../../assets/icons/Chevron-Circle.svg";
import { ReactComponent as ChevronDangerIcon } from "../../assets/icons/chevron-danger.svg";

export const CalendarEventChip = ({
  meetingId,
  meetingTime,
  mepaId,
  topic,
  meetingLength,
  meetingStart,
  nonAdjustable,
  isProposal,
  isExternalMeeting,
  participants,
  remainingCounter,
  dayStartTime,
  containerClassName,
  onViewEventDetail,
  overlapping,
  numberOfOverlappingEvents,
  overlappingGroups,
  index,
}) => {
  const [widthPercentage, setWidthPercentage] = useState("100%");

  useEffect(() => {
    if (overlapping) {
      const result = `${parseInt(100 / (numberOfOverlappingEvents + 1)+10)}%`;
      setWidthPercentage(result);
    }
  }, [overlapping, numberOfOverlappingEvents]);

  const { positionTop, chipHeight } = useMemo(() => {
    let highestEndTime = new Date("2000-01-01T00:00:00");
    let highestStartTime = new Date("3000-01-01T00:00:00");
    const hoursFromDayStart = getHoursDifferenceBetweenTimes(
      dayStartTime,
      meetingStart,
    );

    let maxOverlapping = 1; // Maximum number of overlapping meetings

    overlappingGroups.forEach((group) => {
      if (group.length > 1) {
        maxOverlapping = Math.max(maxOverlapping, group.length);
        group.forEach((item) => {
          const endTime = new Date(item?.end);
          const startTime = new Date(item?.start);
          if (endTime > highestEndTime) {
            highestEndTime = endTime;
          }
          if (startTime < highestStartTime) {
            highestStartTime = startTime;
          }
        });
      }
    });

    // const differencStartAndEndTime = highestEndTime - highestStartTime;
    // const eventDurationInHours = Math.abs(differencStartAndEndTime) / (1000 * 60 * 60);

    return {
      positionTop: hoursFromDayStart * 91 + 2,
      chipHeight: overlapping
        ? meetingLength * 91 - 4
        : meetingLength * 91 - 4,
      // chipLeft: overlapping ? `calc(${(overlappingGroups.findIndex(group => group.some(item => item.meetingId === meetingId))) * 100 / maxOverlapping}%)` : '0%'
    };
  }, [
    dayStartTime,
    meetingStart,
    meetingLength,
    overlapping,
    numberOfOverlappingEvents,
    overlappingGroups,
  ]);

  const handleEventDetail = () => {
    if (isExternalMeeting) {
      return;
    }
    if (mepaId) {
      onViewEventDetail({ isProposal, mepaId });
    } else {
      onViewEventDetail({ entityID: meetingId, isProposal });
    }
  };

  return (
    <div
      className={clsx(
        "border rounded-[8px] p-2 absolute",
        "bg-gradient-to-b text-neutral min-h-[35px] hover:z-50",
        isExternalMeeting
          ? "border-success bg-success100 !text-success !cursor-default"
          : nonAdjustable
            ? "border-[transparent] from-gradientDangerStart to-gradientDangerEnd"
            : "border-info400 from-gradientInfoStart to-gradientInfoEnd",
        "flex w-full cursor-pointer",
        containerClassName,
        index >= 1 ? `right-${(index+1)}` :  ""
      )}
      style={{ height: chipHeight, top: positionTop, width: widthPercentage, }}
      onClick={handleEventDetail}
      id={`calendarMeeting${meetingId}`}
    >
      {meetingLength < 1 ? (
        <div className="flex items-start justify-between w-full ">
          <div className="w-[80%]">
            <p
              className={clsx(
                "text-sm",
                "overflow-hidden whitespace-nowrap text-ellipsis w-full",
              )}
            >
              {!topic ? "External Meeting" : topic}
            </p>
            {meetingLength > 0.64 && (
              <>
                {overlapping ? (
                  <p className="text-[10px]">{"Multiple meetings"}</p>
                ) : (
                  <p className="text-[10px]">{meetingTime}</p>
                )}
              </>
            )}
          </div>

          {!isExternalMeeting && (
            <div className="w-5 h-5 rounded-full bg-neutral">
              {nonAdjustable ? (
                <ChevronDangerIcon
                  className="w-full h-full"
                  fill="transparent"
                />
              ) : (
                <ChevronIcon className="w-full h-full" fill="transparent" />
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-between w-full">
          <div className="flex gap-2">
            <div
              className={clsx(
                isExternalMeeting ? "w-[calc(100%-28px)]" : "w-full",
              )}
            >
              <p
                className={clsx(
                  "text-sm w-full leading-tight",
                  "overflow-hidden whitespace-nowrap text-ellipsis w-full",
                )}
              >
                {!topic ? "External Meeting" : topic}
              </p>
              {/* {overlapping ? (
                <p className="text-[10px]">{"More than one meeting"}</p>
              ) : ( */}
                <p className="text-[10px] leading-none">{meetingTime}</p>
              {/* )} */}
            </div>
          </div>
          {!isExternalMeeting && (
            <ParticipantsChip
              remainingCounter={remainingCounter}
              participants={participants}
              containerClassName="bg-neutral border-neutral"
            />
          )}
        </div>
      )}
      {isExternalMeeting && (
        <Tooltip
          anchorSelect={`#calendarMeeting${meetingId}`}
          content="Please check your external calendar"
          place="top-start"
          offset={4}
          className="!py-1 !px-2 !rounded-md !text-[10px] text-center"
          noArrow={true}
        />
      )}
    </div>
  );
};
