import clsx from "clsx";
import { ReactComponent as ArrowIcon } from "../../assets/icons/Arrow.svg";

export const LinkCard = ({ title, onClick }) => {
  return (
    <div
      className={clsx(
        "flex flex-col justify-between",
        "bg-neutral px-4 py-4 4xl:py-7 border border-gray500 rounded-[10px] shadow-theme",
        "w-[calc(50%-12px)] h-[calc(50%-12px)] cursor-pointer",
      )}
      onClick={onClick}
    >
      <h2 className="text-xl 3xl:text-2xl">{title}</h2>
      <p className="inline-block">
        <ArrowIcon className="stroke-2" />
      </p>
    </div>
  );
};
