import clsx from "clsx";
import { ReactComponent as CrossIcon } from "../../assets/icons/Cross.svg";

export const Modal = ({
  isOpen,
  onClose,
  hideHeader,
  Header,
  preventBackdropClose,
  preventAnimate,
  modalClassName,
  modalContentClassName,
  children,
}) => {
  return (
    <div
      className={clsx(
        "fixed w-full h-full left-0 bg-[#000000a1] z-10",
        isOpen ? "bottom-0" : "bottom-[-100%]",
      )}
      onClick={preventBackdropClose ? () => {} : onClose}
    >
      <div
        className={clsx(
          "fixed left-0 w-full h-full",
          "flex items-center justify-center",
          isOpen === true && !preventAnimate && "bottom-0 animate-slideUp",
          isOpen === false && !preventAnimate && "animate-slideDown",
        )}
      >
        <div
          className={clsx(
            "fixed bg-neutral opacity-1 rounded-[10px]",
            !hideHeader && "p-4",
            modalClassName,
          )}
          onClick={(e) => e.stopPropagation()}
        >
          {!hideHeader && (
            <div className="flex justify-between items-center mb-3">
              {!!Header && <Header />}
              <button
                className="hover:bg-gray300 rounded-full p-1 ml-auto"
                onClick={onClose}
              >
                <CrossIcon fill="transparent" />
              </button>
            </div>
          )}
          <div
            className={clsx(
              "max-h-[calc(96vh-76px);] overflow-y-scroll",
              modalContentClassName,
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
