export const LOGIN_API = "api/Authenticate/login";
export const RECOVER_PASSWORD_API = "api/Authenticate/SendPasswordResetLink";
export const FETCH_MY_PROFILE_API = "api/Authenticate/MyProfile";
export const GET_MY_PROFILE_IMAGE_API = "api/Authenticate/GetMyProfileImage";
export const GET_USER_PROFILE_IMAGE_API =
  "api/Authenticate/GetUserProfileImageByUserId";
export const GET_IANA_TIMEZONES =
  "api/PersonMeetingPreferences/GetIANATimezones";

export const GET_ALL_MEETINGPLATFORMS = "api/MeetingPlatforms/GetAll";
export const GET_ALL_CALENDAR_PLATFORMS =
  "api/ExternalCalendersIntegration/GetMyExternalCalendarInfrastructures";
export const GET_ALL_NOTIFICATIONSTYPES = "api/Messaging/GetAllMessageTypes";

export const GET_MY_MEETING_PREFERENCE =
  "api/PersonMeetingPreferences/GetMyMeetingPreference";
export const UPDATE_MY_MEETING_PREFERENCE = "api/PersonMeetingPreferences";
export const UPDATE_MY_TIMEZONE_API =
  "api/PersonMeetingPreferences/UpdateMyTimezone";

export const SYNC_MY_CALENDARS_API = "api/ExternalCalendersIntegration/SyncMe";
export const SET_MY_PROFILE_IMAGE_API = "api/Authenticate/SetMyProfileImage";
export const RESET_MY_PASSWORD_API = "api/Authenticate/ResetMyPassword";
export const UPDATE_MY_PROFILE_API = "api/Authenticate/UpdateMyProfile";

export const ABSENCES_MODULE_API = "api/Absence";
export const GET_MY_ABSENCES_API = "api/Absence/GetAbsences";

export const GET_External_INVITABLE_USERS_API =
  "api/ExternalUser/SearchExternalUsers";
// Meeting Time Slot
export const GET_Check_Meeting_Time_Slot =
  "api/MEPA/CheckMEPAProposalScheduledTimes";
// Mepa Select Time Slot
export const Post_Meeting_Time_Slot = "api/MEPA/SelectTimeSlots";
// MEETING REQUESTS
export const GET_INVITABLE_USERS_API = "api/People/GetPersonsToInvite";
export const MEETING_REQUEST_BASE_API = "api/MeetingProposal";
export const CHECK_MEETING_VALIDITY = "api/Scheduling/CheckValidity";
export const CHECK_MEPA_MEETING_VALIDITY = "api/Scheduling/CheckMEPAValidity";
export const MEPA_MEETING_REQUEST = "api/MEPA";
export const MEPA_GET_INVITE_STATUS = "api/MEPA/GetInviteStatus";
export const CHECK_MEPA_PROPOSAL_SCHEDULED_TIMES =
  "api/MEPA/CheckMEPAProposalScheduledTimes";

export const GET_MEETING_REQUEST_INVITES = "api/MEPA/GetMyFutureInvites";
// 'api/MeetingProposal/GetFutureProposalsHostedByMeOrIhaveBeenInvitedTo';
export const GET_MY_FUTURE_PROPOSALS =
  "api/MeetingProposal/GetMyFutureProposalsAndMEPAs";
export const RESPONSE_TO_PROPOSAL_API =
  "api/MeetingProposal/ResponseToProposal";
export const Response_To_MEPA = "api/MEPA/ResponseToMEPA";

export const GET_SCHEDULED_MEETING_API =
  "api/SchedulingSession/GetUserScheduledEventsExtended";
export const GET_MEETING_HISTORY_API =
  "api/SchedulingSession/GetProposalSessions";

export const MEETINGS_BASE_API = "api/Meeting";
export const MEPA_SELECT_TIME_SLOTS = "api/MEPA/SelectTimeSlots";
export const MEPA_FIX_MEPA = "api/MEPA/FixMEPA";

export const GET_NOTIFICATIONS_API = "api/Messaging/GetMyLatestMessages";
export const MARK_NOTIFICATIONS_RECEIVED_API = "api/Messaging/Received";
export const MARK_NOTIFICATION_SEEN_API = "api/Messaging/Viewed";
export const QUERY_SUBMISSION_API = "api/Messaging/ContactUs";
export const MESSAGING_HUB_URL = "messagingHub";

export const USER_SERVER_LOOKUP_API = `https://instances.zelvor.com/api/InstanceAggregator/UserInstanceLookup`;
