export const MeetingListingHead = () => {
  return (
    <thead className="text-gray500 text-left">
      <tr>
        <th className="pl-5">Date / Time</th>
        <th>Subject</th>
        <th>Host</th>
        <th>Type</th>
        <th>Duration</th>
        <th>Location</th>
        <th className="text-center">Actions</th>
      </tr>
    </thead>
  );
};
