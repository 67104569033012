import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LinkCard,
  DashboardCalendarCard,
  DashboardIntegrationCard,
  DashboardNotifsCard,
  DashboardRequestsCard,
} from "../../components/dashboard";
import { Modal } from "../../components/layout/Modal";
import { CreateMeetingModal } from "../../components/modals/CreateMeetingModal";
import { ConfirmationModal } from "../../components/modals/ConfirmationModal";

import {
  fetchInvitableUsers,
  resetAddMeetingStatus,
  fetchExternalInvitableUsers,
  selectAddMeetingRequestStatus,
  setActiveModal,
  updateIntervalValue,
  clearTimeoutId,
  fetchMeetingInvitations,
  deleteMeetingRequest,
} from "../../store/meetingRequests";
import { MepaMeeting } from "./MepaMeeting";
import { resetScheduledEventUpdateError } from "../../store/scheduledEvents";
import { selectLoggedInUser } from "../../store/user";

export const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [activeModal, setActiveModal] = useState("");
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState(false);
  const [formHaveUnsavedChanges, setFormHaveUnsavedChanges] = useState(false);

  const {
    addMeetingRequestLoading,
    addMepaMeetingRequestError,
    addMeetingRequestSuccess,
    addMepaMeetingRequestSuccess,
    checkMeetingValidity,
    checkMeetingValidityError,
    addMeetingRequestError,
    addMepaTimesloteSuccess,
    activeModal,
    // selectAddMeetingRequestStatus,
  } = useSelector(selectAddMeetingRequestStatus);

  const navigateToLink = (link) => {
    navigate(`../${link}`, { relative: true });
  };

  const showModal = (modalType) => {
    dispatch(setActiveModal(modalType));
    // setActiveModal(modalType);
  };

  const onHideCreateMeetingModal = () => {
    dispatch(setActiveModal(""));
    dispatch(resetAddMeetingStatus());
    dispatch(resetScheduledEventUpdateError());
  };

  const onFormStatusChange = (haveChanges) => {
    setFormHaveUnsavedChanges(haveChanges);
  };

  const newCreatedMepaMeeting = useSelector(
    (state) => state?.meetingRequests?.createdMepaResponseId,
  );
  const currentUser = useSelector(selectLoggedInUser);

  const handleCancel = () => {
    const payload = {
      mepaId: newCreatedMepaMeeting,
      userId: currentUser?.userId,
      hostPersonID: currentUser?.userId,
    };
    dispatch(deleteMeetingRequest(payload));
  };

  const onTryToCloseCreateMeetingModal = () => {
    if (formHaveUnsavedChanges) {
      setShowDiscardConfirmation(true);
    } else {
      onHideCreateMeetingModal();
    }
  };

  const onDiscardAndCloseCreateMeetingModal = () => {
    dispatch(clearTimeoutId());
    setFormHaveUnsavedChanges(false);
    setShowDiscardConfirmation(false);
    updateIntervalValue(false);
    onHideCreateMeetingModal();
    if (newCreatedMepaMeeting) {
      handleCancel();
    }
  };

  const location = useLocation();

  useEffect(() => {
    dispatch(fetchInvitableUsers());
    dispatch(fetchMeetingInvitations());
  }, [location.pathname]);

  return (
    <div className="font-semibold text-sm mx-[34px] pt-[68px] pb-[22px] h-[calc(100vh-160px)]">
      <div className="mb-6 flex gap-x-6 h-[calc(50%-12px)] min-h-[304px]">
        <div className="flex flex-wrap gap-6 w-[calc(50%-12px)]">
          <LinkCard
            title="Schedule internal meeting"
            onClick={() => showModal("meeting")}
          />
          <LinkCard
            title="Schedule external meeting"
            onClick={() => showModal("mepa")}
          />
          <LinkCard
            title="Review meeting requests"
            onClick={() => navigateToLink("meeting-requests")}
          />
          <LinkCard
            title="Manage meetings"
            onClick={() => navigateToLink("manage-meetings")}
          />
          {/* <LinkCard
						title='Manage absences'
						onClick={() => navigateToLink('manage-absences')}
					/> */}
        </div>
        <DashboardCalendarCard />
      </div>
      <div className="flex gap-x-6 h-[calc(50%-12px)] min-h-[300px] pb-4">
        <DashboardIntegrationCard />
        <DashboardNotifsCard />
        <DashboardRequestsCard />
      </div>
      {activeModal === "meeting" && (
        <Modal
          isOpen={activeModal === "meeting"}
          onClose={onTryToCloseCreateMeetingModal}
          preventAnimate={true}
          Header={() => (
            <p className="text-2xl font-semibold">Scheduling Assistant</p>
          )}
          modalClassName="p-8 pb-0"
          modalContentClassName="-mx-8"
          preventBackdropClose={addMeetingRequestLoading}
          hideHeader={
            !!(
              addMeetingRequestSuccess ||
              addMeetingRequestError ||
              checkMeetingValidityError
            )
          }
        >
          <CreateMeetingModal
            actionLoading={addMeetingRequestLoading}
            actionSuccess={addMeetingRequestSuccess}
            checkMeetingValidity={checkMeetingValidity}
            checkMeetingValidityError={checkMeetingValidityError}
            actionError={addMeetingRequestError}
            onFormStatusChange={onFormStatusChange}
            onClose={onTryToCloseCreateMeetingModal}
          />
        </Modal>
      )}
      {activeModal === "mepa" && (
        <Modal
          isOpen={activeModal === "mepa"}
          onClose={onTryToCloseCreateMeetingModal}
          preventAnimate={true}
          Header={() => (
            <p className="text-2xl font-semibold">Meeting scheduler</p>
          )}
          modalClassName="p-8 pb-0"
          modalContentClassName="-mx-8"
          preventBackdropClose={addMeetingRequestLoading}
          hideHeader={
            !!(
              addMeetingRequestSuccess ||
              addMepaMeetingRequestError ||
              checkMeetingValidityError
            )
          }
        >
          <MepaMeeting
            actionLoading={addMeetingRequestLoading}
            // actionLoading={addMepaMeetingRequestLoading}
            actionSuccess={addMepaMeetingRequestSuccess}
            checkMeetingValidity={checkMeetingValidity}
            checkMeetingValidityError={checkMeetingValidityError}
            actionError={addMepaMeetingRequestError}
            onfetchExternalInvities={fetchExternalInvitableUsers}
            onFormStatusChange={onFormStatusChange}
            onClose={onTryToCloseCreateMeetingModal}
            onHideCreateMeetingModal={onHideCreateMeetingModal}
            addMepaTimesloteSuccess={addMepaTimesloteSuccess}
            mepa="mepa"
          />
        </Modal>
      )}
      {showDiscardConfirmation && (
        <Modal
          isOpen={showDiscardConfirmation}
          onClose={() => {}}
          preventAnimate={true}
          preventBackdropClose={true}
          hideHeader={true}
        >
          <ConfirmationModal
            description="Your unsaved changes will be discarded, do you want to quit?"
            confirmButtonTitle="Confirm"
            onConfirm={onDiscardAndCloseCreateMeetingModal}
            onCancel={() => setShowDiscardConfirmation(false)}
          />
        </Modal>
      )}
      {/* {showDiscardConfirmation && (
        <Modal
          isOpen={showDiscardConfirmation}
          onClose={() => {}}
          preventAnimate={true}
          preventBackdropClose={true}
          hideHeader={true}
        >
          <ConfirmationModal
            description="Your unsaved changes will be discarded, do you want to quit?"
            confirmButtonTitle="Confirm"
            onConfirm={onDiscardAndCloseCreateMeetingModal}
            onCancel={() => setShowDiscardConfirmation(false)}
          />
        </Modal>
      )} */}
    </div>
  );
};
