import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";
import Avatar from "../Avatar";
import { ReactComponent as BellIcon } from "../../assets/icons/bell.svg";
import { selectLoggedInUser } from "../../store/user";
import { SCREEN_MESSAGES } from "../../utils/constants";

export const Header = () => {
  const loggedInUser = useSelector(selectLoggedInUser);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onGoToNotificationsSettings = () => {
    navigate("/admin/settings?section=notifications");
  };

  const onGoToProfileSettings = () => {
    navigate("/admin/settings?section=profile");
  };

  const headerMessage = useMemo(() => {
    return SCREEN_MESSAGES[pathname];
  }, [pathname]);

  return (
    <div className="flex items-stretch flex-wrap mb-4">
      <div className="py-3 pl-8">
        <h1 className="mb-3">Hello {loggedInUser?.userFirstName || "User"}</h1>
        <p className="font-semibold text-primary text-base">{headerMessage}</p>
      </div>
      <div
        className={clsx(
          "flex gap-2 flex-grow items-center justify-end",
          "py-3 pr-8",
        )}
      >
        <div
          className={clsx(
            "flex items-center justify-center",
            "w-12 h-12 rounded-full bg-primary",
            "cursor-pointer",
          )}
          onClick={onGoToNotificationsSettings}
        >
          <BellIcon className="w-5 h-5 fill-[transparent] text-neutral" />
        </div>
        <Avatar
          url={loggedInUser?.profilePhoto}
          firstName={loggedInUser?.userFirstName || "User"}
          onClick={onGoToProfileSettings}
        />
      </div>
      <div className="w-[80%] border-b border-[#2DACE033] ml-auto"></div>
    </div>
  );
};
