import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { DEFAULT_SERVER_URL, SCREEN_MESSAGES } from "../utils/constants";
import { getServerUrl, updateServerUrl } from "../utils/localStorage";
import axiosClient from "../utils/axiosClient";
import {
  GET_ALL_CALENDAR_PLATFORMS,
  GET_ALL_MEETINGPLATFORMS,
  GET_ALL_NOTIFICATIONSTYPES,
  GET_IANA_TIMEZONES,
  USER_SERVER_LOOKUP_API,
} from "../utils/apiRoutes";
import { groupNotificationsData } from "../utils/dataParser";

const initialState = {
  serverUrl: getServerUrl() || DEFAULT_SERVER_URL,
  sidebarCollapsible: false,
  sidebarCollapsed: false,
  screenMessage: SCREEN_MESSAGES["/admin/dashboard"],
  availableTimezones: [],
  availableMeetingPlatforms: [],
  availableCalendarPlatforms: [],
  notificationsTypes: [],
  notificationsGroupedTypes: [],
};

export const lookupUserServer = createAsyncThunk(
  "configs/fetchTimezones",
  async (username, { dispatch }) => {
    try {
      const { data: result } = await axiosClient.get(
        `${USER_SERVER_LOOKUP_API}?userName=${username}`,
      );
      const parsedResult = result.map((server) => ({
        name: server.instanceTitle,
        url: server.instanceAddress.replace("https:\\\\", "https://"),
      }));

      // If User is only in 1 server, set it direclty
      // If Not in any server, set the default server
      if (parsedResult.length === 1) {
        await dispatch(setServerUrl(parsedResult[0].url));
      } else if (parsedResult.length === 0) {
        await dispatch(setServerUrl(DEFAULT_SERVER_URL));
      }

      return parsedResult;
    } catch (error) {
      await dispatch(setServerUrl(DEFAULT_SERVER_URL));
      return [];
    }
  },
);

export const fetchTimezones = createAsyncThunk(
  "configs/fetchTimezones",
  async (_, { getState }) => {
    const { serverUrl } = getState().configs;
    const { data: result } = await axiosClient.get(
      `${serverUrl}/${GET_IANA_TIMEZONES}`,
    );
    return result;
  },
);

export const fetchAllMeetingPlatforms = createAsyncThunk(
  "configs/fetchAllMeetingPlatforms",
  async (_, { getState }) => {
    const { serverUrl } = getState().configs;
    const { data: result } = await axiosClient.get(
      `${serverUrl}/${GET_ALL_MEETINGPLATFORMS}`,
    );
    return result;
  },
);

export const fetchAllCalendarPlatforms = createAsyncThunk(
  "configs/fetchAllCalendarPlatforms",
  async (_, { getState }) => {
    const { serverUrl } = getState().configs;
    const { data: result } = await axiosClient.get(
      `${serverUrl}/${GET_ALL_CALENDAR_PLATFORMS}`,
    );
    const filteredCalendars = result.filter(
      (calendar) => calendar.externalCalendarInfrastructureId !== 3,
    );
    return filteredCalendars;
  },
);

export const fetchAllNotificationsTypes = createAsyncThunk(
  "configs/fetchAllNotificationsTypes",
  async (_, { getState }) => {
    const { serverUrl } = getState().configs;
    const { data: result } = await axiosClient.get(
      `${serverUrl}/${GET_ALL_NOTIFICATIONSTYPES}`,
    );

    return { result: result, groupedResult: groupNotificationsData(result) };
  },
);

const slice = createSlice({
  name: "configs",
  initialState,
  reducers: {
    setServerUrl: (state, { payload }) => {
      updateServerUrl(payload);
      state.serverUrl = payload;
    },
    setScreenMessage: (state, { payload }) => {
      state.screenMessage = payload;
    },
    setSidebarCollapsible: (state, { payload }) => {
      state.sidebarCollapsible = payload;
      state.sidebarCollapsed = payload;
    },
    setSidebarCollapsed: (state, { payload }) => {
      state.sidebarCollapsed = payload;
    },
    resetConfigsState: (state) => {
      state.availableCalendarPlatforms = [];
      state.availableMeetingPlatforms = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTimezones.fulfilled, (state, { payload }) => {
      state.availableTimezones = payload;
    });
    builder.addCase(
      fetchAllMeetingPlatforms.fulfilled,
      (state, { payload }) => {
        state.availableMeetingPlatforms = payload;
      },
    );
    builder.addCase(
      fetchAllCalendarPlatforms.fulfilled,
      (state, { payload }) => {
        state.availableCalendarPlatforms = payload || []; 
      },
    );
    builder.addCase(
      fetchAllNotificationsTypes.fulfilled,
      (state, { payload }) => {
        state.notificationsTypes = payload.result;
        state.notificationsGroupedTypes = payload.groupedResult;
      },
    );
  },
});

// ACTIONS
export const {
  setServerUrl,
  setScreenMessage,
  setSidebarCollapsible,
  setSidebarCollapsed,
  resetConfigsState,
} = slice.actions;

// SELECTORS
const selectConfigsData = (state) => {
  return state.configs;
};

export const selectServerUrl = createSelector(selectConfigsData, (data) => {
  return data.serverUrl;
});

export const selectSidebarCollapsible = createSelector(
  selectConfigsData,
  ({ sidebarCollapsible }) => sidebarCollapsible,
);

export const selectSidebarCollapsed = createSelector(
  selectConfigsData,
  ({ sidebarCollapsible, sidebarCollapsed }) => {
    return sidebarCollapsible && sidebarCollapsed;
  },
);

export const selectScreenMessage = createSelector(selectConfigsData, (data) => {
  return data.screenMessage;
});

export const selectAvailableTimezones = createSelector(
  selectConfigsData,
  (data) => {
    return data.availableTimezones;
  },
);

export const selectAvailableMeetingPlatforms = createSelector(
  selectConfigsData,
  (data) => {
    return data?.availableMeetingPlatforms;
  },
);

export const selectAvailableCalendarPlatforms = createSelector(
  selectConfigsData,
  (data) => {
    return data.availableCalendarPlatforms;
  },
);

export const selectNotificationsTypes = createSelector(
  selectConfigsData,
  (data) => {
    return data.notificationsTypes;
  },
);

export const selectNotificationsGroupedTypes = createSelector(
  selectConfigsData,
  (data) => {
    return data.notificationsGroupedTypes;
  },
);

export default slice.reducer;
