import clsx from "clsx";
import { ReactComponent as TickIcon } from "../../assets/icons/Tick.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/Cross.svg";
import { Spinner } from "../Spinner";

export const SettingsActionButtons = ({
  disableSumbit,
  disableFromsReset,
  formsSumitProcessing,
  handleSubmit,
  handleReset,
}) => {
  return (
    <div className="flex justify-end gap-6 pb-4 border-b border-gray300">
      <button
        className={clsx(
          "flex items-center gap-6 px-6 font-semibold",
          "w-60 h-12",
          "border border-gray500 rounded-full bg-[#EAF9F8]",
          !disableSumbit && "shadow-theme",
        )}
        disabled={disableSumbit || formsSumitProcessing}
        onClick={handleSubmit}
      >
        <TickIcon className="w-6 h-6" fill="transparent" />
        <div className="flex items-center gap-2 shrink-0">
          <span>Save Changes</span>
          {formsSumitProcessing && <Spinner />}
        </div>
      </button>
      <button
        className={clsx(
          "flex items-center gap-6 px-6 font-semibold",
          "w-60 h-12 bg-neutral text-danger text-nowrap",
          "border border-[#FFE9EE] rounded-full",
          !disableFromsReset && "shadow-theme",
        )}
        disabled={disableFromsReset || formsSumitProcessing}
        onClick={handleReset}
      >
        <CrossIcon className="w-6 h-6" fill="transparent" />
        <span>Discard Changes</span>
      </button>
    </div>
  );
};
