import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { SelectedHoursSlice } from "./SelectedHoursSlice";
import {
  getHoursBetween,
  getMergedTimeSlices,
  getSliceFromValues,
  parseTimeSlotsForRangeSlots,
} from "../../utils/dateTime";

export const HoursRange = ({
  type,
  startTime,
  endTime,
  selectedRanges,
  onUpdateTimeRanges,
  hasError,
  error,
}) => {
  const [startEndPos, setStartEndPos] = useState({ start: 0, end: 0 });
  const [isDragActive, setIsDragActive] = useState(false);

  const [selectedTimeSlices, setSelectedTimeSlices] = useState([
    // {
    // 	start: '9:30',
    // 	end: '11:30',
    // 	timeSliceLength: 2,
    // 	id: '9:30_11:30',
    // },
  ]);

  const rangeRef = useRef();

  const hoursSlotsList = useMemo(
    () => getHoursBetween(startTime, endTime),
    [startTime, endTime],
  );

  const onSelectionUpdate = ({ rangeStart, sliceId, timeSliceLength }) => {
    const [start, end] = getSliceFromValues(
      startTime,
      rangeStart,
      timeSliceLength,
    );

    const updatedSlice = {
      start,
      end,
      timeSliceLength,
      id: `${start}_${end}`,
    };

    const filteredSlices = selectedTimeSlices.filter(
      (slice) => slice.id !== sliceId,
    );

    const updatedSlicesList = getMergedTimeSlices(filteredSlices, updatedSlice);
    setSelectedTimeSlices(updatedSlicesList);
    onUpdateTimeRanges(updatedSlicesList);

    console.log("ON SELECTION UPDATE::::", updatedSlicesList, updatedSlice);
  };

  const onAddTimeSlice = ({ id, start, end, timeSliceLength }) => {
    const updatedSlice = {
      id,
      start,
      end,
      timeSliceLength,
    };

    const updatedSlicesList = getMergedTimeSlices(
      selectedTimeSlices,
      updatedSlice,
    );
    setSelectedTimeSlices(updatedSlicesList);
    onUpdateTimeRanges(updatedSlicesList);
  };

  const onRemoveSlice = (sliceId) => {
    const slices = selectedTimeSlices.filter((slice) => slice.id !== sliceId);
    setSelectedTimeSlices(slices);
    onUpdateTimeRanges(slices);
  };

  const rangeWidth = useMemo(() => {
    return (
      hoursSlotsList.reduce((prev, current) => {
        return prev + current.timeSliceLength;
      }, 0) *
        36 +
      6
    );
  }, [hoursSlotsList]);

  useEffect(() => {
    // Update Local Ranges when the parent values get changed
    const updateSlices = parseTimeSlotsForRangeSlots(selectedRanges);
    setSelectedTimeSlices(updateSlices);
  }, [selectedRanges]);

  useEffect(() => {
    if (rangeRef.current) {
      const position = {
        start: rangeRef.current.offsetLeft + 3,
        end: rangeRef.current.offsetLeft + rangeRef.current.offsetWidth - 3,
      };
      setStartEndPos(position);
    }
  }, [rangeRef.current, startTime, endTime]);

  return (
    <>
      {hoursSlotsList?.length > 0 && (
        <div className="text-gray500 font-semibold text-sm w-fit mt-3 !select-none">
          <div
            className={clsx(
              "flex border rounded-[6px] relative",
              "px-[2px]",
              hasError ? "border-danger" : "border-gray500",
            )}
            style={{ width: rangeWidth }}
            ref={rangeRef}
          >
            {hoursSlotsList.map((slotItem, index) => (
              <div
                key={index}
                className={clsx(
                  "h-9 flex items-center justify-center",
                  "z-[2]",
                  "border-l border-gray500",
                  index === 0 ? "border-opacity-0" : "border-opacity-40",
                  isDragActive ? "cursor-col-resize" : "cursor-pointer",
                  !isDragActive &&
                    (type === "info"
                      ? " hover:bg-info100"
                      : " hover:bg-danger100"),
                )}
                style={{ width: slotItem.timeSliceLength * 36 }}
                onClick={() => onAddTimeSlice(slotItem)}
              >
                <span
                  className={clsx(
                    slotItem.minutesDisplay && "text-[8px] -rotate-90",
                  )}
                >
                  {slotItem.minutesDisplay || slotItem.hour}
                </span>
              </div>
            ))}
            {selectedTimeSlices.map((timeSlice, index) => (
              <SelectedHoursSlice
                key={index}
                type={type}
                sliceId={timeSlice.id}
                workingHoursStart={startTime}
                workingHoursEnd={endTime}
                sliceStart={timeSlice.start}
                selectedHours={timeSlice.timeSliceLength}
                startEndPos={startEndPos}
                setIsDragActive={setIsDragActive}
                onSelectionUpdate={onSelectionUpdate}
                onRemoveSlice={onRemoveSlice}
              />
            ))}
          </div>
          <div className={clsx("flex justify-between items-center mt-1")}>
            <p>Morning</p>
            <p>Afternoon</p>
          </div>
          {hasError && (
            <p className="text-danger text-xs font-normal">{error}</p>
          )}
        </div>
      )}
    </>
  );
};
