import { useState } from "react";
import { useSelector } from "react-redux";
import { SettingsCard } from "./SettingsCard";
import { RedirectionConfirmationModal } from "../modals/RedirectionConfirmationModal";
import { Modal } from "../layout/Modal";
import { selectAvailableCalendarPlatforms } from "../../store/configs";

import { ReactComponent as SyncCalendarIcon } from "../../assets/icons/sync_calendar.svg";
import { ReactComponent as IconTick } from "../../assets/icons/Tick.svg";

const logosByPlatform = {
  Zoom: "/apps/zoom_logo.svg",
  Azure: "/apps/outlook_logo.svg",
  Google: "/apps/gcalendar_logo.png",
};

export const SettingsCalendarSync = ({ listRef }) => {
  const availableCalendars = useSelector(selectAvailableCalendarPlatforms);
  const [showRedirectionAlert, setShowRedirectionAlert] = useState(false);

  const onToggleRedirectionAlert = () => {
    setShowRedirectionAlert(!showRedirectionAlert);
  };

  return (
    <SettingsCard
      title="Calendar sync"
      description="Join your calendars all in one place"
      Icon={SyncCalendarIcon}
      sectionKey="calendar-sync"
      listRef={listRef}
    >
      <div className="ml-[302px]">
        <h5 className="text-sm font-semibold mb-7">
          Synchronyze your Zelvor Meetings with:
        </h5>
        <div className="flex items-center gap-4 flex-wrap">
          {availableCalendars.map((calendarApp, index) => (
            <div
              key={index}
              className="flex gap-1 items-center"
            >
              <img
                className="max-h-[25px] h-full w-auto"
                src={
                  logosByPlatform[
                    calendarApp.externalCalendarInfrastructureTitle
                  ]
                }
                alt={calendarApp.externalCalendarInfrastructureTitle}
              />
              <button
                className="text-sm font-semibold"
                onClick={onToggleRedirectionAlert}
              >
                {calendarApp.userIsLinked ? (
                  <IconTick
                    className="text-success w-5 h-5 ml-1"
                    fill="transparent"
                  />
                ) : (
                  <span className="underline">Connect</span>
                )}
              </button>
            </div>
          ))}
        </div>
      </div>
      {showRedirectionAlert && (
        <Modal
          isOpen={showRedirectionAlert}
          onClose={onToggleRedirectionAlert}
          preventAnimate={true}
          hideHeader={true}
          modalClassName="border border-gray500 shadow-theme p-0"
        >
          <RedirectionConfirmationModal
            title="Integrations"
            description={"You'll be redirected outside of Zelvor"}
            onClose={onToggleRedirectionAlert}
          />
        </Modal>
      )}
    </SettingsCard>
  );
};
