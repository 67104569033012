import { useEffect, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../components/PageTitle";
import { Modal } from "../../components/layout/Modal";
import { Tooltip } from "react-tooltip";
import { DeleteAbsenceModal } from "../../components/modals/DeleteAbsenceModal";
import { ManageAbsenceModal } from "../../components/modals/ManageAbsenceModal";
import { Spinner } from "../../components/Spinner";

import { ReactComponent as AbsenceIcon } from "../../assets/icons/Absence.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/Plus.svg";
import { ReactComponent as WrenchIcon } from "../../assets/icons/Wrench.svg";
import { ReactComponent as CrossCircledIcon } from "../../assets/icons/Circled_cross.svg";
import { ReactComponent as EmptyListIcon } from "../../assets/icons/empty-box.svg";
import {
  updateExistingAbsence,
  addNewAbsence,
  deleteAbsence,
  fetchAbsences,
  resetAbsencesActionData,
  selectAbsenceActionData,
  selectAbsences,
} from "../../store/absences";

export const Absences = () => {
  const dispatch = useDispatch();
  const { absencesLoading, absencesRefreshing, absencesList } =
    useSelector(selectAbsences);
  const {
    absenceActionLoading,
    absenceActionErrorMessage,
    absenceActionSuccessMessage,
  } = useSelector(selectAbsenceActionData);

  const [showAbsenceDeleteModal, setShowAbsenceDeleteModal] =
    useState(undefined);
  const [showAbsenceActionModal, setShowAbsenceActionModal] =
    useState(undefined);
  const [absenceActionType, setAbsenceActionType] = useState("");
  const [absenceData, setAbsenceData] = useState(null);

  const onToggleAbsenceModal = () => {
    setAbsenceActionType("");
    setShowAbsenceActionModal(false);
    setAbsenceData(null);
    dispatch(resetAbsencesActionData());
  };

  const onSwitchActionType = (newAction) => {
    if (newAction === "delete") {
      setAbsenceActionType("");
      setShowAbsenceActionModal(false);
      setShowAbsenceDeleteModal(true);
    } else {
      setAbsenceActionType(newAction);
    }
  };

  const onToggleAbsenceDeleteModal = (event, data) => {
    event && event.stopPropagation();
    setShowAbsenceDeleteModal(!!data);
    setAbsenceData(data);
    dispatch(resetAbsencesActionData());
  };

  const onDelete = (absenceId) => {
    dispatch(deleteAbsence(absenceId));
  };

  const onAddAbsence = () => {
    setAbsenceActionType("add");
    setShowAbsenceActionModal(true);
  };

  const onAbsenceAction = (absenceItem, actionType, event) => {
    event && event.stopPropagation();
    setAbsenceActionType(actionType);
    setShowAbsenceActionModal(true);
    setAbsenceData(absenceItem);
  };

  const handleAbsenceCreateUpdate = (updatedValues) => {
    if (absenceActionType === "add") {
      dispatch(addNewAbsence(updatedValues));
    } else {
      dispatch(updateExistingAbsence(updatedValues));
    }
  };

  const onRefreshAbsences = () => {
    dispatch(fetchAbsences(true));
  };

  useEffect(() => {
    dispatch(fetchAbsences());
  }, []);

  return (
    <div className="font-semibold text-sm">
      <PageTitle Icon={AbsenceIcon} text="Manage absence" />
      <div className="flex ml-[34px] mt-7 mb-4 gap-[10px]">
        <button
          className={clsx(
            "flex items-center",
            "border border-gray500",
            "w-[280px] px-[25px] py-3 bg-neutral rounded-full",
            "shadow-theme",
          )}
          disabled={absencesRefreshing}
          onClick={onRefreshAbsences}
        >
          <RefreshIcon
            className={clsx(absencesRefreshing && "animate-spin")}
            fill="transparent"
          />
          <span className="flex-grow">Refresh</span>
        </button>
        <button
          className={clsx(
            "flex items-center",
            "border border-gray500",
            "w-[280px] px-[25px] py-3 bg-neutral rounded-full",
            "shadow-theme",
          )}
          onClick={onAddAbsence}
        >
          <PlusIcon />
          <span className="flex-grow">Add absence</span>
        </button>
      </div>
      <div
        className={clsx(
          "border border-gray500 rounded-[10px] bg-neutral shadow-theme overflow-scroll",
          "p-3 mx-[34px] mb-[38px]",
          "max-h-[calc(100vh-340px)]",
        )}
      >
        <table className="w-full border-separate border-spacing-y-[13px] whitespace-nowrap">
          <thead className="text-gray500 text-left">
            <tr>
              <th className="w-[90px] pl-5">Time Period</th>
              <th className="block w-fit min-w-[130px]">Absence</th>
              <th className="min-w-[150px]">Hours</th>
              <th></th>
              <th className="w-[80px] text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="font-normal">
            {absencesLoading && (
              <tr>
                <td className="text-center py-2" colSpan="5">
                  <Spinner className="!w-6 !h-6" />
                </td>
              </tr>
            )}
            {absencesList.map((absenceItem, index) => (
              <tr
                key={index}
                className="bg-info100"
                onClick={(e) => onAbsenceAction(absenceItem, "view", e)}
              >
                <td className="py-1 w-[90px] pl-5 pr-10">
                  {absenceItem.dateDisplay}
                </td>
                <td
                  className={clsx(
                    "py-1 w-fit mr-[66px] overflow-hidden",
                    "pr-10 min-w-[130px] max-w-[400px] xl:max-w-[500px] 5xl:max-w-[600px]",
                    "text-ellipsis",
                  )}
                >
                  {absenceItem.motivation}
                </td>
                <td className="py-1 min-w-[150px]">
                  {absenceItem.isAllDay ? "All Day" : absenceItem.timeDisplay}
                </td>
                <td className="w-full"></td>
                <td className="py-1 pr-4 w-[80px]">
                  <div className="flex gap-3 items-center justify-center">
                    <button
                      onClick={(e) => onAbsenceAction(absenceItem, "edit", e)}
                      id={`editAbsenceBtn${absenceItem.id}`}
                    >
                      <WrenchIcon fill="transparent" />
                    </button>
                    <button
                      onClick={(e) =>
                        onToggleAbsenceDeleteModal(e, absenceItem)
                      }
                      id={`deleteAbsenceBtn${absenceItem.id}`}
                    >
                      <CrossCircledIcon fill="transparent" />
                    </button>
                    <Tooltip
                      anchorSelect={`#editAbsenceBtn${absenceItem.id}`}
                      content="Edit absence"
                      offset={4}
                      className="!py-1 !px-2 !rounded-md !text-[10px] text-center"
                      noArrow={true}
                    />
                    <Tooltip
                      anchorSelect={`#deleteAbsenceBtn${absenceItem.id}`}
                      content="Delete absence"
                      offset={4}
                      className="!py-1 !px-2 !rounded-md !text-[10px] text-center"
                      noArrow={true}
                    />
                  </div>
                </td>
              </tr>
            ))}
            {!absencesLoading && absencesList.length === 0 && (
              <tr className="bg-info100 h-[44px]">
                <td className="text-center py-2" colSpan="5">
                  <p className="flex items-center justify-center gap-2 ml-12">
                    <EmptyListIcon className="w-6 h-6" />
                    No absences registered
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {showAbsenceDeleteModal && (
        <Modal
          isOpen={showAbsenceDeleteModal}
          onClose={onToggleAbsenceDeleteModal}
          preventBackdropClose={absenceActionLoading === "delete"}
          preventAnimate={true}
          hideHeader={true}
        >
          <DeleteAbsenceModal
            absenceId={absenceData?.id}
            onConfirm={onDelete}
            actionLoading={absenceActionLoading === "delete"}
            actionError={absenceActionErrorMessage}
            actionSucces={absenceActionSuccessMessage}
            onCancel={onToggleAbsenceDeleteModal}
          />
        </Modal>
      )}
      {showAbsenceActionModal && (
        <Modal
          isOpen={showAbsenceActionModal}
          onClose={onToggleAbsenceModal}
          preventAnimate={true}
          preventBackdropClose={
            absenceActionLoading && absenceActionLoading !== "delete"
          }
          hideHeader={true}
        >
          <ManageAbsenceModal
            absenceData={absenceData}
            actionType={absenceActionType}
            actionLoading={absenceActionLoading}
            actionError={absenceActionErrorMessage}
            actionSuccess={absenceActionSuccessMessage}
            onConfirm={handleAbsenceCreateUpdate}
            onCancel={onToggleAbsenceModal}
            onSwitchActionType={onSwitchActionType}
          />
        </Modal>
      )}
    </div>
  );
};
