import clsx from "clsx";
import { useSelector } from "react-redux";
import { open } from "@tauri-apps/api/shell";
// import { invoke } from '@tauri-apps/api/tauri';
import { selectServerUrl } from "../../store/configs";
// import { selectUserAuthData } from '../../store/user';

import { ReactComponent as TickIcon } from "../../assets/icons/Tick.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/Arrow.svg";

export const RedirectionConfirmationModal = ({
  title,
  description,
  onClose,
}) => {
  const serverUrl = useSelector(selectServerUrl);
  // const { authToken } = useSelector(selectUserAuthData);

  const handleConfirmation = () => {
    if (window.__TAURI__ || window.__TAURI_METADATA__) {
      open(`${serverUrl}/Integrations?master=0`);
      // invoke('open_integrations_window', {
      // 	url: `${serverUrl}/Integrations?master=0`,
      // 	token: `Bearer ${authToken}`,
      // });
    } else {
      window.open(`${serverUrl}/Integrations?master=0`, "_blank");
    }
    onClose();
  };

  return (
    <div className="w-[560px] font-semibold relative">
      <div className="border-b border-gray500 pt-4 pl-6 pb-3">
        <h3 className="text-2xl text-gray500">{title}</h3>
      </div>
      <div className="text-center p-8">
        <h3 className="text-2xl mt-9 mb-5">{description}</h3>
        <ArrowIcon className="w-16 h-16 mx-auto" fill="transparent" />
        <div className="flex items-center justify-center gap-9 mt-6">
          <button
            className={clsx(
              "flex items-center",
              "border border-gray500",
              "min-w-[140px] px-4 py-3 bg-info100 rounded-full",
              "shadow-theme",
            )}
            onClick={handleConfirmation}
          >
            <TickIcon fill="transparent" />
            <span className="flex-grow text-center px-6">Confirm</span>
          </button>
          <button className="underline text-base leading-8" onClick={onClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
