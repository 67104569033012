import clsx from "clsx";

export const MeetingDayGroupRow = ({ dateDisplay, onClick }) => {
  return (
    <tr className="h-[44px]">
      <td colSpan="7">
        <p
          className={clsx(
            "font-bold text-base -ml-6 py-2",
            "cursor-pointer hover:bg-secondary select-none",
          )}
          onClick={onClick}
        >
          {dateDisplay}
        </p>
      </td>
    </tr>
  );
};
