import * as Yup from "yup";
import {
  convertToMinutes,
  hasMinDifferenceBetweenTimes,
} from "../../../utils/dateTime";
import {
  convertNumberToString,
  extractAndParseValue,
  parseWorkingCalendarTimes,
  parseWorkingTimeSlices,
} from "../../../utils/dataParser";

export const passwordUpdateFields = {
  oldPassword: "",
  newPassword: "",
  confirmNewPassword: "",
};

export const workingWeekDayFields = {
  daysOfWeek: [],
  startTime: "",
  endTime: "",
};

export const preferencesTimeSlicFields = {
  daysOfWeek: [],
  workingTimes: [],
};

export const getInitialProfileDataFields = (userData = {}) => {
  return {
    userFirstName: extractAndParseValue(userData, "userFirstName", ""),
    userLastName: extractAndParseValue(userData, "userLastName", ""),
    userName: extractAndParseValue(userData, "userName", ""),
    timeZone: extractAndParseValue(userData, "timeZone", ""),
    phoneNumber: extractAndParseValue(userData, "phoneNumber", ""),
  };
};

export const getInitialNotificationsSettings = (
  enabledNotifications,
  notificationsTypes,
) => {
  return notificationsTypes
    .filter((notifType) => {
      // If the list is empty, it means allowed by default
      if (!enabledNotifications) {
        return true;
      }

      return enabledNotifications.some(
        (notifId) => parseInt(notifId) === parseInt(notifType.id),
      );
    })
    .map((item) => item.id.toString());
};

export const getMeetingPreferencesInitial = (savedPreferences) => {
  if (!savedPreferences) {
    return {
      calendarWeekDays: [workingWeekDayFields],
      overTimeFlexibilityBeforeStart: "",
      overTimeFlexibilityAfterEnd: "",
      meetingBlockPreference: "",
      requiredBreakIfMaxReached: "",
      preferentialMeetingWindows: [preferencesTimeSlicFields],
      blockedMeetingWindows: [preferencesTimeSlicFields],
    };
  }

  const preferencesSchema = {
    calendarWeekDays: extractAndParseValue(
      savedPreferences,
      "workingCalendar.calendarWeekDays",
      [workingWeekDayFields],
      parseWorkingCalendarTimes,
    ),
    overTimeFlexibilityBeforeStart: extractAndParseValue(
      savedPreferences,
      "overTimeFlexibilityBeforeStart",
      "",
      convertNumberToString,
    ),
    overTimeFlexibilityAfterEnd: extractAndParseValue(
      savedPreferences,
      "overTimeFlexibilityAfterEnd",
      "",
      convertNumberToString,
    ),
    meetingBlockPreference: extractAndParseValue(
      savedPreferences,
      "meetingBlockPreference",
      "",
    ),
    requiredBreakIfMaxReached: extractAndParseValue(
      savedPreferences,
      "requiredBreakIfMaxReached",
      "",
    ),
    preferentialMeetingWindows: extractAndParseValue(
      savedPreferences,
      "preferenceForMeetingPeriodsCalendar.calendarWeekDays",
      [preferencesTimeSlicFields],
      parseWorkingTimeSlices,
    ),
    blockedMeetingWindows: extractAndParseValue(
      savedPreferences,
      "blockedMeetingsCalendar.calendarWeekDays",
      [preferencesTimeSlicFields],
      parseWorkingTimeSlices,
    ),
  };

  if (!preferencesSchema.calendarWeekDays.length) {
    preferencesSchema.calendarWeekDays = [workingWeekDayFields];
  }
  if (!preferencesSchema.preferentialMeetingWindows.length) {
    preferencesSchema.preferentialMeetingWindows = [preferencesTimeSlicFields];
  }
  if (!preferencesSchema.blockedMeetingWindows.length) {
    preferencesSchema.blockedMeetingWindows = [preferencesTimeSlicFields];
  }

  return preferencesSchema;
};

export const profileDataFormValidations = Yup.object().shape({
  userFirstName: Yup.string()
    .min(2, "First name should be at least 2 characters")
    .required("This field is mandatory."),
  userLastName: Yup.string()
    .min(2, "Last name should be at least 2 characters")
    .required("This field is mandatory."),
  userName: Yup.string()
    .email("Please provide a valid email.")
    .required("This field is mandatory."),
  timeZone: Yup.string().required("This field is mandatory."),
  phoneNumber: Yup.string()
    .matches(
      /^\+?[0-9]+$/,
      "Phone number must only contain digits and an optional plus sign at the beginning",
    )
    .min(10, "Phone number must be at least 10 digits long")
    .max(15, "Phone number can be at most 15 digits long"),
});

export const passwordUpdateFormValidations = Yup.object().shape({
  oldPassword: Yup.string().required("This field is mandatory."),
  newPassword: Yup.string()
    .required("This field is mandatory.")
    .min(8, "Password must be at least 8 characters long")
    .matches(/[A-Z]/, "Passwords must have at least one uppercase letter.")
    .matches(
      /[^a-zA-Z0-9]/,
      "Passwords must have at least one non-alphanumeric character",
    ),
  confirmNewPassword: Yup.string()
    .oneOf(
      [Yup.ref("newPassword"), null],
      "Password does not match with the new password.",
    )
    .required("This field is mandatory.obligatoriu"),
});

const getBlockedTimewindowWithOverlappedTimeSlices = (
  blockedMeetingWindows,
  preferredMeetingWindows,
) => {
  const blockedTimeRanges = [];
  for (const groupedWeedDays of blockedMeetingWindows) {
    const workingTimesInMinutes = groupedWeedDays.workingTimes.map(
      ({ start, end }) => ({
        start: convertToMinutes(start),
        end: convertToMinutes(end),
      }),
    );
    blockedTimeRanges.push({
      days: groupedWeedDays.daysOfWeek,
      workingTimes: workingTimesInMinutes,
    });
  }

  const preferredTimeRanges = [];
  for (const groupedWeedDays of preferredMeetingWindows) {
    const workingTimesInMinutes = groupedWeedDays.workingTimes.map(
      ({ start, end }) => ({
        start: convertToMinutes(start),
        end: convertToMinutes(end),
      }),
    );
    preferredTimeRanges.push({
      days: groupedWeedDays.daysOfWeek,
      workingTimes: workingTimesInMinutes,
    });
  }

  let overlappingBlock = -1;

  // Check for overlapping time ranges
  for (let index = 0; index < blockedTimeRanges.length; index++) {
    const blockedRange = blockedTimeRanges[index];
    for (const preferredRange of preferredTimeRanges) {
      // If preferred block doesn't included any day in this timewindow,
      // Then skip and check next preferredTimewindow
      const hasSameDay = blockedRange.days.some((day) =>
        preferredRange.days.includes(day),
      );
      if (!hasSameDay) {
        continue;
      }

      for (const blockedTimeSlice of blockedRange.workingTimes) {
        for (const preferredTimeSlice of preferredRange.workingTimes) {
          // If timeSlice starts after the preferredBlock slice, then it also ends after it,
          // So no chance to overlap as start is always greater than end
          if (blockedTimeSlice.start >= preferredTimeSlice.end) {
            continue;
          }

          // IF the timeslice start or end goes after the preferred slice, then it overlaps
          if (
            blockedTimeSlice.start > preferredTimeSlice.start ||
            blockedTimeSlice.end > preferredTimeSlice.start
          ) {
            overlappingBlock = index;
            break;
          }
        }

        // If there is already any blocked overlapped, no need to further check.
        if (overlappingBlock !== -1) {
          break;
        }
      }
    }
  }

  return overlappingBlock;
};

export const meetingPreferencesValidations = Yup.object()
  .shape({
    calendarWeekDays: Yup.array().of(
      Yup.object().shape({
        daysOfWeek: Yup.array()
          .of(Yup.string().required("Please select working days"))
          .min(1, "Select at least one day of week"),
        startTime: Yup.string().required("Start time is required"),
        endTime: Yup.string()
          .required("End time is required")
          .test(
            "is-after-start",
            "End time must be at least 1 hour after start time.",
            function (value) {
              const { startTime } = this.parent;
              return hasMinDifferenceBetweenTimes(startTime, value);
            },
          ),
      }),
    ),
    meetingBlockPreference: Yup.string().required(
      "Please provide preferred metting length.",
    ),
    overTimeFlexibilityAfterEnd: Yup.string().required(
      "Please provide overtime flexibility before start of day",
    ),
    overTimeFlexibilityBeforeStart: Yup.string().required(
      "Please provide overtime flexibility before start of day.",
    ),
    requiredBreakIfMaxReached: Yup.string().required(
      "Please provide preffered break length.",
    ),
    preferentialMeetingWindows: Yup.array(
      Yup.object().shape({
        daysOfWeek: Yup.array()
          .of(Yup.string().required("Please select working days"))
          .min(1, "Select at least one day of week"),
        workingTimes: Yup.array().min(
          1,
          "Select at-least 1 preferred time block.",
        ),
      }),
    ),
    blockedMeetingWindows: Yup.array(
      Yup.object().shape({
        daysOfWeek: Yup.array()
          .of(Yup.string().required("Please select working days"))
          .min(1, "Select at least one day of week"),
        workingTimes: Yup.array().min(1, "Select at-least 1 blocked time."),
      }),
    ),
  })
  .test(
    "no-overlaping-timewindow",
    "Blocked time should not overlap preferred time",
    function (value) {
      const overlappingIndex = getBlockedTimewindowWithOverlappedTimeSlices(
        value.blockedMeetingWindows,
        value.preferentialMeetingWindows,
      );

      if (overlappingIndex !== -1) {
        return this.createError({
          path: `blockedMeetingWindows[${overlappingIndex}].workingTimes`,
          message: "Blocked time should not overlap preferred time",
        });
      }
    },
  );
