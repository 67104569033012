import { useDispatch, useSelector } from "react-redux";
import { authenticateUser, selectUserAuthData } from "../../store/user";
import { Navigate, useLocation } from "react-router-dom";
import { AuthLoader } from "./AuthLoader";
import { useEffect } from "react";
import { useState } from "react";

export const ProtectedRouter = ({ children }) => {
  const { currentUser, isAuthenticating } = useSelector(selectUserAuthData);
  const [isAuth, setIsAuth] = useState(false);

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(authenticateUser());
  }, [pathname]);

  if (!isAuthenticating && !currentUser) {
    return <Navigate to="/auth/login" />;
  }

  if (isAuthenticating && !isAuth) {
    setIsAuth(true);
    return <AuthLoader />;
  }

  if (currentUser) {
    return children;
  }

};
