import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import dayjs from "dayjs";
import * as signalR from "@microsoft/signalr";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import {
  fetchAllCalendarPlatforms,
  fetchAllMeetingPlatforms,
  fetchAllNotificationsTypes,
  fetchTimezones,
  selectServerUrl,
} from "../../store/configs";
import {
  fetchMyMeetingPreferences,
  fetchMyProfilePhoto,
  selectUserAuthData,
} from "../../store/user";
import { fetchLatestNotification } from "../../store/notifications";
import { MESSAGING_HUB_URL } from "../../utils/apiRoutes";

export const AdminContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const serverUrl = useSelector(selectServerUrl);
  const { authToken, currentUser } = useSelector(selectUserAuthData);
  const [timezoneMismatchError, setTimezoneMismatchError] = useState("");

  const onGoToProfileSettings = () => {
    navigate("/admin/settings?section=profile");
  };

  const onHideTimezoneError = () => {
    setTimezoneMismatchError("");
  };

  useEffect(() => {
    dispatch(fetchTimezones());
    dispatch(fetchMyProfilePhoto());
    dispatch(fetchAllMeetingPlatforms());
    dispatch(fetchAllCalendarPlatforms());
    dispatch(fetchAllNotificationsTypes());
    dispatch(fetchMyMeetingPreferences());
  }, []);

  useEffect(() => {
    if (authToken) {
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${serverUrl}/${MESSAGING_HUB_URL}`, {
          withCredentials: false,
          accessTokenFactory: () => authToken,
        })
        .build();

      hubConnection
        .start()
        .then((_) => {
          console.log("Notif Listening Started");
        })
        .catch((err) => {
          console.log("Notif Listening ERRROR", err);
        });

      hubConnection.on("ReceiveMessage", () => {
        dispatch(fetchLatestNotification());
      });

      return () => {
        hubConnection
          .stop()
          .then((_) => {
            console.log("Notif Listening Stopped:");
          })
          .catch();
      };
    }
  }, [authToken]);

  useEffect(() => {
    const systemTimezone = dayjs.tz.guess();
    if (!currentUser?.timeZone) {
      setTimezoneMismatchError("Please set the timezone in profile settings.");
    } else if (systemTimezone !== currentUser.timeZone) {
      setTimezoneMismatchError(
        "Your selected timezone is different from the system timezone.",
      );
    }
  }, [currentUser?.timeZone]);

  return (
    <div className="h-screen flex">
      <Sidebar />
      <div className="w-full pt-8 overflow-y-scroll">
        {timezoneMismatchError && (
          <div
            className={clsx(
              "flex items-center gap-x-8 bg-danger100 py-3 px-8 -mt-8",
              "text-danger font-semibold text-sm",
            )}
          >
            <p className="flex-grow text-center">{timezoneMismatchError}</p>
            <div className="flex gap-2">
              <button
                className="border rounded-full bg-[white] px-6 py-1 shadow-theme"
                onClick={onGoToProfileSettings}
              >
                Update
              </button>
              <button
                className="border rounded-full px-4 py-1 shadow-theme"
                onClick={onHideTimezoneError}
              >
                Remind me later
              </button>
            </div>
          </div>
        )}
        <Header />
        <div className="">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
