import { useEffect, useMemo } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SidebarLink } from "./SidebarLink";
import { logout } from "../../store/user";
import {
  selectSidebarCollapsed,
  selectSidebarCollapsible,
  setSidebarCollapsible,
} from "../../store/configs";
import packageJson from "../../../package.json";

import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboard.svg";
import { ReactComponent as ManageIcon } from "../../assets/icons/Manage.svg";
import { ReactComponent as RequestIcon } from "../../assets/icons/Request.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/Calendar.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/Settings.svg";
import { ReactComponent as SupportIcon } from "../../assets/icons/support.svg";
import { ReactComponent as AbsenceIcon } from "../../assets/icons/Absence.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import logo from "../../assets/images/logo.png";
import logoIcon from "../../assets/images/logoIcon.png";

export const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarCollapsible = useSelector(selectSidebarCollapsible);
  const sidebarCollapsed = useSelector(selectSidebarCollapsed);

  const activeVersionLabel = useMemo(() => {
    if (window.__TAURI__ || window.__TAURI_METADATA__) {
      return `Version ${packageJson.version}`;
    }
    return null;
  }, []);

  const onLogout = () => {
    dispatch(logout());
    navigate("/auth/login");
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1440) {
        !sidebarCollapsible && dispatch(setSidebarCollapsible(true));
      } else {
        sidebarCollapsible && dispatch(setSidebarCollapsible(false));
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sidebarCollapsible]);

  return (
    <div className="pr-[1px] bg-[linear-gradient(180deg,_#0A9DDB_0%,_#7FC9E8_25.52%,_#FFEBEF_32.29%,_#FF3561_44.27%,_#FF0037_100%)] relative">
      <div
        className={clsx(
          "flex flex-col shrink-0 justify-between",
          "bg-neutral shadow-sidebar h-screen overflow-y-scroll",
          sidebarCollapsed ? "w-[80px]" : "w-[294px]",
          "transition-width duration-300",
        )}
      >
        <div className={clsx(sidebarCollapsed ? "py-4 tall:py-8 px-4" : "p-8")}>
          <div className="mb-[54px]">
            {sidebarCollapsed ? (
              <img
                src={logoIcon}
                className="w-10 tall:w-12 h-auto mt-4 mx-auto"
                alt="Zelvor Logo"
              />
            ) : (
              <img
                src={logo}
                className="w-[183px] mt-3 mx-auto"
                alt="Zelvor Logo"
              />
            )}
          </div>
          <p
            className={clsx(
              "text-xs font-semibold mb-4 opacity-50",
              sidebarCollapsed ? "ml-0 text-center" : "ml-5",
            )}
          >
            Main menu
          </p>
          <SidebarLink
            title="Dashboard"
            link="dashboard"
            Icon={DashboardIcon}
            collapsedView={sidebarCollapsed}
          />
          <SidebarLink
            title="Manage meetings"
            link="manage-meetings"
            Icon={ManageIcon}
            collapsedView={sidebarCollapsed}
          />
          <SidebarLink
            title="Meeting requests"
            link="meeting-requests"
            Icon={RequestIcon}
            collapsedView={sidebarCollapsed}
          />
          <SidebarLink
            title="Calendar"
            link="calendar"
            Icon={CalendarIcon}
            collapsedView={sidebarCollapsed}
          />
        </div>
        <div
          className={clsx(
            "border-t border-gray300",
            sidebarCollapsed ? "py-4 tall:py-8 px-4" : "p-8",
          )}
        >
          <p
            className={clsx(
              "text-xs font-semibold mb-4 opacity-50",
              sidebarCollapsed ? "ml-0 text-center" : "ml-5",
            )}
          >
            Others
          </p>
          <SidebarLink
            title="Settings"
            link="settings"
            Icon={SettingsIcon}
            collapsedView={sidebarCollapsed}
          />
          <SidebarLink
            title="Support center"
            link="support-center"
            Icon={SupportIcon}
            collapsedView={sidebarCollapsed}
          />
          <SidebarLink
            title="Manage absences"
            link="manage-absences"
            Icon={AbsenceIcon}
            collapsedView={sidebarCollapsed}
          />
          {sidebarCollapsed ? (
            <button
              className={clsx(
                "bg-primary flex items-center justify-center",
                "w-10 h-10 tall:w-12 tall:h-12",
                "rounded-[8px] tall:rounded-[10px]",
                "mx-auto tall:mx-0",
              )}
              onClick={onLogout}
            >
              <LogoutIcon className="w-6 h-6 text-neutral" fill="transparent" />
            </button>
          ) : (
            <button
              className={clsx(
                "bg-primary flex w-full justify-center",
                "px-5 py-3 rounded-[25px] mt-6 mx-auto",
                "text-neutral font-semibold text-base",
                "whitespace-nowrap",
              )}
              onClick={onLogout}
            >
              Log out
            </button>
          )}
          {activeVersionLabel && (
            <p className="text-sm font-semibold text-center mt-4">
              {activeVersionLabel}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
