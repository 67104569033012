import { useState } from "react";
import { SettingsCard } from "./SettingsCard";
import { ReactComponent as IntegrationsIcon } from "../../assets/icons/integrations.svg";
import { Modal } from "../layout/Modal";
import { RedirectionConfirmationModal } from "../modals/RedirectionConfirmationModal";
import { useLinkedMeetingApps } from "../../hooks/integrations";

import { ReactComponent as IconTick } from "../../assets/icons/Tick.svg";

const logosByPlatform = {
  None: null,
  Zoom: "/apps/zoom_logo.svg",
  "Google Meet": "/apps/meet_logo.png",
  Skype: "/apps/skype_logo.svg",
  "Microsoft Teams": "/apps/msteam_logo.png",
};

export const SettingsIntegrations = ({ listRef }) => {
  const [showRedirectionAlert, setShowRedirectionAlert] = useState(false);
  const userLinkedMeetingApps = useLinkedMeetingApps();

  const onToggleRedirectionAlert = () => {
    setShowRedirectionAlert(!showRedirectionAlert);
  };

  return (
    <SettingsCard
      title="Integrations"
      description="Connect Zelvor to your favourite apps"
      Icon={IntegrationsIcon}
      sectionKey="integrations"
      listRef={listRef}
    >
      <div className="ml-[302px]">
        <h5 className="text-sm font-semibold mb-7">Integrate with:</h5>
        <div className="flex items-center gap-4 flex-wrap">
          {userLinkedMeetingApps.map((platform, index) => (
            <div key={index} className="flex gap-1 items-center">
              <img
                className="max-h-[25px] h-full w-auto"
                src={logosByPlatform[platform.title]}
                alt={platform.title}
              />
              <button
                className="text-sm font-semibold"
                onClick={onToggleRedirectionAlert}
              >
                {platform.isConnected ? (
                  <IconTick
                    className="text-success w-5 h-5 ml-1"
                    fill="transparent"
                  />
                ) : (
                  <span className="underline">Connect</span>
                )}
              </button>
            </div>
          ))}
        </div>
      </div>
      {showRedirectionAlert && (
        <Modal
          isOpen={showRedirectionAlert}
          onClose={onToggleRedirectionAlert}
          preventAnimate={true}
          hideHeader={true}
          modalClassName="border border-gray500 shadow-theme p-0"
        >
          <RedirectionConfirmationModal
            title="Integrations"
            description={"You'll be redirected outside of Zelvor"}
            onClose={onToggleRedirectionAlert}
          />
        </Modal>
      )}
    </SettingsCard>
  );
};
