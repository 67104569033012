import clsx from "clsx";
import { CalendarEventChip } from "./CalendarEventChip";
import { CalendarEventChipLarge } from "./CalendarEventChipLarge";

export const CalendarWeeklyDay = ({
  isToday,
  dateValue,
  disabled,
  isWorkingDay,
  hasMeeting,
  isFullWeek,
  scheduledEvents,
  workingHours,
  onViewEventDetail,
}) => {
  const isOverlapping = (eventToCheck, allEvents) => {
    const startToCheck = new Date(eventToCheck.start).getTime();
    const endToCheck = new Date(eventToCheck.end).getTime();

    for (const event of allEvents) {
      if (event.entityID !== eventToCheck.entityID) {
        const start = new Date(event.start).getTime();
        const end = new Date(event.end).getTime();

        if (startToCheck < end && endToCheck > start) {
          return true;
        }
      }
    }
    return false;
  };

  const getOverlappingEvents = (eventToCheck, allEvents) => {
    const startToCheck = new Date(eventToCheck.start).getTime();
    const endToCheck = new Date(eventToCheck.end).getTime();
    const overlappingEvents = [];

    for (const event of allEvents) {
      if (event.entityID !== eventToCheck.entityID) {
        const start = new Date(event.start).getTime();
        const end = new Date(event.end).getTime();

        if (startToCheck <= end && endToCheck >= start) {
          overlappingEvents.push(event);
          // break;
        }
      }
    }

    return overlappingEvents;
  };

  const calculateNumberOfOverlappingEvents = (eventToCheck, allEvents) => {
    const overlappingEvents = getOverlappingEvents(eventToCheck, allEvents);
    return overlappingEvents.length;
  };

  const eventsWithOverlapInfo = scheduledEvents.map((eventItem) => ({
    ...eventItem,
    overlapping: isOverlapping(eventItem, scheduledEvents),
    numberOfOverlappingEvents: calculateNumberOfOverlappingEvents(
      eventItem,
      scheduledEvents,
    ),
  }));

  const overlappingGroups = [];
  scheduledEvents.forEach((eventItem) => {
    const overlappingGroup = overlappingGroups.find((group) =>
      isOverlapping(eventItem, group),
    );
    if (overlappingGroup) {
      overlappingGroup.push(eventItem);
    } else {
      overlappingGroups.push([eventItem]);
    }
  });

  return (
    <div
      className={clsx(
        "flex border-l border-gray500",
        "[&:first-child]:border-l-0 border-t-[4px]",
        isToday && isFullWeek ? "border-t-info400" : "border-t-[transparent]",
        !isWorkingDay && "bg-info100",
      )}
    >
      <div className={clsx("px-2 w-full")}>
        <p
          className={clsx(
            "text-xl pt-[8px] h-[46px] text-right",
            disabled && isFullWeek && "text-gray500 opacity-50",
          )}
        >
          {isFullWeek
            ? dateValue
            : hasMeeting && `${scheduledEvents.length} Meetings`}
        </p>
        {hasMeeting && (
          <>
            {isFullWeek ? (
              <div className={clsx("relative")}>
                {overlappingGroups.map((group) => {
                  return group?.map((representativeEvent, index) => {
                    return (
                      <CalendarEventChip
                        key={index}
                        meetingId={representativeEvent.entityID}
                        mepaId={representativeEvent?.mepaid}
                        topic={representativeEvent.topic}
                        meetingTime={representativeEvent.eventDurationDisplay}
                        meetingLength={representativeEvent.eventDuration}
                        meetingStart={representativeEvent.startTime}
                        start={representativeEvent.start}
                        nonAdjustable={representativeEvent.nonAdjustable}
                        isProposal={representativeEvent.isProposal}
                        isExternalMeeting={representativeEvent.isExternalMeeting}
                        participants={representativeEvent.participants}
                        remainingCounter={representativeEvent.remainingCounter}
                        dayStartTime={workingHours[0]?.value}
                        onViewEventDetail={onViewEventDetail}
                        overlapping={group.length > 1}
                        numberOfOverlappingEvents={group.length}
                        overlappingGroups={overlappingGroups}
                        index={index}
                      />
                    );
                   });
                })}
              </div>
            ) : (
              <div
                className={clsx(
                  eventsWithOverlapInfo
                    ? "flex flex-wrap "
                    : "flex flex-wrap  w-full h-full",
                )}
              >
                {eventsWithOverlapInfo.map((eventItem, index) => (
                  <CalendarEventChipLarge
                    key={index}
                    meetingId={eventItem.entityID}
                    mepaId={eventItem?.mepaid}
                    topic={eventItem.topic}
                    meetingTime={eventItem.eventDurationDisplay}
                    meetingLength={eventItem.eventDuration}
                    meetingStart={eventItem.startTime}
                    nonAdjustable={eventItem.nonAdjustable}
                    isProposal={eventItem.isProposal}
                    isExternalMeeting={eventItem.isExternalMeeting}
                    meetingPlatform={eventItem.meetingPlatform}
                    participants={eventItem.participants}
                    remainingCounter={eventItem.remainingCounter}
                    dayStartTime={workingHours[0]?.value}
                    onViewEventDetail={onViewEventDetail}
                    overlapping={eventItem.overlapping}
                    numberOfOverlappingEvents={
                      eventItem.numberOfOverlappingEvents
                    }
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
