import { useEffect, useMemo, useRef, useState } from "react";
import { getHoursDifferenceBetweenTimes } from "../../utils/dateTime";

export const ScrollerToTime = ({ dayStartTime, workingDayStartTime }) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const pointerRef = useRef();

  const positionTop = useMemo(() => {
    let hoursFromDayStart = getHoursDifferenceBetweenTimes(
      dayStartTime,
      workingDayStartTime,
    );

    if (hoursFromDayStart < 0) {
      hoursFromDayStart = 0;
    } else if (hoursFromDayStart > 24) {
      hoursFromDayStart = 24;
    }
    const positionTop = hoursFromDayStart * 91;
    return positionTop;
  }, [dayStartTime, workingDayStartTime]);

  useEffect(() => {
    if (pointerRef.current && !hasScrolled && positionTop > 0) {
      pointerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setHasScrolled(true);
    }
  }, [pointerRef.current, positionTop, hasScrolled]);

  return (
    <div
      className="absolute left-0 w-0 h-0"
      style={{ top: positionTop }}
      ref={pointerRef}
    />
  );
};
