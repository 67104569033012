import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../Spinner";
import { Modal } from "../layout/Modal";
import { ParticipantsChip } from "../ParticipantsChip";
import { MeetingProposalModal } from "../modals/MeetingProposalModal";
import { ConfirmationModal } from "../modals/ConfirmationModal";
import {
  fetchMeetingInvitations,
  resetMeetingResponseError,
  resetMeetingResponseStatus,
  responseToMeetingInvitation,
  selectMeetingInvitations,
  selectMeetingResponseStatus,
} from "../../store/meetingRequests";
import { MEETING_INVITATION_RESPONSE } from "../../utils/constants";

import { ReactComponent as ChevronIcon } from "../../assets/icons/Chevron.svg";
import { ReactComponent as TickIcon } from "../../assets/icons/Tick.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/Cross.svg";
import dayjs from "dayjs";

export const DashboardRequestsCard = () => {
  const dispatch = useDispatch();
  const [expandedInvitation, setExpandedInvitation] = useState(null);
  const [invitionAction, setInvitationAction] = useState(null);
  const isSuccessFullResponse = useSelector(state => state?.meetingRequests?.isSuccessFullResponse);

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(fetchMeetingInvitations());
    return () => {
      dispatch(fetchMeetingInvitations());
    };
  }, [pathname, isSuccessFullResponse]);

  const { meetingInvitations, meetingInvitationsLoading } = useSelector(
    selectMeetingInvitations,
  );
  const {
    meetingResponseLoading,
    meetingResponseSuccess,
    meetingResponseError,
  } = useSelector(selectMeetingResponseStatus);

  const meetingRequestsInvitations = useMemo(() => {
    return meetingInvitations.filter((item) => !(item.isHost || item.response));
  }, [meetingInvitations]);

  const onViewProposalDetail = (invitationData) => {
    setExpandedInvitation(invitationData);
  };

  const onHideModal = () => {
    setExpandedInvitation(null);
    dispatch(resetMeetingResponseStatus());
  };

  const onClearResponseActionError = () => {
    dispatch(resetMeetingResponseError());
  };

  const onInvitationAction = (proposal, response) => [
    setInvitationAction({
      meetingProposalID: proposal?.meetingProposalID,
      mepaId: proposal?.mepaId,
      response,
    }),
  ];

  const handleResponse = (invitation, response) => {
    if (invitation.mepaId) {
      dispatch(
        responseToMeetingInvitation({
          mepaId: invitation?.mepaId,
          response: response,
        }),
      );
    } else {
      dispatch(
        responseToMeetingInvitation({
          proposalId: invitation?.meetingProposalID,
          response: response,
        }),
      );
    }
  };

  const onHideInvitionActionModal = () => {
    setInvitationAction(null);
    dispatch(resetMeetingResponseStatus());
  };

  return (
    <div
      className={clsx(
        "bg-neutral px-6 py-7 border border-gray500 rounded-[10px] shadow-theme",
        "w-[calc(25%-16px)]",
      )}
    >
      <div className="flex items-center justify-between pb-[22px]">
        <p className="text-xl 3xl:text-2xl whitespace-nowrap">
          Meeting requests
        </p>
        <Link className="text-gray500" to="../meeting-requests">
          <ChevronIcon fill="transparent" />
        </Link>
      </div>
      <div className="h-[calc(100%-30px)] overflow-y-scroll flex flex-col gap-2">
        {meetingRequestsInvitations.map((invitationItem, index) => (
          <div
            key={index}
            className="border border-gray500 rounded-[5px] bg-secondary p-2"
          >
            <div className="flex items-center justify-between">
              <div className="w-[calc(100%-32px)]">
                <p className="text-base text-ellipsis overflow-hidden whitespace-nowrap">
                  {invitationItem.topic}
                </p>
                <p className="font-normal">
                  {/* {invitationItem.meetingWindow} */}
                  {dayjs(invitationItem.proposalDateTimesToBeHeldStart).format(
                    "DD.MM.YYYY",
                  )}
                  -{" "}
                  {dayjs(invitationItem.proposalDateTimesToBeHeldEnd).format(
                    "DD.MM.YYYY",
                  )}
                </p>
              </div>
              <button
                className="text-primary"
                onClick={() => onViewProposalDetail(invitationItem)}
              >
                <ChevronIcon fill="transparent" />
              </button>
            </div>
            <div className="flex items-center justify-center 3xl:justify-between mt-5">
              <div className="hidden 3xl:flex">
                <ParticipantsChip
                  remainingCounter={invitationItem.remainingCounter}
                  participants={invitationItem.participants}
                  containerClassName="bg-neutral border-neutral"
                />
              </div>
              <div className="">
                <button
                  disabled={
                    invitationItem.response ===
                    MEETING_INVITATION_RESPONSE.ACCEPTED
                  }
                  className={clsx(
                    "px-3 py-1 border rounded-full mr-1",
                    invitationItem.response ===
                      MEETING_INVITATION_RESPONSE.ACCEPTED
                      ? "border-gray300 text-gray500"
                      : "border-success",
                  )}
                  onClick={() =>
                    onInvitationAction(
                      {
                        mepaId: invitationItem?.mepaId,
                        meetingProposalID: invitationItem?.meetingProposalID,
                      },
                      1,
                    )
                  }
                >
                  <TickIcon fill="transparent" />
                </button>
                <button
                  disabled={
                    invitationItem.response ===
                    MEETING_INVITATION_RESPONSE.REJECTED
                  }
                  className={clsx(
                    "px-3 py-1 border rounded-full",
                    invitationItem.response ===
                      MEETING_INVITATION_RESPONSE.REJECTED
                      ? "border-gray300 text-gray500"
                      : "border-danger",
                  )}
                  onClick={() =>
                    onInvitationAction(
                      {
                        mepaId: invitationItem?.mepaId,
                        meetingProposalID: invitationItem?.meetingProposalID,
                      },
                      2,
                    )
                  }
                >
                  <CrossIcon fill="transparent" />
                </button>
              </div>
            </div>
          </div>
        ))}
        {meetingInvitationsLoading && (
          <Spinner className="w-8 h-8 text-center !m-auto" />
        )}
        {!meetingInvitationsLoading &&
          meetingRequestsInvitations.length === 0 && (
            <p className="text-gray500 text-center text-lg my-auto">
                No pending meeting requests.
            </p>
          )}
      </div>
      {!!expandedInvitation && (
        <Modal
          isOpen={!!expandedInvitation}
          onClose={onHideModal}
          preventAnimate={true}
          hideHeader={true}
          modalClassName={clsx(
            "border shadow-theme",
            !expandedInvitation?.response && "border-gray500",
            expandedInvitation?.response ===
              MEETING_INVITATION_RESPONSE.REJECTED && "border-danger400",
            expandedInvitation?.response ===
              MEETING_INVITATION_RESPONSE.ACCEPTED && "border-success100",
          )}
          preventBackdropClose={meetingResponseLoading}
        >
          <MeetingProposalModal
            proposalData={expandedInvitation}
            actionLoading={meetingResponseLoading}
            actionSuccess={meetingResponseSuccess}
            actionError={meetingResponseError}
            onAccept={() => handleResponse(expandedInvitation, 1)}
            onDecline={() => handleResponse(expandedInvitation, 2)}
            onClearError={onClearResponseActionError}
            onClose={onHideModal}
          />
        </Modal>
      )}
      {!!invitionAction && (
        <Modal
          isOpen={!!invitionAction}
          onClose={onHideInvitionActionModal}
          preventAnimate={true}
          hideHeader={true}
          modalClassName={clsx("border border-gray500 shadow-theme")}
          preventBackdropClose={meetingResponseLoading}
        >
          <ConfirmationModal
            description={`Are you sure you want to ${
              invitionAction.response === MEETING_INVITATION_RESPONSE.ACCEPTED
                ? "accept"
                : "decline"
            }?`}
            confirmButtonTitle="Confirm"
            modalTitle="Response to invitation failed."
            actionLoading={meetingResponseLoading}
            actionSuccess={meetingResponseSuccess}
            actionError={meetingResponseError}
            onConfirm={() =>
              handleResponse(invitionAction, invitionAction.response)
            }
            onCancel={onHideInvitionActionModal}
            onClearError={onClearResponseActionError}
          />
        </Modal>
      )}
    </div>
  );
};
