import clsx from "clsx";
import { ReactComponent as TickIcon } from "../../assets/icons/Tick.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/Cross.svg";
import { ReactComponent as QuestionIcon } from "../../assets/icons/help.svg";

const MeetingParticipant = ({
  firstName,
  lastName,
  hasApproved,
  hasRejected,
}) => {
  return (
    <p
      className={clsx(
        "relative border border-gray500 rounded-[10px]",
        "px-3 py-1 leading-none whitespace-nowrap",
      )}
    >
      {
        // (hasRejected || hasApproved) && (
        <span
          className={clsx(
            "absolute text-[white] rounded-full -right-1.5 -top-2",
            hasRejected ? "bg-[red]" : "bg-success",
          )}
        >
          {hasRejected ? (
            <CrossIcon fill="transparent" className="h-4 w-4" />
          ) : hasApproved ? (
            <TickIcon fill="transparent" className="h-4 w-4" />
          ) : (
            <QuestionIcon fill="transparent" className="h-4 w-4" />
          )}
        </span>
        // )
      }
      {firstName} {lastName}
    </p>
  );
};

export default MeetingParticipant;
