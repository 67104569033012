import clsx from "clsx";
import { NavLink } from "react-router-dom";

export const SidebarLink = ({ title, Icon, link, collapsedView }) => {
  return (
    <NavLink
      to={`./${link}`}
      className={({ isActive }) =>
        clsx(
          "flex my-2 text-base font-semibold items-center",
          collapsedView
            ? "w-10 h-10 mx-auto rounded-[8px] tall:w-12 tall:h-12 tall:mx-none tall:rounded-[10px] justify-center"
            : "px-5 py-3 rounded-[25px]",
          "whitespace-nowrap",
          {
            "bg-primary text-neutral": isActive,
            "hover:bg-gray300": !isActive,
          },
        )
      }
    >
      {Icon && <Icon className={clsx("w-6 h-6 fill-[transparent]")} />}
      {!collapsedView && <span className="ml-1">{title}</span>}
    </NavLink>
  );
};
