import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axiosClient from "../utils/axiosClient";
import { ABSENCES_MODULE_API, GET_MY_ABSENCES_API } from "../utils/apiRoutes";
import { parseAbsencesData } from "../utils/dateTime";

const initialState = {
  absencesList: [],
  absencesLoading: false,
  absencesRefreshing: false,
  addAbsenceLoading: false,
  absenceActionLoading: "",
  absenceActionErrorMessage: "",
  absenceActionSuccessMessage: "",
};

export const fetchAbsences = createAsyncThunk(
  "absences/fetchAbsences",
  async (isRefreshing, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { absencesList } = getState().absences;
    if (absencesList.length > 0 && !isRefreshing) {
      return absencesList;
    }
    if (isRefreshing) {
      dispatch(setAbsencesRefreshing());
    }
    const { data: result } = await axiosClient.get(
      `${serverUrl}/${GET_MY_ABSENCES_API}`,
    );
    return parseAbsencesData(result);
  },
);

export const addNewAbsence = createAsyncThunk(
  "absences/addNewAbsence",
  async (data, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    let apiUrl = `${serverUrl}/${ABSENCES_MODULE_API}`;
    const dataToPost = {
      fromDate: data.fromDate,
      toDate: data.toDate,
      motivation: data.motivation,
    };
    if (data.isAllDay) {
      dataToPost.absenceTimeString = "00:00T23:59";
    } else {
      dataToPost.absenceTimeString = [data.fromTime, data.toTime].join("T");
    }
    await axiosClient.post(apiUrl, dataToPost);
    // Trigger the listing refresh..
    dispatch(fetchAbsences(true));
    return;
  },
);

export const updateExistingAbsence = createAsyncThunk(
  "absences/updateExistingAbsence",
  async (data, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    let apiUrl = `${serverUrl}/${ABSENCES_MODULE_API}`;
    const dataToPost = {
      id: data.id,
      fromDate: data.fromDate,
      toDate: data.toDate,
      motivation: data.motivation,
    };
    if (data.isAllDay) {
      dataToPost.absenceTimeString = "00:00T23:59";
    } else {
      dataToPost.absenceTimeString = [data.fromTime, data.toTime].join("T");
    }
    await axiosClient.put(apiUrl, dataToPost);
    // Trigger the listing refresh..
    dispatch(fetchAbsences(true));
    return;
  },
);

export const deleteAbsence = createAsyncThunk(
  "absences/deleteAbsence",
  async (absenceId, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { data: result } = await axiosClient.delete(
      `${serverUrl}/${ABSENCES_MODULE_API}/${absenceId}`,
    );
    dispatch(fetchAbsences(true));
    return result;
  },
);

const slice = createSlice({
  name: "absences",
  initialState,
  reducers: {
    setAbsencesRefreshing: (state) => {
      state.absencesRefreshing = true;
    },
    resetAbsencesActionData: (state) => {
      state.absenceActionLoading = "";
      state.absenceActionSuccessMessage = "";
      state.absenceActionErrorMessage = "";
    },
    setAbsencesList: (state, { payload }) => {
      state.absencesList = payload;
    },
    resetAbsencesState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAbsences.pending, (state) => {
      state.absencesLoading = true;
    });
    builder.addCase(fetchAbsences.fulfilled, (state, { payload }) => {
      state.absencesLoading = false;
      state.absencesRefreshing = false;
      state.absencesList = payload;
    });
    builder.addCase(fetchAbsences.rejected, (state) => {
      state.absencesLoading = false;
      state.absencesRefreshing = false;
    });
    builder.addCase(deleteAbsence.pending, (state) => {
      state.absenceActionLoading = "delete";
      state.absenceActionSuccessMessage = "";
      state.absenceActionErrorMessage = "";
    });
    builder.addCase(deleteAbsence.fulfilled, (state) => {
      state.absenceActionLoading = "";
      state.absenceActionSuccessMessage =
        "Absence has been deleted successfully.";
      state.absenceActionErrorMessage = "";
    });
    builder.addCase(deleteAbsence.rejected, (state, { error }) => {
      console.log("ABSENCE DELETION ERROR::", error.response);
      state.absenceActionLoading = "";
      state.absenceActionSuccessMessage = "";
      state.absenceActionErrorMessage =
        "There was an error with the absence deletion.";
    });
    builder.addCase(addNewAbsence.pending, (state) => {
      state.absenceActionLoading = "add";
      state.absenceActionSuccessMessage = "";
      state.absenceActionErrorMessage = "";
    });
    builder.addCase(addNewAbsence.fulfilled, (state) => {
      state.absenceActionLoading = "";
      state.absenceActionSuccessMessage =
        "Absence has been added successfully.";
      state.absenceActionErrorMessage = "";
    });
    builder.addCase(addNewAbsence.rejected, (state, { error }) => {
      console.log("ABSENCE CREATION ERROR::", error);
      state.absenceActionLoading = "";
      state.absenceActionSuccessMessage = "";
      state.absenceActionErrorMessage = "There was an error with the absence.";
    });
    builder.addCase(updateExistingAbsence.pending, (state) => {
      state.absenceActionLoading = "edit";
      state.absenceActionSuccessMessage = "";
      state.absenceActionErrorMessage = "";
    });
    builder.addCase(updateExistingAbsence.fulfilled, (state) => {
      state.absenceActionLoading = "";
      state.absenceActionSuccessMessage =
        "Absence has been updated successfully.";
      state.absenceActionErrorMessage = "";
    });
    builder.addCase(updateExistingAbsence.rejected, (state, { error }) => {
      console.log("ABSENCE UPDATE ERROR::", error);
      state.absenceActionLoading = "";
      state.absenceActionSuccessMessage = "";
      state.absenceActionErrorMessage = "There was an error with the absence.";
    });
  },
});

// ACTIONS
export const {
  setAbsencesRefreshing,
  resetAbsencesActionData,
  setAbsencesList,
  resetAbsencesState,
} = slice.actions;

// SELECTORS
const selectAbsencesData = (state) => {
  return state.absences;
};

export const selectAbsences = createSelector(
  selectAbsencesData,
  ({ absencesList, absencesLoading, absencesRefreshing }) => ({
    absencesList,
    absencesLoading,
    absencesRefreshing,
  }),
);

export const selectAbsenceActionData = createSelector(
  selectAbsencesData,
  ({
    absenceActionLoading,
    absenceActionErrorMessage,
    absenceActionSuccessMessage,
  }) => ({
    absenceActionLoading,
    absenceActionErrorMessage,
    absenceActionSuccessMessage,
  }),
);

export default slice.reducer;
