import {
  createSlice,
  createSelector,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import axiosClient from "../utils/axiosClient";
import {
  CHECK_MEPA_PROPOSAL_SCHEDULED_TIMES,
  GET_MEETING_HISTORY_API,
  GET_SCHEDULED_MEETING_API,
  MEETINGS_BASE_API,
  MEETING_REQUEST_BASE_API,
  MEPA_FIX_MEPA,
  MEPA_GET_INVITE_STATUS,
  MEPA_MEETING_REQUEST,
  MEPA_SELECT_TIME_SLOTS,
  SYNC_MY_CALENDARS_API,
} from "../utils/apiRoutes";
import {
  groupeScheduledEventsData,
  parseCreateMeetingRequestDataForApi,
  parseMeetingDetailData,
  parseMeetingHistorySessions,
  parseMeetingProposalData,
  queryfyUrl,
} from "../utils/dataParser";
import {
  getCurrentDateTime,
  getEndOfDateTime,
  getStartOfDateTime,
} from "../utils/dateTime";

const initialState = {
  scheduledEventsList: {},
  scheduledEventsLoading: false,
  scheduledEventsRefreshing: false,
  scheduledEventDetail: null,
  scheduledEventDetailLoading: false,
  scheduledEventDetailError: null,
  eventHistory: [],
  eventHistoryLoading: false,
  eventHistoryError: null,
  updateScheduledMeetingLoading: false,
  updateScheduledMeetingSuccess: null,
  updateScheduledMeetingError: null,
  scheduledEventDeletionLoading: false,
  scheduledEventDeletionSuccess: null,
  scheduledEventDeletionError: null,
  meetingSlotsWithInvitee: [],
  meetingProposalStatus: null,
  isDeleteSuccess: false,
  isFinalizeMepaSuccess: false,
  isFixMepaSuccess: false,
  isSuccessLoader: false,
};

export const fetchScheduledEvents = createAsyncThunk(
  "scheduledEvents/fetchScheduledEvents",
  async (data, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { scheduledEventsList } = getState().scheduledEvents;
    if (data.isRefreshing) {
      dispatch(setRefreshing());
    }
    if (data.syncExternal) {
      await axiosClient.get(`${serverUrl}/${SYNC_MY_CALENDARS_API}`);
    }

    const dateRangeWithTime = {
      start: getStartOfDateTime(data.start, "date", "YYYY-MM-DDTHH:mm:ss"),
      end: getEndOfDateTime(data.end, "date", "YYYY-MM-DDTHH:mm:ss"),
    };

    // Fetch Result
    const url = queryfyUrl(
      `${serverUrl}/${GET_SCHEDULED_MEETING_API}`,
      dateRangeWithTime,
    );
    const { data: result } = await axiosClient.get(url);
    const groupedResult = groupeScheduledEventsData(
      data.isRefreshing ? {} : scheduledEventsList,
      result,
    );
    return groupedResult;
  },
);

export const refreshScheduledEvents = createAsyncThunk(
  "scheduledEvents/refreshScheduledEvents",
  async (rangeData, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { scheduledEventsList } = getState().scheduledEvents;
    dispatch(setRefreshing());

    let range = rangeData;
    // If start,end not provided, generate it
    if (!rangeData) {
      const dateGroups = Object.keys(scheduledEventsList).sort((a, b) => {
        return a.localeCompare(b);
      });

      // If the data was pre-loaded, select that range
      // Else create new range from the today + end-of-month
      if (dateGroups.length) {
        range = {
          start: dateGroups[0],
          end: dateGroups[dateGroups.length - 1],
        };
      } else {
        const todayDate = getCurrentDateTime("YYYY-MM-DDTHH:mm:ss");
        const endDate = getEndOfDateTime(
          todayDate,
          "month",
          "YYYY-MM-DDTHH:mm:ss",
        );
        range = {
          start: todayDate,
          end: endDate,
        };
      }
    }

    const dateRangeWithTime = {
      start: getStartOfDateTime(range.start, "date", "YYYY-MM-DDTHH:mm:ss"),
      end: getEndOfDateTime(range.end, "date", "YYYY-MM-DDTHH:mm:ss"),
    };

    // Fetch Result
    const url = queryfyUrl(
      `${serverUrl}/${GET_SCHEDULED_MEETING_API}`,
      dateRangeWithTime,
    );
    try {
      const { data: result } = await axiosClient.get(url);
      const groupedResult = groupeScheduledEventsData({}, result);
      dispatch(setScheduledEvents(groupedResult));
    } catch {
      // Catch block haven't any work as of yet.
    } finally {
      dispatch(setRefreshing(false));
    }
  },
);

export const deleteMepaMeetingById = createAsyncThunk(
  "scheduledEvents/deleteMepaMeetingById",
  async (data, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { mepaId } = data;
    if (mepaId) {
      const url = `${serverUrl}/${MEPA_MEETING_REQUEST}/${mepaId}`;
      const { data: result } = await axiosClient.delete(url);
      return result;
    }
  },
);

export const fetchScheduledEventDetailById = createAsyncThunk(
  "scheduledEvents/fetchScheduledEventDetailById",
  async (data, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;

    const { eventId, isProposal, mepaId } = data;

    if (mepaId) {
      const url = `${serverUrl}/${MEPA_MEETING_REQUEST}/${mepaId}`;
      const { data: result } = await axiosClient.get(url);
      const parsedResult = parseMeetingProposalData(result, mepaId);
      return parsedResult;
    } else {
      dispatch(setEventDetailStatus());

      const url = `${serverUrl}/${
        isProposal ? MEETING_REQUEST_BASE_API : MEETINGS_BASE_API
      }/${eventId}`;
      const { data: result } = await axiosClient.get(url);
      let parsedResult = null;
      if (isProposal) {
        parsedResult = parseMeetingProposalData(result, eventId);
      } else {
        parsedResult = parseMeetingDetailData(result, eventId);
      }
      // dispatch(fetchEventHistory(parsedResult.proposalID));
      return parsedResult;
    }
  },
);

export const fetachGetInviteStatus = createAsyncThunk(
  "api/MEPA/GetInviteStatus",
  async (data, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { mepaId } = data;
    if (mepaId) {
      const url = `${serverUrl}/${MEPA_GET_INVITE_STATUS}?mepaId=${mepaId}`;
      const { data: result } = await axiosClient.get(url);
      return result;
    }
  },
);

export const fetchMepaScheduleTimeSlots = createAsyncThunk(
  "fetchMepaScheduleTimeSlots",
  async (data, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { mepaId } = data;
    if (mepaId) {
      const url = `${serverUrl}/${CHECK_MEPA_PROPOSAL_SCHEDULED_TIMES}?id=${mepaId}`;
      const { data: result } = await axiosClient.get(url);
      return result.meetingProposalStatus;
    }
  },
);

export const fetchSelectTimeSlots = createAsyncThunk(
  "SelectTimeSlots",
  async (payload, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const url = `${serverUrl}/${MEPA_SELECT_TIME_SLOTS}`;
    const { data: result } = await axiosClient.post(url, payload);
    return result;
  },
);

export const fetchFixTimeSlots = createAsyncThunk(
  "fetchFixTimeSlots",
  async (payload, { getState, dispatch }) => {
    const { serverUrl } = getState().configs;
    const url = `${serverUrl}/${MEPA_FIX_MEPA}?mepaId=${payload.mepaId}&proposalId=${payload.proposalId}`;
    const { data: result } = await axiosClient.post(url, payload);
    return result;
  },
);

export const fetchEventHistory = createAsyncThunk(
  "scheduledEvents/fetchEventHistory",
  async (meetingProposalId, { getState, rejectWithValue }) => {
    const { serverUrl } = getState().configs;
    const url = queryfyUrl(`${serverUrl}/${GET_MEETING_HISTORY_API}`, {
      meetingProposalId,
    });
    const { data: result } = await axiosClient.get(url);
    if (result.length === 0) {
      return rejectWithValue("No sessions history exists for this event.");
    }
    const parsedResult = parseMeetingHistorySessions(result);
    return parsedResult;
  },
);

export const updateScheduledMeeting = createAsyncThunk(
  "meetingRequests/updateScheduledMeeting",
  async (data, { getState, rejectWithValue, fulfillWithValue, dispatch }) => {
    const { serverUrl } = getState().configs;
    const { currentUser } = getState().user;
    const parsedData = parseCreateMeetingRequestDataForApi(data, currentUser);
    try {
      await axiosClient.put(`${serverUrl}/${MEETINGS_BASE_API}`, parsedData);
      // Refresh Events
      dispatch(refreshScheduledEvents());
      return fulfillWithValue("Meeting has been updated successfully.");
    } catch (error) {
      const parsedErrors = Object.values(error.response.data.errors).flat();
      return rejectWithValue(parsedErrors.join(" "));
    }
  },
);

export const deleteScheduledEvent = createAsyncThunk(
  "meetingRequests/deleteScheduledEvent",
  async (eventId, { getState, dispatch, fulfillWithValue }) => {
    const { serverUrl } = getState().configs;
    const url = `${serverUrl}/${MEETINGS_BASE_API}/${eventId}`;
    await axiosClient.delete(url);

    // Remove the corresponding meeting from the meetings list when deleted
    dispatch(removeEventFromList(eventId));

    // Post appropriate message
    return fulfillWithValue("The scheduled meeting has been deleted.");
  },
);

export const removeEventFromList = createAsyncThunk(
  "scheduledEvents/removeEventFromList",
  async (eventId, { getState, dispatch }) => {
    const { scheduledEventsList } = getState().scheduledEvents;
    const existingEvents = { ...scheduledEventsList };
    for (const key in existingEvents) {
      existingEvents[key] = existingEvents[key].filter(
        (event) => event.entityID !== eventId,
      );

      // Optimization: If the Arrays length at both key differs, it means the event is removed
      if (existingEvents[key].length !== scheduledEventsList[key].length) {
        // If No more events in the group, then remove the day group;
        if (!existingEvents[key].length) {
          delete existingEvents[key];
        }
        // Since only 1 event can match the Id, so no need to further check
        break;
      }
    }
    dispatch(setScheduledEvents(existingEvents));
  },
);

const slice = createSlice({
  name: "scheduledEvents",
  initialState,
  reducers: {
    setRefreshing: (state, { payload }) => {
      state.scheduledEventsRefreshing = payload !== undefined ? payload : true;
    },
    setScheduledEvents: (state, { payload }) => {
      state.scheduledEventsList = payload;
    },
    setEventDetailStatus: (state) => {
      state.scheduledEventDetail = null;
      state.scheduledEventDetailError = null;
      state.scheduledEventDetailLoading = true;
    },
    resetUpdateScheduledMeetingStatus: (state) => {
      state.updateScheduledMeetingLoading = false;
      state.updateScheduledMeetingSuccess = null;
      state.updateScheduledMeetingError = null;
    },
    resetScheduledEventUpdateError: (state) => {
      state.updateScheduledMeetingError = null;
    },
    resetScheduledEventDeletionError: (state) => {
      state.scheduledEventDeletionError = null;
    },
    resetScheduledEventDeletionStatus: (state) => {
      state.scheduledEventDeletionLoading = false;
      state.scheduledEventDeletionSuccess = null;
      state.scheduledEventDeletionError = null;
    },
    rescheduleDeleteMeeting: (state) => {
      state.isDeleteSuccess = false;
    },
    rescheduleFinalizeSuccess: (state) => {
      state.isFinalizeMepaSuccess = false;
    },
    rescheduleFixSuccess: (state) => {
      state.isFixMepaSuccess = false;
    },
    resetScheduledEventsState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScheduledEvents.pending, (state) => {
      state.scheduledEventsLoading = true;
    });
    builder.addCase(fetchScheduledEvents.fulfilled, (state, { payload }) => {
      state.scheduledEventsLoading = false;
      state.scheduledEventsRefreshing = false;
      state.scheduledEventsList = payload;
    });
    builder.addCase(fetchScheduledEvents.rejected, (state) => {
      state.scheduledEventsLoading = false;
      state.scheduledEventsRefreshing = false;
    });
    builder.addCase(fetchScheduledEventDetailById.pending, (state) => {
      state.scheduledEventDetailError = null;
      state.scheduledEventDetailLoading = true;
      state.scheduledEventDetail = null;
    });
    builder.addCase(
      fetchScheduledEventDetailById.fulfilled,
      (state, { payload }) => {
        state.scheduledEventDetailLoading = false;
        state.scheduledEventDetail = payload;
      },
    );
    builder.addCase(
      fetchScheduledEventDetailById.rejected,
      (state, { error }) => {
        state.scheduledEventDetail = null;
        state.scheduledEventDetailLoading = false;
        state.scheduledEventDetailError = "This meeting is not available.";
      },
    );
    builder.addCase(fetchEventHistory.pending, (state) => {
      state.eventHistory = [];
      state.eventHistoryError = null;
      state.eventHistoryLoading = true;
    });
    builder.addCase(fetchEventHistory.fulfilled, (state, { payload }) => {
      state.eventHistory = payload;
      state.eventHistoryError = null;
      state.eventHistoryLoading = false;
    });
    builder.addCase(fetchEventHistory.rejected, (state, { error }) => {
      console.log("ERROR", error);
      state.eventHistoryLoading = false;
      state.eventHistoryError = "Could not load history for this meeting";
      state.eventHistory = [];
    });
    builder.addCase(updateScheduledMeeting.pending, (state) => {
      state.updateScheduledMeetingLoading = true;
      state.updateScheduledMeetingError = null;
      state.updateScheduledMeetingSuccess = null;
    });
    builder.addCase(updateScheduledMeeting.fulfilled, (state, { payload }) => {
      state.updateScheduledMeetingLoading = false;
      state.updateScheduledMeetingSuccess = payload;
    });
    builder.addCase(updateScheduledMeeting.rejected, (state, { payload }) => {
      state.updateScheduledMeetingLoading = false;
      state.updateScheduledMeetingError =
        payload || "There was an error with your meesting request";
    });
    builder.addCase(deleteScheduledEvent.pending, (state) => {
      state.scheduledEventDeletionLoading = true;
    });
    builder.addCase(deleteScheduledEvent.fulfilled, (state, { payload }) => {
      state.scheduledEventDeletionLoading = false;
      state.scheduledEventDeletionSuccess = payload;
    });
    builder.addCase(deleteScheduledEvent.rejected, (state) => {
      state.scheduledEventDeletionLoading = false;
      state.scheduledEventDeletionError =
        "Sorry! Failed to process your request this time, please retry again,";
    });

    // status
    builder.addCase(fetachGetInviteStatus.pending, (state) => {
      state.scheduledEventDetailLoading = true;
    });
    builder.addCase(fetachGetInviteStatus.fulfilled, (state, { payload }) => {
      state.scheduledEventDetailLoading = false;
      state.meetingSlotsWithInvitee = payload;
      state.meetingProposalStatus = null;
    });
    builder.addCase(fetachGetInviteStatus.rejected, (state) => {
      state.scheduledEventDetailLoading = false;
    });

    builder.addCase(fetchMepaScheduleTimeSlots.pending, (state) => {
      state.scheduledEventDetailLoading = true;
    });
    builder.addCase(
      fetchMepaScheduleTimeSlots.fulfilled,
      (state, { payload }) => {
        state.scheduledEventDetailLoading = false;
        state.meetingProposalStatus = payload;
        state.meetingSlotsWithInvitee = null;
      },
    );
    builder.addCase(fetchMepaScheduleTimeSlots.rejected, (state) => {
      state.scheduledEventDetailLoading = false;
    });

    //delete meeting

    builder.addCase(deleteMepaMeetingById.pending, (state) => {
      state.isSuccessLoader = true;
      state.isDeleteSuccess = false;
    });
    builder.addCase(deleteMepaMeetingById.fulfilled, (state, { payload }) => {
      state.isSuccessLoader = false;
      state.isDeleteSuccess = true;
    });
    builder.addCase(deleteMepaMeetingById.rejected, (state) => {
      state.isSuccessLoader = false;
      state.isDeleteSuccess = false;
    });

    //Finalize mepa

    builder.addCase(fetchSelectTimeSlots.pending, (state) => {
      state.isSuccessLoader = true;
      state.isFinalizeMepaSuccess = false;
    });
    builder.addCase(fetchSelectTimeSlots.fulfilled, (state, { payload }) => {
      state.isSuccessLoader = false;
      state.isFinalizeMepaSuccess = true;
    });
    builder.addCase(fetchSelectTimeSlots.rejected, (state) => {
      state.isSuccessLoader = false;
      state.isFinalizeMepaSuccess = false;
    });

    // fixing mepa

    builder.addCase(fetchFixTimeSlots.pending, (state) => {
      state.isSuccessLoader = true;
      state.isFixMepaSuccess = false;
    });
    builder.addCase(fetchFixTimeSlots.fulfilled, (state, { payload }) => {
      state.isSuccessLoader = false;
      state.isFixMepaSuccess = true;
    });
    builder.addCase(fetchFixTimeSlots.rejected, (state) => {
      state.isSuccessLoader = false;
      state.isFixMepaSuccess = false;
    });
  },
});

// ACTIONS
export const {
  setRefreshing,
  setScheduledEvents,
  setEventDetailStatus,
  resetScheduledEventsState,
  resetUpdateScheduledMeetingStatus,
  resetScheduledEventUpdateError,
  resetScheduledEventDeletionError,
  resetScheduledEventDeletionStatus,
  rescheduleDeleteMeeting,
  rescheduleFinalizeSuccess,
  rescheduleFixSuccess,
} = slice.actions;

// SELECTORS
const selectScheduledEventsData = (state) => {
  return state.scheduledEvents;
};

export const selectScheduledEvents = createSelector(
  selectScheduledEventsData,
  ({
    scheduledEventsLoading,
    scheduledEventsList,
    scheduledEventsRefreshing,
  }) => ({
    scheduledEventsLoading,
    scheduledEventsList,
    scheduledEventsRefreshing,
  }),
);

export const selectEventDetail = createSelector(
  selectScheduledEventsData,
  ({
    scheduledEventDetail,
    scheduledEventDetailLoading,
    scheduledEventDetailError,
  }) => ({
    scheduledEventDetail,
    scheduledEventDetailLoading,
    scheduledEventDetailError,
  }),
);
export const selectEventHistory = createSelector(
  selectScheduledEventsData,
  ({ eventHistory, eventHistoryLoading, eventHistoryError }) => ({
    eventHistory,
    eventHistoryLoading,
    eventHistoryError,
  }),
);

export const selectScheduledEventUpdateStatus = createSelector(
  selectScheduledEventsData,
  ({
    updateScheduledMeetingLoading,
    updateScheduledMeetingSuccess,
    updateScheduledMeetingError,
  }) => ({
    updateScheduledMeetingLoading,
    updateScheduledMeetingSuccess,
    updateScheduledMeetingError,
  }),
);

export const selectScheduledEventDeletionStatus = createSelector(
  selectScheduledEventsData,
  ({
    scheduledEventDeletionLoading,
    scheduledEventDeletionSuccess,
    scheduledEventDeletionError,
  }) => ({
    scheduledEventDeletionLoading,
    scheduledEventDeletionSuccess,
    scheduledEventDeletionError,
  }),
);
export default slice.reducer;
