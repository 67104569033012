import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthContainer } from "./components/layout/AuthContainer";
import { AdminContainer } from "./components/layout/AdminContainer";
import { Login } from "./pages/Login";
import { RecoverPassword } from "./pages/RecoverPassword";
import { Dashboard } from "./pages/Admin/Dashboard";
import { Meetings } from "./pages/Admin/Meetings";
import { MeetingRequests } from "./pages/Admin/MeetingRequests";
import { Calendar } from "./pages/Admin/Calendar";
import { Settings } from "./pages/Admin/Settings";
import { SupportCenter } from "./pages/Admin/SupportCenter";
import { Absences } from "./pages/Admin/Absences";
import { ProtectedRouter } from "./components/auth/ProtectedRouter";
import { authenticateUser } from "./store/user";
import { MepaMeeting } from "./pages/Admin/MepaMeeting";

export const AppRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authenticateUser());
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="auth" element={<AuthContainer />}>
          <Route path="login" element={<Login />} />
          <Route path="recover-password" element={<RecoverPassword />} />
        </Route>
        <Route
          path="admin"
          element={
            <ProtectedRouter>
              <AdminContainer />
            </ProtectedRouter>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="manage-meetings" element={<Meetings />} />
          <Route path="meeting-requests" element={<MeetingRequests />} />
          <Route path="calendar" element={<Calendar />} />
          <Route path="settings" element={<Settings />} />
          <Route path="support-center" element={<SupportCenter />} />
          <Route path="manage-absences" element={<Absences />} />
          <Route path="mepa-meeting" element={<MepaMeeting />} />
        </Route>
        <Route path="*" element={<Navigate to="/admin/dashboard" replace />} />
      </Routes>
    </BrowserRouter>
  );
};
