import { useEffect } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { Switch } from "../../theme";
import { SettingsCard } from "./SettingsCard";
import { ReactComponent as NotificationsIcon } from "../../assets/icons/Notifications.svg";
import {
  selectNotificationsGroupedTypes,
  selectNotificationsTypes,
} from "../../store/configs";
import { getInitialNotificationsSettings } from "../../pages/Admin/Settings/settingsForms";

export const SettingsNotifications = ({
  listRef,
  enabledNotifications,
  formInstance,
}) => {
  const settingsList = useSelector(selectNotificationsTypes);
  const groupedSettingsList = useSelector(selectNotificationsGroupedTypes);

  useEffect(() => {
    const enabledList = getInitialNotificationsSettings(
      enabledNotifications,
      settingsList
    );
    formInstance.resetForm({
      values: {
        enabledMessageTypeIdsForNotifications: enabledList,
      },
    });
  }, [enabledNotifications, settingsList]);

  return (
    <SettingsCard
      title="Notifications"
      description="Setup how you want to be notified"
      Icon={NotificationsIcon}
      hideBottomBorder={true}
      sectionKey="notifications"
      listRef={listRef}
      haveChanges={formInstance.dirty}
    >
      <div className="flex gap-7 mb-5 ml-[302px]">
        <p className="flex-grow text-sm font-semibold text-gray500">
          Notification
        </p>
        <p className="text-gray500 font-semibold text-sm w-[38px]">Email</p>
      </div>
      {groupedSettingsList.map((settingsGroup, index) => (
        <div key={index} className="flex mb-14">
          <p
            className={clsx(
              "text-gray500 font-semibold text-sm text-end",
              "mr-6 w-[278px] pt-[6px] shrink-0"
            )}
          >
            {settingsGroup.title}
          </p>
          <div className="flex flex-col flex-grow">
            {settingsGroup.notificationsType.map((notifSettingRow, index) => (
              <div
                key={index}
                className="flex gap-7 items-center p-1 border-b border-gray500 my-[2px]"
              >
                <p className="flex-grow text-sm">{notifSettingRow.title}</p>
                <Switch
                  name="enabledMessageTypeIdsForNotifications"
                  value={notifSettingRow.id.toString()}
                  checked={formInstance.values.enabledMessageTypeIdsForNotifications.includes(
                    notifSettingRow.id.toString()
                  )}
                  onChange={formInstance.handleChange}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </SettingsCard>
  );
};
