import { useState } from "react";
import clsx from "clsx";
import { Modal } from "../layout/Modal";
import { Spinner } from "../Spinner";
import { MeetingAgendModal } from "./MeetingAgendModal";
import { MeetingRequestsActions } from "../meeting-requests/MeetingRequestsActions";
import { ActionSuccessModal } from "./ActionSuccessModal";
import { ActionErrorModal } from "./ActionErrorModal";
import Avatar from "../Avatar";
import MeetingParticipant from "../meetings/MeetingParticipant";
import { MEETING_INVITATION_RESPONSE } from "../../utils/constants";

import { ReactComponent as TickIcon } from "../../assets/icons/Tick.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/Cross.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/time.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/Calendar.svg";
import { ReactComponent as GroupIcon } from "../../assets/icons/Group.svg";
import { ReactComponent as AgendaIcon } from "../../assets/icons/Agenda.svg";
import { ReactComponent as PinIcon } from "../../assets/icons/pin.svg";
import { ReactComponent as AdjustableIcon } from "../../assets/icons/adjustable.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/Location.svg";

export const MeetingProposalModal = ({
  proposalData,
  actionLoading,
  actionSuccess,
  actionError,
  onAccept,
  onDecline,
  onClearError,
  onClose,
}) => {
  const [showAgendaDetailModal, setShowAgendaDetailModal] = useState(false);

  return (
    <>
      {!actionError && !actionSuccess && (
        <div className={clsx("font-semibold max-w-[826px] w-[90vw] py-8")}>
          <div className="flex items-center justify-between gap-8 pl-8">
            <div className="flex items-center gap-2 shrink-0">
              <Avatar
                userId={proposalData.hostPersonID}
                firstName={proposalData.host}
                lastName=""
                className="w-8 h-8 rounded-full"
                profileById={true}
              />
              <p className="border border-gray500 rounded-[10px] px-4 py-1 whitespace-nowrap">
                {proposalData.host}
              </p>
            </div>
            <p
              className={clsx(
                "text-xl text-ellipsis overflow-hidden whitespace-nowrap",
              )}
            >
              {proposalData.topic}
            </p>
            {!proposalData.response && (
              <button className="text-primary mr-8" onClick={onClose}>
                <CrossIcon fill="transparent" />
              </button>
            )}
            {proposalData.response === MEETING_INVITATION_RESPONSE.ACCEPTED && (
              <div
                className={clsx(
                  "flex items-center gap-1 px-3 rounded-1",
                  "text-base leading-loose",
                  "bg-success100 text-success",
                )}
              >
                <TickIcon fill="transparent" />
                <span>Accepted</span>
              </div>
            )}
            {proposalData.response === MEETING_INVITATION_RESPONSE.REJECTED && (
              <div
                className={clsx(
                  "flex items-center gap-1 px-3 rounded-1",
                  "text-base leading-loose",
                  "bg-danger100 text-danger",
                )}
              >
                <CrossIcon fill="transparent" />
                <span>Declined</span>
              </div>
            )}
          </div>
          <div className="my-6 px-8">
            <div
              className={clsx(
                "flex items-center justify-between gap-x-6 gap-y-3 flex-wrap",
                "border border-gray500 rounded-[10px] p-4",
                "whitespace-nowrap font-normal",
              )}
            >
              <p className="flex items-center gap-2">
                <ClockIcon fill="transparent" />
                <span>{proposalData.meetingDuration}</span>
              </p>
              <p className="flex items-center gap-2">
                <CalendarIcon fill="transparent" />
                <span>{proposalData.meetingWindow}</span>
              </p>
              <p className="flex items-center gap-2">
                <LocationIcon fill="transparent" />
                <span>{proposalData.platformTitle}</span>
              </p>
              <p className="flex items-center gap-2">
                {proposalData.nonAdjustable ? (
                  <PinIcon fill="transparent" />
                ) : (
                  <AdjustableIcon fill="transparent" />
                )}
                <span>
                  {proposalData.nonAdjustable
                    ? "Non-Adjustable Meeting"
                    : "Adjustable Meeting"}
                </span>
              </p>
            </div>

            <div className="flex gap-4 items-start my-6">
              <p className="flex items-center gap-2 text-gray500">
                <AgendaIcon fill="transparent" />
                <span className="font-normal">Agenda</span>
              </p>
              <div className="">
                <p className="font-normal">{proposalData.agenda}</p>
                {proposalData.canExapandAgendaText && (
                  <button
                    className="border border-gray500 px-3 py-1 rounded-full mt-3"
                    onClick={() => setShowAgendaDetailModal(true)}
                  >
                    Expand Agenda
                  </button>
                )}
              </div>
            </div>

            <div className="flex gap-4 items-start">
              <p className="flex items-center gap-2 text-gray500">
                <GroupIcon fill="transparent" />
                <span className="font-normal">Attendees</span>
              </p>
              <div className="flex flex-wrap gap-1">
                {proposalData.invitees.map((invitedUser, index) => (
                  <MeetingParticipant
                    key={index}
                    firstName={
                      invitedUser?.firstName
                        ? invitedUser?.firstName
                        : invitedUser?.emailAddress?.split("@")[0]
                    }
                    lastName={invitedUser.lastName ? invitedUser?.lastName : ""}
                    hasApproved={
                      invitedUser.response ===
                      MEETING_INVITATION_RESPONSE.ACCEPTED
                    }
                    hasRejected={
                      invitedUser.response ===
                      MEETING_INVITATION_RESPONSE.REJECTED
                    }
                  />
                  // <p
                  // 	key={invitedUser.id}
                  // 	className='border border-gray500 px-3 py-1 leading-none rounded-[10px] whitespace-nowrap'
                  // >
                  // 	{invitedUser.firstName} {invitedUser.lastName}
                  // </p>
                ))}
              </div>
            </div>
          </div>
          <MeetingRequestsActions
            response={proposalData.response}
            onClose={onClose}
            onAccept={onAccept}
            onDecline={onDecline}
          />
          {showAgendaDetailModal && (
            <Modal
              isOpen={showAgendaDetailModal}
              onClose={() => setShowAgendaDetailModal(false)}
              preventAnimate={true}
              hideHeader={true}
              modalClassName="border border-gray500 shadow-theme p-0"
            >
              <MeetingAgendModal
                onClose={() => setShowAgendaDetailModal(false)}
                agendaText={proposalData.agendaFull}
              />
            </Modal>
          )}
          {actionLoading && (
            <div
              className={clsx(
                "absolute top-0",
                "flex flex-col items-center justify-center",
                "w-full h-full bg-[#b5b5b5b5] rounded-[10px]",
              )}
            >
              <Spinner className="!w-6 !h-6 mb-2" />
              <p>Loading...</p>
            </div>
          )}
        </div>
      )}
      {actionSuccess && (
        <ActionSuccessModal message={actionSuccess} onClose={onClose} />
      )}
      {actionError && (
        <ActionErrorModal
          onClose={onClose}
          onClearError={onClearError}
          modalTitle="Response to invitation failed."
          message={actionError}
        />
      )}
    </>
  );
};
