import { useState } from "react";
import clsx from "clsx";

export const Tooltip = ({
  content,
  containerClassName,
  tooltipClassName,
  children,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div className={clsx("relative", containerClassName)}>
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {children}
      </div>
      {showTooltip && content && (
        <div
          className={clsx(
            "absolute bottom-full left-1/2 mb-[2px] min-w-[100px] -translate-x-1/2",
            "bg-primary text-[white] text-[10px] text-center rounded-md",
            "py-1 px-2 pointer-events-none",
            tooltipClassName,
          )}
        >
          {content}
        </div>
      )}
    </div>
  );
};
