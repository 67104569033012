import React from "react";
import searchimg from "../../assets/images/searchloading.gif";
import logout from "../../assets/images/logout.png";
import closecircle from "../../assets/images/closecircle.png";
import { useDispatch } from "react-redux";
import { setExistModalCalculationSlots } from "../../store/meetingRequests";
import { useNavigate } from "react-router-dom";

const CalculationTimeSlot = ({
  onCancel,
  actionLoading,
  onHideCreateMeetingModal,
}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickForSlot = () => {
    onHideCreateMeetingModal();
    dispatch(setExistModalCalculationSlots(true));
    navigate("/");
  };

  return (
    <>
      <div className="md:w-[600px] z-50  w-full h-[500px] flex flex-col justify-between p-5">
        <div className=" w-full mx-auto p-5">
          <div>
            <p className="text-xl text-center">
              Calculation of time slots is in progress
            </p>
          </div>
          <div className="flex justify-center py-10">
            <img src={searchimg} alt="" />
          </div>
          <div className=" text-center mt-5">
            <p>
              The search for suitable time slots might take few minutes. You can
              leave and return for slot selection
            </p>
          </div>
        </div>
        <div className="w-2/3 mx-auto ">
          {actionLoading ? (
            <div
              style={{ border: "1px solid lightgray" }}
              className="flex items-center gap-3  rounded-full justify-center p-2 m-2 bg-[#EAF9F8]"
            >
              <img src={logout} alt="" className="w-6 h-6" />
              <button className=""> Loading Time Slot</button>
            </div>
          ) : (
            <div
              style={{ border: "1px solid lightgray" }}
              className="flex items-center gap-3 cursor-pointer  rounded-full justify-center p-2 m-2 bg-[#EAF9F8]"
              onClick={handleClickForSlot}
            >
              <img src={logout} alt="" className="w-6 h-6" />
              <button> Exit and notify me for slot selection</button>
            </div>
          )}
          <div
            style={{ border: "1px solid lightgray" }}
            // onClick={onCancel}
            onClick={onCancel}
            className="flex items-center gap-3  rounded-full justify-center p-2 m-2 mt-4 mb-4"
          >
            <img src={closecircle} alt="" />{" "}
            <button className=""> Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalculationTimeSlot;
