import clsx from "clsx";
import { ReactComponent as TickIcon } from "../../assets/icons/Tick.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/Cross.svg";

export const MeetingRequestsActions = ({
  response,
  hideClose,
  onClose,
  onAccept,
  onDecline,
}) => {
  return (
    <div className="flex justify-center items-center gap-6 font-semibold">
      {response !== 1 && (
        <button
          className={clsx(
            "flex items-center gap-6 px-6",
            "w-60 h-12",
            "border border-gray500 rounded-full bg-[#EAF9F8]",
            "shadow-theme",
          )}
          onClick={onAccept}
        >
          <TickIcon className="w-6 h-6" fill="transparent" />
          <span>Accept request</span>
        </button>
      )}
      {response !== 2 && (
        <button
          className={clsx(
            "flex items-center gap-6 px-6",
            "w-60 h-12 bg-neutral text-danger",
            "border border-[#FFE9EE] rounded-full",
            "shadow-theme",
          )}
          onClick={onDecline}
        >
          <CrossIcon className="w-6 h-6" fill="transparent" />
          <span>Decline request</span>
        </button>
      )}
      {!!response && !hideClose && (
        <button className="text-base text-gray500" onClick={onClose}>
          Close
        </button>
      )}
    </div>
  );
};
