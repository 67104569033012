import clsx from "clsx";
import { Tooltip } from "../../../theme";

import { ReactComponent as WrenchIcon } from "../../../assets/icons/Wrench.svg";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import { ReactComponent as CrossCircledIcon } from "../../../assets/icons/Circled_cross.svg";
import { ReactComponent as RedIcon } from "../../../assets/icons/red-info.svg";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectLoggedInUser } from "../../../store/user";
dayjs.extend(relativeTime);

export const MeetingRow = ({
  eventItem,
  onViewDetail,
  onDecline,
  onEdit,
  onDelete,
}) => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const currentUser = useSelector(selectLoggedInUser);

  const calculateEndTime = (startTimes, duration) => {
    const [startHour, startMinute] =
      startTimes && startTimes.split(":").map(Number);
    const [durationHour, durationMinute] =
      duration && duration.split(":").map(Number);

    let endHour = startHour + durationHour;
    let endMinute = startMinute + durationMinute;

    if (endMinute >= 60) {
      endHour += Math.floor(endMinute / 60);
      endMinute %= 60;
    }

    return `${endHour.toString().padStart(2, "0")}:${endMinute
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    const startTimes = dayjs(eventItem.lastScheduleTime).format("HH:mm");
    if (startTimes && eventItem?.minLength) {
      const endTimes = calculateEndTime(startTimes, eventItem.minLength);
      setStartTime(startTimes);
      setEndTime(endTimes);
    }
  }, [eventItem]);

  const parsedeventTime = (eventtime) => {
    let parsedTime = Number(eventtime) * 60;
    parsedTime = Math.ceil(parsedTime);
    if (parsedTime >= 60) {
      let hours = parsedTime / 60;
      let rhours = Math.floor(hours);
      let hoursTitle = rhours > 1 ? "hours" : "hour";
      var minutes = (hours - rhours) * 60;
      var rminutes = Math.round(minutes);
      let minuteTitle = rminutes > 1 ? "minutes" : "minute";
      return rhours + " " + hoursTitle + " " + rminutes + " " + minuteTitle;
    }
    return `${Math.ceil(parsedTime)} minutes`;
  };

  return (
    <tr className="bg-info100 h-[44px]">
      {eventItem?.lastScheduleTime ? (
        <td className="py-1 w-[90px] pl-5 pr-10">
          {startTime} - {endTime}
        </td>
      ) : eventItem.meetingWindow ? (
        <td className="py-1 w-[90px] pl-5 pr-10">{eventItem.meetingWindow}</td>
      ) : (
        <td className="py-1 w-[90px] pl-5 pr-10">
          {eventItem.startTime} - {eventItem.endTime}
        </td>
      )}
      <td
        className={clsx(
          "py-1 w-fit mr-[66px] overflow-hidden",
          "pr-10 max-w-[130px]",
          "text-ellipsis",
        )}
      >
        {eventItem.topic}{" "}
        {eventItem?.mepA_TimeSlotsFinalizationTime === null
          ? eventItem?.mepA_ScheduledProposalsCount > 0 &&
            eventItem?.hostPersonID === currentUser?.userId && (
              <RedIcon className="text-primary w-5 h-5 inline" />
            )
          : eventItem.mepA_ShouldBeManuallyFixedBefore !== null &&
            eventItem?.mepA_FixedProposalId === null &&
            eventItem?.hostPersonID === currentUser?.userId && (
              <RedIcon className="text-primary w-5 h-5 inline" />
            )}
      </td>
      <td>
        {eventItem.isHost
          ? "You"
          : `${eventItem.host ? eventItem.host : eventItem.hostFirstName}  ${
              eventItem?.host ? "" : eventItem.hostLastName
            }`}
      </td>
      <td>{eventItem.mepaId ? "External" : "Internal"}</td>

      {eventItem.eventDurationParsed ? (
        <td>{eventItem.eventDurationParsed}</td>
      ) : (
        <td>{parsedeventTime(eventItem.eventDuration)}</td>
      )}
      <td>{eventItem.meetingPlatform}</td>
      <td className="py-1 pr-4 w-[80px]">
        <div className="flex gap-3 items-center justify-center">
          <Tooltip content="View meeting">
            <button onClick={onViewDetail}>
              <InfoIcon fill="transparent" />
            </button>
          </Tooltip>
          {eventItem.isHost && (
            <>
              {" "}
              {!eventItem?.mepaId && !eventItem.nonAdjustable && (
                <Tooltip content="Edit meeting">
                  <button onClick={onEdit}>
                    <WrenchIcon fill="transparent" />
                  </button>
                </Tooltip>
              )}
              <Tooltip content="Delete meeting">
                <button onClick={onDelete}>
                  <CrossCircledIcon fill="transparent" />
                </button>
              </Tooltip>
            </>
          )}
          {!eventItem.isHost && eventItem.isProposal && (
            <Tooltip content="Decline meeting">
              <button onClick={onDecline}>
                <CrossCircledIcon fill="transparent" />
              </button>
            </Tooltip>
          )}
        </div>
      </td>
    </tr>
  );
};
