import { useState } from "react";
import clsx from "clsx";
import { ReactComponent as InfoIcon } from "../../assets/icons/Info_branded.svg";

export const SectionHeading = ({ content, title, hasTooltip }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <div className="flex items-center gap-[1px] font-semibold text-sm">
      <h5>{title}</h5>
      {hasTooltip && (
        <div className="relative">
          <div
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <InfoIcon className="w-4 h-4" fill="transparent" />
          </div>
          {showTooltip && (
            <div
              className={clsx(
                "absolute bottom-full left-1/2 z-[20] mb-[2px] min-w-[125px] -translate-x-1/2 p-[1px]",
                "pointer-events-none rounded-[6px] rounded-bl-none",
                "bg-gradient-to-b from-[#0A9DDB] to-[#E63B60]",
              )}
            >
              <div
                className={clsx(
                  "bg-neutral rounded-[6px] rounded-bl-none",
                  "text-gray500 text-[10px] text-center font-semibold",
                  "py-1 px-2",
                )}
              >
                {content}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
