import { useEffect, useState } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { getLeftCursorSpace } from "../../utils/dateTime";

export const SelectedHoursSlice = ({
  type,
  sliceId,
  workingHoursStart,
  workingHoursEnd,
  sliceStart,
  selectedHours,
  startEndPos,
  setIsDragActive,
  onSelectionUpdate,
  onRemoveSlice,
}) => {
  const [totalRangeHours, setTotalRangeHours] = useState(selectedHours);
  const [rangeStartPoint, setRangeStartPoint] = useState(
    getLeftCursorSpace(workingHoursStart, sliceStart),
  );

  const [activeCursor, setActiveCursor] = useState(null);
  const [activeCursorPos, setActiveCursorPos] = useState(null);

  const _handleDrag = (event) => {
    // If the cursor goes out of the hours range then Stop
    let translateValue = event.clientX;
    if (
      translateValue < startEndPos.start ||
      translateValue + 3 > startEndPos.end ||
      !activeCursorPos
    ) {
      return;
    }

    if (activeCursor === "min") {
      translateValue = activeCursorPos - translateValue;
    } else {
      translateValue = translateValue - activeCursorPos;
    }
    const hoursCoverd = translateValue / 36;
    const updatedRangeHours = totalRangeHours + hoursCoverd;
    if (updatedRangeHours < 0.5) {
      return;
    }

    let updatedStartRange = rangeStartPoint;

    if (activeCursor === "min") {
      updatedStartRange = rangeStartPoint - hoursCoverd;
      if (updatedStartRange < 0) {
        return;
      }
      setRangeStartPoint(updatedStartRange);
    }

    setTotalRangeHours(updatedRangeHours);
  };

  const _handleDragStart = (cursor, event) => {
    setIsDragActive(true);
    setActiveCursor(cursor);
    setActiveCursorPos(event.clientX);
  };

  const _handleMouseUp = (event) => {
    setIsDragActive(false);
    setActiveCursor(null);
    setActiveCursorPos(null);

    onSelectionUpdate({
      sliceId: sliceId,
      rangeStart: rangeStartPoint,
      timeSliceLength: totalRangeHours,
    });
  };

  useEffect(() => {
    // Update the start point whenever the working hours changes
    const updatedStartPoint = getLeftCursorSpace(workingHoursStart, sliceStart);
    setRangeStartPoint(updatedStartPoint);
  }, [workingHoursStart, workingHoursEnd, sliceStart]);

  // TODO: Redo the Whole Dragging Functionality
  // Until then keeping the mousedown event function commented
  useEffect(() => {
    if (activeCursor) {
      window.addEventListener("mousemove", _handleDrag);
    }
    return () => {
      window.removeEventListener("mousemove", _handleDrag);
    };
  }, [activeCursor, startEndPos.start, startEndPos.end]);

  return (
    <>
      <div
        className={clsx(
          "absolute z-[1] h-[34px] top-[1px]",
          type === "info" ? "bg-info100" : "bg-danger100",
        )}
        style={{
          width: totalRangeHours * 36 + 1,
          left: rangeStartPoint * 36 + 2,
        }}
      >
        <div
          className={clsx(
            "absolute z-[10] h-full w-[1px]",
            type === "info"
              ? "bg-[repeating-linear-gradient(to_bottom,transparent_0_2px,#fff_2px_4px),_linear-gradient(to_bottom,#0FA0DB,#9FDEF7)]"
              : "bg-[repeating-linear-gradient(to_bottom,transparent_0_2px,#fff_2px_4px),_linear-gradient(to_bottom,#FF6F8E,#FFC3D0)]",
          )}
        />
        <div
          className={clsx(
            "absolute z-[10] h-full w-[1px] right-0",
            type === "info"
              ? "bg-[repeating-linear-gradient(to_bottom,transparent_0_2px,#fff_2px_4px),_linear-gradient(to_bottom,#0FA0DB,#9FDEF7)]"
              : "bg-[repeating-linear-gradient(to_bottom,transparent_0_2px,#fff_2px_4px),_linear-gradient(to_bottom,#FF6F8E,#FFC3D0)]",
          )}
        />
      </div>
      <div
        className={clsx(
          "absolute z-[10] h-full flex justify-between",
          activeCursor ? "cursor-col-resize" : "cursor-pointer",
        )}
        style={{
          width: totalRangeHours * 36 + 1,
          left: rangeStartPoint * 36 + 2,
        }}
        onClick={() => onRemoveSlice(sliceId)}
      >
        <motion.div
          drag="x"
          className="bg-[transparent] w-[2px] h-full cursor-col-resize"
          dragConstraints={{ top: 0, bottom: 0 }}
          onDragStart={(event) => _handleDragStart("min", event)}
          onDragEnd={_handleMouseUp}
        />
        <motion.div
          drag="x"
          className="bg-[transparent] w-[2px] h-full cursor-col-resize"
          dragConstraints={{ top: 0, bottom: 0 }}
          onDragStart={(event) => _handleDragStart("max", event)}
          onDragEnd={_handleMouseUp}
        />
      </div>
    </>
  );
};
