import { useEffect, useMemo, useRef, useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Input, Select, Textarea } from "../../theme";

import externalicon from "../../assets/icons/externalicon.svg";
import searchnormal from "../../assets/icons/searchnormal.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/icons/Chevron2.svg";
import { ReactComponent as CrossIcon } from "../../assets/icons/Cross.svg";

import {
  validateMepaMeetingRequest,
  resetAddMeetingError,
  selectInvitableUsers,
  selectExternalInvitableUsers,
  fetchExternalInvitableUsers,
  selectMeetingTimeSlots,
  setExistModalCalculationSlots,
} from "../../store/meetingRequests";
import { resetScheduledEventUpdateError } from "../../store/scheduledEvents";
import { useLinkedMeetingApps } from "../../hooks/integrations";
import {
  getReadableDateRange,
  parseAndFormatProvidedTime,
} from "../../utils/dateTime";
import {
  GetMepaInitialMeetingRequestData,
  mepaMeetingProposalFormSchema,
} from "../../utils/formsValidators";

import { ReactComponent as CalendarIcon } from "../../assets/icons/Calendar.svg";
import { ReactComponent as ClockIcon } from "../../assets/icons/time.svg";
import { ReactComponent as GroupIcon } from "../../assets/icons/Group.svg";
import { ReactComponent as AgendaIcon } from "../../assets/icons/Agenda.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/Location.svg";

import { Modal } from "../../components/layout/Modal";
import { InvitableUsersSelection } from "../../components/meeting-requests/InvitableUsersSelection";
import { MeetingRequestError } from "../../components/meeting-requests/MeetingRequestError";
import { DatePicker } from "../../components/date-picker";
import { Spinner } from "../../components/Spinner";
import Separator from "../../components/Separator";
import CalculationTimeSlot from "./CalculationTimeSlot";
import MeetingTimeSlot from "../../components/modals/MeetingTimeSlot";
import { selectLoggedInUser } from "../../store/user";

export const MepaMeeting = ({
  existingMeetingData,
  actionLoading,
  actionSuccess,
  checkMeetingValidityError,
  actionError,
  onFormStatusChange,
  addMepaTimesloteSuccess,
  onClose,
  onHideCreateMeetingModal,
}) => {
  const userLinkedMeetingApps = useLinkedMeetingApps();
  const { invitableUsers } = useSelector(selectInvitableUsers);
  const { meetingTimeSlots } = useSelector(selectMeetingTimeSlots);

  const addMetingtimeSlotLoading = useSelector(
    (state) => state?.meetingRequests?.addMetingtimeSlotLoading
  );

  const isExitScheduleModal = useSelector(
    (state) => state?.meetingRequests?.isExistCalculationModal
  );

  const currentUser = useSelector(selectLoggedInUser);
  const {
    externalInvitees: externalUserFromApi,
    invitableExternalUsersLoading,
  } = useSelector(selectExternalInvitableUsers);
  const [openMailDropdown, setOpenMailDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const menuListRef = useRef(null);
  const [externalInvitees, setExternalInvitees] = useState([]);

  const inputRef = useRef(null);
  const [inputValueExteralinvitees, setInputValueExteralinvitees] =
    useState("");
  const dispatch = useDispatch();

  const {
    values,
    errors,
    touched,
    dirty,
    handleChange,
    setFieldValue,
    handleBlur,
    submitForm,
  } = useFormik({
    // initialValues: GetMepaInitialMeetingRequestData(existingMeetingData),
    initialValues: {
      ...GetMepaInitialMeetingRequestData(existingMeetingData),
      // start date: 5 days ahead at 00:00:00
      dateTimesToBeHeldStart: convertDateToISOString(new Date(
        Date.now() + 5 * 24 * 60 * 60 * 1000).setHours(0,0, 0, 0)),
      // end date 19 days ahead at 23:59:00
      dateTimesToBeHeldEnd: convertDateToISOString(new Date(
        Date.now() + 19 * 24 * 60 * 60 * 1000).setHours(23, 59, 0, 0)),
      platformId: null,
      minLength: "30", // default meeting length
    },
    validationSchema: mepaMeetingProposalFormSchema,
    onSubmit: async (values) => {
      // return console.log("value :", values);
      // onFormStatusChange(false);
      if (values.isProposal) {
        //   dispatch(validateMepaMeetingRequest(values));
        dispatch(validateMepaMeetingRequest(values));
      } else {
        // dispatch(updateScheduledMeeting(values));
        // console.log("mepa failed to call api");
      }
    },
  });

  const handleAddEmail = () => {
    const newEmail = String(values.externalInvitees).trim();
    const duplicateMail = externalInvitees.find(
      (item) => item.emailAddress === newEmail
    );
    if (duplicateMail !== undefined) {
      return;
    }
    if (newEmail.length > 0 && /\S+@\S+\.\S+/.test(newEmail)) {
      setExternalInvitees((prevList) => [
        ...prevList,
        {
          canAttend: true,
          emailAddress: newEmail,
        },
      ]);
    }
    setInputValueExteralinvitees("");
  };

  const updateExternalInviteesInForm = async () => {
    // console.log("externalInvitees :", externalInvitees);
    await setFieldValue("externalInvitees", externalInvitees);
    await setFieldValue("hostPersonFirstName", currentUser.userFirstName);
    await setFieldValue("hostPersonLastName", currentUser.userLastName);
    await setFieldValue("hostPersonUserName", currentUser.userName);
  };
  useEffect(() => {
    updateExternalInviteesInForm();
  }, [externalInvitees]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    if (inputValue?.length > 1 && !invitableExternalUsersLoading) {
      // onfetchExternalInvities()
      dispatch(fetchExternalInvitableUsers(inputValue));
    }
  };

  function convertDateToISOString(dateString) {
    const date = new Date(dateString);
    return date.toISOString();
  }

  const onRangeChange = (range) => {
    const [start, end] = range;
    const startDate = new Date(start + "T00:00:00");
    const endDate = new Date(end + "T23:59:00");
    setFieldValue("dateTimesToBeHeldStart", convertDateToISOString(startDate), true);
    setFieldValue("dateTimesToBeHeldEnd", convertDateToISOString(endDate), true);
  };

  const onClearError = () => {
    dispatch(resetAddMeetingError());
    dispatch(resetScheduledEventUpdateError());
  };

  const meetingPlatformsList = useMemo(() => {
    const parsedPlatforms = userLinkedMeetingApps.map((platform) => ({
      label: platform.title,
      value: platform.id.toString(),
      isDisabled: !platform.enabled || !platform.isConnected,
    }));

    // Add Offline platform as well
    parsedPlatforms.unshift({
      label: "Offline",
      value: "0",
      isDisabled: false,
    });

    return parsedPlatforms;
  }, [userLinkedMeetingApps]);

  const { formatedRangeStart, formatedRangeEnd } = useMemo(() => {
    const timeFrameDisplay = getReadableDateRange(
      values.dateTimesToBeHeldStart,
      values.dateTimesToBeHeldEnd
    );
    const formatedRangeStart = parseAndFormatProvidedTime("DD/MM/YYYY")(
      values.dateTimesToBeHeldStart
    );
    const formatedRangeEnd = parseAndFormatProvidedTime("DD/MM/YYYY")(
      values.dateTimesToBeHeldEnd
    );

    return { timeFrameDisplay, formatedRangeStart, formatedRangeEnd };
  }, [values.dateTimesToBeHeldStart, values.dateTimesToBeHeldEnd]);

  useEffect(() => {
    if (dirty !== undefined) {
      onFormStatusChange(dirty);
    }
  }, [dirty]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !menuListRef.current?.contains(event.target)
      ) {
        setOpenMailDropdown(false);
      }
    };

    if (openMailDropdown) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [openMailDropdown, dropdownRef.current]);

  useEffect(() => {
    if(isExitScheduleModal){
      setExistModalCalculationSlots(false)
    }
  }, [])


  const colors = [
    "#FF5722",
    "#03A9F4", 
    "#4CAF50", 
  ];

  return (
    <div className={clsx("w-[546px] font-semibold mb-8 px-8")}>
      {!actionSuccess && !actionError && !addMepaTimesloteSuccess && (
        <div>
          <p className="text-gray500 font-medium">Set up your meeting infos</p>
          <div className="mt-3">
            <div className="" ref={dropdownRef}>
              <div className="flex items-center mt-4 mb-2">
                <p className="flex items-center gap-2 text-base font-light shrink-0">
                  <AgendaIcon fill="transparent" />
                  <span>Meeting title</span>
                </p>
                <Separator className="ml-4" />
              </div>
              <Input
                name="topic"
                placeholder="Meeting title"
                value={values.topic}
                className="mb-2 mt-2 py-[9px] font-thin"
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={!!(errors.topic && touched.topic)}
                error={errors.topic}
              />
            </div>
            <div className="">
              <div className="flex items-center mt-4 ">
                <p className="flex items-center gap-2 text-base font-light shrink-0">
                  <CalendarIcon fill="transparent" />
                  <span>Time Window</span>
                </p>
                <Separator className="ml-4" />
              </div>
              <DatePicker
                calendarTitle="Pick a day"
                highlightType="info"
                startDate={values.dateTimesToBeHeldStart}
                endDate={values.dateTimesToBeHeldEnd}
                showSplittedRange={true}
                formatedRangeStart={formatedRangeStart}
                formatedRangeEnd={formatedRangeEnd}
                pickerButtonClassName="mb-4 mt-2 py-2 text-sm"
                onRangeChange={onRangeChange}
                mepa={"mepa"}
              />
            </div>
            <div className="">
              <div className="flex items-center mt-4 mb-2">
                <p className="flex items-center gap-2 text-base font-light shrink-0">
                  <ClockIcon fill="transparent" />
                  <span>Meeting Length</span>
                </p>
                <Separator className="ml-4" />
              </div>
              <Input
                name="minLength"
                placeholder="Minutes"
                value={values.minLength}
                type="number"
                className="mb-2 mt-2 py-[9px]"
                onChange={handleChange}
                onBlur={handleBlur}
                hasError={!!(errors.minLength && touched.minLength)}
                error={errors.minLength}
              />
            </div>
            <div>
              <div className="flex items-center mt-4">
                <p className="flex items-center gap-2 text-base font-light">
                  <GroupIcon fill="transparent" />
                  <p className="whitespace-nowrap ">Internal Participants</p>
                </p>
                <Separator className="ml-4" />
              </div>
              <InvitableUsersSelection
                invitableUsers={invitableUsers}
                selectedUsers={values.invitees}
                fieldName="invitees"
                hasError={!!(errors.invitees && touched.invitees)}
                error={errors.invitees}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
              />
            </div>
            <div>
              <div className="flex items-center mt-4">
                <p className={"text-base font-light flex items-center gap-2"}>
                  {/* <GroupIcon fill='transparent' /> */}
                  <img src={externalicon} className="w-5 h-5" alt="" />
                  <p className="whitespace-nowrap pr-2">
                    External Participants
                  </p>
                </p>
                <Separator className="ml-4" />
              </div>
              <div className="font-thin w-full mt-2 flex gap-4 relative">
                <div className="relative">
                  <input
                    ref={inputRef}
                    name="externalInvitees"
                    placeholder="Who are you going to invite?"
                    type="email"
                    value={inputValueExteralinvitees}
                    className={clsx(
                      "text-xs font-medium !bg-[transparent] !min-h-[36px] !h-9",
                      "mb-2 mt-2 py-[9px] !w-[337px] px-3 border border-slate-300 rounded-md outline-none",
                      errors.externalInvitees && touched.externalInvitees
                        ? "!border-danger"
                        : "!border-primary"
                    )}
                    onChange={handleChange}
                    onInput={(e) => {
                      setInputValueExteralinvitees(e.target.value);
                      handleInputChange(e);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleAddEmail();
                      }
                    }}
                  />
                  <div>
                    <ChevronDownIcon
                      fill="transparent"
                      className={clsx(
                        "w-6 h-4 text-gray500 font-extrabold ",
                        "absolute right-2 cursor-pointer top-[18px] hover:text-primary"
                      )}
                    />
                  </div>
                </div>
                {externalUserFromApi?.length > 0 &&
                  inputValueExteralinvitees.length > 0 && (
                    <div
                      style={{ backgroundColor: "white" }}
                      className="border w-[70%] border-slate-400 h-[140px] overflow-y-scroll rounded-md  absolute top-[90%]"
                    >
                      {externalUserFromApi?.map((item, index) => {
                        return (
                          <>
                            <div
                              ref={dropdownRef}
                              onClick={() => {
                                setExternalInvitees((prevList) => [
                                  ...prevList,
                                  {
                                    canAttend: true,
                                    emailAddress: item?.emailAddress,
                                  },
                                ]);
                                setInputValueExteralinvitees("");
                              }}
                              key={index}
                              className="text-xs py-2 px-2 font-semibold hover:bg-[#E0FFFF] "
                            >
                              <div className="text-xs flex gap-1">
                                <span>{item?.firstName}</span>
                                <span>{item?.lastName}</span>
                              </div>
                              <span className=" block text-gray500">
                                {item?.emailAddress}
                              </span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  )}
                {/* <div className="">
                  <button
                    onClick={handleAddEmail}
                    className="p-2 border border-slate-400 text-xs rounded-md"
                  >
                    Add
                  </button>
                </div> */}
                {externalInvitees.length > 0 && (
                  <div className="flex items-center mt-2 gap-4 h-9 w-[30%] relative">
                    <div className="flex relative h-full">
                      {externalInvitees.slice(0, 3).map((char, i) => (
                        <div
                          key={i}
                          style={{
                            color: "white",
                            left: `${i * 20}px`,
                            background: i <= 2 ? colors[i] : "black",
                          }}
                          className={`w-9 h-9 uppercase absolute flex justify-center items-center border-[3px] border-gray500  rounded-full`}
                        >
                          {char.emailAddress?.charAt(0)}
                        </div>
                      ))}
                      {externalInvitees.length > 3 && (
                        <div
                          style={{
                            color: "white",
                            left: `${3 * 20}px`,
                          }}
                          className={clsx(
                            "w-9 h-9 absolute bg-primary top-0 flex justify-center items-center rounded-full"
                          )}
                        >
                          {externalInvitees.length - 3}+
                        </div>
                      )}
                    </div>
                    <div>
                      <ChevronDownIcon
                        fill="transparent"
                        onClick={() => setOpenMailDropdown(!openMailDropdown)}
                        className={clsx(
                          "w-4 h-4 text-primary font-semibold",
                          "cursor-pointer absolute top-2",
                          openMailDropdown && "rotate-180"
                        )}
                        style={{
                          left: `${
                            externalInvitees.length === 1
                              ? 40
                              : externalInvitees.length === 2
                              ? 55
                              : externalInvitees.length === 3
                              ? 73
                              : 90
                          }%`,
                        }}
                      />
                    </div>
                    {openMailDropdown && (
                      <div
                        ref={menuListRef}
                        className="absolute mt-2 py-2 right-0 top-full bg-neutral border border-gray500 rounded-[10px] shadow-theme w-[220px]"
                        // style={{ backgroundColor: "neutral" }}
                      >
                        {externalInvitees.map((mail, index) => {
                          return (
                            <>
                              <div className="flex items-center justify-between p-1">
                                <div
                                  key={index}
                                  className=" flex items-center gap-[5px] p-[2px] w-[170px] overflow-hidden overflow-ellipsis "
                                >
                                  <div
                                    key={index}
                                    style={{
                                      color: "white",
                                      left: `${index * 20}px`,
                                      background:
                                        index <= 2 ? colors[index] : "black",
                                    }}
                                    className={`w-6 h-6 uppercase flex justify-center items-center  rounded-full`}
                                  >
                                    {mail.emailAddress?.charAt(0)}
                                  </div>
                                  <div>
                                    <p className="text-xs font-bold">{mail?.emailAddress.split("@")[0]}</p>
                                  <p className="text-[10px] font-medium text-gray500">{mail?.emailAddress}</p>
                                  </div>
                                </div>
                                <CrossIcon
                                  onClick={() => {
                                    setExternalInvitees((prevList) =>
                                      prevList.filter((_, i) => i !== index)
                                    );
                                  }}
                                  fill="transparent"
                                  className="text-danger cursor-pointer ml-auto w-5 h-5"
                                />
                              </div>
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {errors.externalInvitees && touched.externalInvitees && (
                <p className="text-danger block text-xs">
                  At least one external invitee is required
                </p>
              )}
            </div>
            {/* <div>
              <div className="flex items-center gap-4 w-full">
                <div className="font-thin w-full">
                  <p className="mb-2">First name</p>
                  <Input
                    name="firstName"
                    placeholder="First Name"
                    value={values.firstName}
                    className="mb-2 py-[9px] w-full"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={!!(errors.firstName && touched.firstName)}
                    error={errors.firstName}
                  />
                </div>
                <div className="font-thin w-full">
                  <p className="mb-2">Last name</p>
                  <Input
                    name="lastName"
                    placeholder="Last Name"
                    value={values.lastName}
                    className="mb-2 py-[9px] w-full"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    hasError={!!(errors.lastName && touched.lastName)}
                    error={errors.lastName}
                  />
                </div>
              </div>
              <div className="mt-2 font-thin">
                <p className="mb-2">E-mail</p>
                <Input
                  name="email"
                  placeholder="Email"
                  type="email"
                  value={values.email}
                  className="mb-2 py-[9px]"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  hasError={!!(errors.email && touched.email)}
                  error={errors.email}
                />
              </div>
            </div> */}
            <div>
              <div className="flex items-center mt-4">
                <p className="text-base font-light flex items-center gap-2">
                  <AgendaIcon fill="transparent" />
                  <span>Agenda</span>
                </p>
                <Separator className="ml-4" />
              </div>
              <Textarea
                name="agenda"
                placeholder="What's the agenda of the meeting?"
                value={values.agenda}
                onChange={handleChange}
                onBlur={handleBlur}
                containerClassName="mb-[18px]"
                className={clsx(
                  "mt-[9px] !text-sm font-thin",
                  !(errors.agenda && touched.agenda) && "!border-primary"
                )}
                rows={3}
                hasError={errors.agenda && touched.agenda}
                error={errors.agenda}
              />
            </div>
            <div className="">
              <div className="flex items-center mt-4">
                <p className="flex items-center gap-2 text-base font-light">
                  <LocationIcon fill="transparent" />
                  <span>Location</span>
                </p>
                <Separator className="ml-4" />
              </div>

              <Select
                name="platformId"
                placeholder="Meeting Platform"
                value={values.platformId}
                options={meetingPlatformsList}
                onChange={handleChange}
                onBlur={handleBlur}
                containerClassName="mt-4 "
                controlClassName={clsx(
                  errors.platformId && touched.platformId
                    ? "!border-danger"
                    : "!border-primary"
                )}
                hasError={!!(errors.platformId && touched.platformId)}
                error={errors.platformId}
                errorClassName="text-xs font-semibold mt-2 "
              />
            </div>

            <Separator className="my-5" />

            <div
              // onClick={timeSlot}
              // onClick={() => setMeetingSlot(true)}
              onClick={submitForm}
              className={clsx(
                "py-3  rounded-full cursor-pointer flex items-center justify-center w-full",
                "border border-primary rounded-[10px]  flex items-center gap-4"
              )}
            >
              <img src={searchnormal} alt="" />
              <span className="font-bold ">Find Time Slots</span>
            </div>
          </div>

          {(actionLoading || addMetingtimeSlotLoading) && (
            <div
              className={clsx(
                "absolute top-0 left-0",
                "flex flex-col items-center justify-center",
                "w-full h-full bg-[#b5b5b5b5] rounded-[10px]"
              )}
            >
              <Spinner className="!w-6 !h-6 mb-2" />
              <p>Loading...</p>
            </div>
          )}
        </div>
      )}

      {/* {actionLoading && (
        <Modal
          isOpen="true"
          onClose={() => {}}
          preventAnimate={true}
          preventBackdropClose={true}
          hideHeader={true}
        >
          <CalculationTimeSlot
            actionLoading={actionLoading}
            onCancel={() => setMeetingSlot(false)}
          />
        </Modal>
      )} */}
      {actionSuccess && (
        <Modal
          isOpen="true"
          onClose={() => {}}
          preventAnimate={true}
          preventBackdropClose={true}
          hideHeader={true}
        >
          <CalculationTimeSlot
            onFormStatusChange={onFormStatusChange}
            onHideCreateMeetingModal={onHideCreateMeetingModal}
            onCancel={() => {
              // onFormStatusChange()
              onClose();
            }}
          />
        </Modal>
      )}
      {addMepaTimesloteSuccess && !isExitScheduleModal && (
        <Modal
          isOpen="true"
          onClose={() => {}}
          preventAnimate={true}
          preventBackdropClose={true}
          hideHeader={true}
        >
          <MeetingTimeSlot
            onFormStatusChange={onFormStatusChange}
            onCancel={() => onClose()}
            addMepaTimesloteSuccess={addMepaTimesloteSuccess}
            timeSlotsData={meetingTimeSlots}
          />
        </Modal>
      )}
      {(actionError || checkMeetingValidityError) && (
        <MeetingRequestError
          actionError={actionError || checkMeetingValidityError}
          isUpdateAction={!!values.id}
          onClose={onClose}
          onClearError={onClearError}
        />
      )}
    </div>
  );
};
