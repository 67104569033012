import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { Modal } from "../layout/Modal";
import { MeetingDetailModal } from "../modals/MeetingDetailModal";
import { CalendarWeekGrid } from "../calendar-page/CalendarWeekGrid";
import { useScheduleEventsByDuration } from "../../hooks/scheduleEvents";
import {
  fetchScheduledEventDetailById,
  fetchScheduledEvents,
} from "../../store/scheduledEvents";

import { ReactComponent as ChevronIcon } from "../../assets/icons/Chevron.svg";
import { useGetWorkingDays } from "../../hooks/workingHours";

let timoutRef;

export const DashboardCalendarCard = () => {
  const dispatch = useDispatch();
  const [currentDateTime] = useState(dayjs());
  const [currentTime, setCurrentTime] = useState("");
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [isMepaMeeting, setIsMepaMeeting] = useState(false);

  const scheduledEventsList = useScheduleEventsByDuration(
    currentDateTime,
    "day",
  );
  const workingDays = useGetWorkingDays();

  const { displayDate, eventsOnDay } = useMemo(() => {
    const dateKey = currentDateTime.format("YYYY-MM-DD");
    const eventsOnDay = scheduledEventsList[dateKey] || [];
    return {
      displayDate: currentDateTime.format("DD MMM, YYYY"),
      eventsOnDay: eventsOnDay,
    };
  }, [currentDateTime, scheduledEventsList]);

  const onViewEventDetail = (event) => {
    if (event?.mepaId) {
      setIsMepaMeeting(true);
    } else {
      setIsMepaMeeting(false);
    }
    dispatch(
      fetchScheduledEventDetailById({
        entityID: event?.entityID,
        isProposal: event?.isProposal,
        mepaId: event?.mepaId,
      }),
    );
    setShowDetailModal(true);
  };

  const onHideModal = () => {
    setShowDetailModal(false);
  };

  const updateCurrentTime = () => {
    const dateTime = dayjs();
    setCurrentTime(dateTime.format("HH:mm"));
  };

  useEffect(() => {
    updateCurrentTime();

    // Update the time every 10 seconds
    timoutRef = setInterval(() => {
      updateCurrentTime();
    }, 1000 * 10);

    return () => {
      clearInterval(timoutRef);
    };
  }, []);

  useEffect(() => {
    const start = currentDateTime.startOf("day").format("YYYY-MM-DDTHH:mm:ss");
    const end = currentDateTime.endOf("day").format("YYYY-MM-DDTHH:mm:ss");
    dispatch(fetchScheduledEvents({ start, end }));
  }, []);

  return (
    <div
      className={clsx(
        "bg-neutral pt-7 border border-gray500 rounded-[10px] shadow-theme",
        "w-[calc(50%-12px)]",
      )}
    >
      <div className={clsx("px-4 pb-3 border-b border-gray500")}>
        <div className="flex items-center justify-between mb-5">
          <h3 className="text-2xl">Calendar</h3>
          <Link className="text-gray500" to="../calendar">
            <span>Go to calendar</span>
            <ChevronIcon className="inline" fill="transparent" />
          </Link>
        </div>
        <div className="flex justify-between items-end">
          <p className="text-3xl">{currentTime}</p>
          <div className="text-right">
            <p className="text-4xl text-gray500">{displayDate}</p>
            {eventsOnDay.length > 0 && (
              <p className="text-3xl">
                {eventsOnDay.length}{" "}
                {eventsOnDay.length > 1 ? "meetings" : "meeting"} today
              </p>
            )}
          </div>
        </div>
      </div>
      <div
        className={clsx(
          "overflow-y-scroll rounded-[10px]",
          eventsOnDay.length > 0
            ? "h-[calc(100%-143px)]"
            : "h-[calc(100%-107px)]",
        )}
      >
        <CalendarWeekGrid
          today={currentDateTime}
          currentMonth={currentDateTime}
          workingDays={workingDays}
          workingDayStartTime={currentTime}
          showFullWeek={false}
          scheduledEventsList={scheduledEventsList}
          onViewEventDetail={onViewEventDetail}
        />
      </div>
      {showDetailModal && (
        <Modal
          isOpen={showDetailModal}
          onClose={onHideModal}
          preventAnimate={true}
          hideHeader={true}
          modalClassName="border border-gray500 shadow-theme px-8"
        >
          <MeetingDetailModal onClose={onHideModal} isMepa={isMepaMeeting} />
        </Modal>
      )}
    </div>
  );
};
